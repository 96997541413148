import React, { useEffect, useRef, useState } from "react";
import { listGurudwaras } from "../Data";
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap";
import { FaQuestionCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import Payment from "../../../pages/DashboardPages/settingsComponents/Payment";
import { useDispatch, useSelector } from "react-redux";
import { GrLinkNext } from "react-icons/gr";
import {
  setDonationClientId,
  setDonationFrom,
  setDonationSecretKey,
  setModalIsOpen,
  setMonthlyDonation,
  setOpenPaypal,
  setSelectedAmount,
  setSelectedGurdwara,
} from "../../../redux/slices/DonationSlice";

const DonateModal = ({
  modalIsOpen,
  setModalIsOpen,
  handleFormSubmit,
  onClose,
}) => {
  const [gurdwaras, setGurdwaras] = useState([]);
  const formData = useSelector((state) => state.requestForm);
  const [formSelectedGurdwara, setFormSelectedGurdwara] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  // const [selectedGurdwara, setSelectedGurdwara] = useState("");
  // const [selectedAmount, setSelectedAmount] = useState("");
  // const [monthlyDonation, setMonthlyDonation] = useState(null);
  // const [openPaypal, setOpenPaypal] = useState(false);
  const {
    selectedGurdwara,
    selectedAmount,
    monthlyDonation,
    openPaypal,
    donationFrom,
  } = useSelector((state) => state.gurdwaraDonation);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const tooltipRef = useRef();
  const buttonRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setTooltipOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const dispatch = useDispatch();
  const closeModal = (e) => {
    console.log("close modal");
    e.preventDefault();
    setModalIsOpen(false);
    dispatch(setMonthlyDonation(null));
    dispatch(setSelectedAmount(null));
    dispatch(setSelectedGurdwara(null));
    dispatch(setOpenPaypal(false));
    dispatch(setDonationClientId(""));
    dispatch(setDonationSecretKey(""));
    dispatch(setDonationFrom(""));
    onClose();
    if (donationFrom === "GURDWARA_NEED") {
      handleFormSubmit(e);
    }
  };
  const fetchData = async () => {
    const gurudwaras = await listGurudwaras();
    setGurdwaras(gurudwaras);
  };
  useEffect(() => {
    const filterClientID =
      gurdwaras.find((gurdwara) => gurdwara.Id === parseInt(selectedGurdwara))
        ?.paypalClientID || "";
    dispatch(setDonationClientId(filterClientID));
    const filterSecretKey =
      gurdwaras.find((gurdwara) => gurdwara.Id === parseInt(selectedGurdwara))
        ?.paypalSecretKey || "";
    dispatch(setDonationSecretKey(filterSecretKey));
    console.log(filterClientID, filterSecretKey, "filterClientID");
  }, [selectedGurdwara, gurdwaras, dispatch]);

  useEffect(() => {
    const formSelectedGurdwara = gurdwaras.find(
      (gurdwara) => gurdwara.Title === formData?.Gurudwara
    );
    console.log(formSelectedGurdwara?.Id, "formSelectedGurdwara");
    setFormSelectedGurdwara(formSelectedGurdwara);
    setSelectedGurdwara(formSelectedGurdwara);
    console.log(formSelectedGurdwara, "formSelectedGurdwaraId");
    dispatch(setSelectedGurdwara(formSelectedGurdwara?.Id));
  }, [formData?.Gurudwara]);
  useEffect(() => {
    fetchData();
    console.log(gurdwaras, "gurdwaras");
  }, []);
  console.log(
    selectedGurdwara,
    formSelectedGurdwara,
    formData?.Gurudwara,
    "selectedGurdwara"
  );
  console.log(donationFrom, "donationFrom");
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        toggle={closeModal}
        backdrop={donationFrom === "GURDWARA_NEED" ? "static" : true}
        className="inter-font"
      >
        <ModalBody>
          <ModalHeader
            className="fw-bold bg-primary text-white"
            style={{ fontSize: "20px" }}
          >
            <span className="fw-bold fs-1">Donate</span>
            <div style={{ fontSize: "13px", fontFamily: "system-ui" }}>
              The Gurdwara offers free services to the Sangat, but only with
              your support can we sustain efforts & continue introducing new
              technologies.
            </div>
          </ModalHeader>
          <FormGroup className="mt-4">
            <Label className="ms-1 fs-5">
              Choose Gurdwara to receive Donation
            </Label>
            <Input
              type="select"
              name="Gurudwara"
              //placeholder="Enter Zip code "
              onChange={(e) => {
                dispatch(setSelectedGurdwara(e.target.value));
                dispatch(setOpenPaypal(false));
              }}
              value={selectedGurdwara || formSelectedGurdwara?.Id}
              className="form-control form-control-lg fs-5"
            >
              <option value="" selected>
                Select Gurdwara
              </option>
              {gurdwaras.map((gurdwara) => (
                <option key={gurdwara.Id} value={gurdwara.Id}>
                  {gurdwara.Title}
                </option>
              ))}
            </Input>
          </FormGroup>
          {/* <FormGroup>
            <Label className="ms-1 fs-5">Name</Label>
            <Input
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-control form-control-lg fs-5"
            />
            <Label className="ms-1 fs-5">Email</Label>
            <Input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control form-control-lg fs-5"
            />
          </FormGroup> */}
          <div className="d-flex align-items-center mt-3 justify-content-around">
            <button
              className="btn p-3 border rounded-pill active fs-4"
              onClick={() => {
                dispatch(setSelectedAmount(25));
                dispatch(setOpenPaypal(false));
              }}
            >
              &nbsp;&nbsp;$25&nbsp;&nbsp;
            </button>
            <button
              className="btn p-3 border rounded-pill fs-4"
              onClick={() => {
                dispatch(setSelectedAmount(50));
                dispatch(setOpenPaypal(false));
              }}
            >
              &nbsp;&nbsp;$50&nbsp;&nbsp;
            </button>
            <button
              className="btn p-3 border rounded-pill fs-4"
              onClick={() => {
                dispatch(setSelectedAmount(100));
                dispatch(setOpenPaypal(false));
              }}
            >
              &nbsp;&nbsp;$100&nbsp;&nbsp;
            </button>
          </div>
          <FormGroup className="mt-4">
            <InputGroup>
              <InputGroupText>$</InputGroupText>
              <Input
                type="text"
                name="customAmount"
                placeholder="Custom Amount"
                value={selectedAmount}
                onChange={(e) => {
                  const amount = e.target.value.replace(/[^0-9]/g, "");
                  dispatch(setSelectedAmount(amount));
                  dispatch(setOpenPaypal(false));
                }}
                className="form-control fs-4"
              />
            </InputGroup>
          </FormGroup>
          <div className="d-flex justify-content-center my-4">
            <Input
              type="checkbox"
              className="custom-checkbox"
              id="monthlyDonation"
              checked={monthlyDonation}
              onChange={(e) => {
                dispatch(setMonthlyDonation(e.target.checked));
                dispatch(setOpenPaypal(false));
              }}
              name="monthlyDonation"
            />
            <label className="ml-2" htmlFor="monthlyDonation">
              Make this a monthly donation{" "}
            </label>
            <button
              id="ScheduleUpdateTooltip"
              ref={buttonRef}
              className="ms-2"
              onClick={() => setTooltipOpen(true)}
            >
              <FaQuestionCircle />
            </button>
            <UncontrolledTooltip
              placement="top"
              target="ScheduleUpdateTooltip"
              trigger="click"
              isOpen={tooltipOpen}
              toggle={() => setTooltipOpen(false)}
              container="body"
              innerRef={tooltipRef} // Adding ref to tooltip
            >
              Donated amount will be collected every 30 days through Paypal /
              Bank account.
            </UncontrolledTooltip>
          </div>
          {donationFrom === "GURDWARA_NEED" ? (
            <>
              {!openPaypal ? (
                <FormGroup className="d-flex gap-3 justify-content-center align-items-center">
                  {/* <button
                    style={{
                      backgroundColor: "grey",
                      borderRadius: "10px",
                      padding: "10px 9px",
                      width: "100%",
                      color: "white",
                    }}
                    onClick={(e) => {
                      console.log(" Post My Ad without supporting Gurdwara");
                      closeModal(e);
                    }}
                  >
                    Post My Ad without supporting Gurdwara
                  </button> */}
                  <button
                    className="bg-success"
                    style={{
                      borderRadius: "10px",
                      padding: "10px 15px",
                      width: "100%",
                      color: "white",
                    }}
                    onClick={() => {
                      if (!selectedGurdwara) {
                        toast.error("Please select Gurdwara");
                        return;
                      }
                      if (!selectedAmount || selectedAmount <= 0) {
                        toast.error("Please select amount");
                        return;
                      }
                      dispatch(setOpenPaypal(true));
                    }}
                  >
                    Support Gurdwara & Post My Ad
                  </button>
                </FormGroup>
              ) : (
                <Payment
                  totalPrice={Number(selectedAmount)}
                  state={{ donation: true }}
                  selectedGurdwara={selectedGurdwara}
                  monthlyDonation={monthlyDonation}
                />
              )}
            </>
          ) : (
            <>
              {!openPaypal ? (
                <button
                  className="btn btn-large w-100 text-white fs-4 py-3"
                  style={{ backgroundColor: "#EC1889", borderRadius: "40px" }}
                  onClick={(e) => {
                    if (!selectedGurdwara) {
                      toast.error("Please select Gurdwara");
                      return;
                    }
                    if (!selectedAmount || selectedAmount <= 0) {
                      toast.error("Please select amount");
                      return;
                    }
                    dispatch(setOpenPaypal(true));
                  }}
                >
                  <strong>
                    Next &nbsp;&nbsp;
                    <GrLinkNext />
                  </strong>
                </button>
              ) : (
                <>
                  <Payment
                    totalPrice={Number(selectedAmount)}
                    state={{ donation: true }}
                  />
                </>
              )}
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default DonateModal;
