import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axiosClient from "../../axios-client";

const CustomerGottonModal = ({ open, close, user }) => {
  const [gottonData, setGottonData] = useState([]);

  const fetchGotton = async () => {
    try {
      const response = await axiosClient.put(
        `message/TotalMsgMonthGetCustomer/${user.Id}`
      );
      const data = response.data.Data; // Ensure this matches the API response structure
      setGottonData(data);
    } catch (error) {
      console.error("Error fetching Gotton data:", error);
    }
  };

  useEffect(() => {
    if (user && open) {
      fetchGotton();
    }
  }, [open, user]);
  const formatMonth = (monthString) => {
    const [year, month] = monthString.split("-");
    const date = new Date(year, month - 1);
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      year: "numeric",
    }).format(date);
  };
  console.log(gottonData, "gottonData");
  return (
    <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
      <DialogTitle>
        Customers Gotten Data for {user?.FirstName} {user?.LastName}
        <IconButton
          aria-label="close"
          onClick={close}
          style={{ position: "absolute", right: 16, top: 16, margin: "-15px" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {gottonData && gottonData.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Month</TableCell>
                  <TableCell align="center">Customers Gotten</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gottonData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      {formatMonth(item.MONTH)}
                    </TableCell>
                    <TableCell align="center">{item.totalMessages}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body1" color="textSecondary" align="center">
            No Gotton data available.
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CustomerGottonModal;
