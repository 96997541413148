import React, { createContext, useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { setUsers } from "../redux/slices/SocketSlice";

const SocketContext = createContext();

const backendUrl =
  process.env.REACT_APP_SOCKET_BACKEND_URL || "http://localhost:7000";

export const SocketProvider = ({ children }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const socket = io(backendUrl);
  const dispatch = useDispatch();

  const sendNewMessage = (messageForm) => {
    if (user) {
      socket.emit("send-message", messageForm);
    }
  };
  const onMessageReceived = (message) => {
    // console.log("receive-message:", message);
    // Optionally, any centralized logic for processing the message
    return message; // Pass this to MsgInputBox
  };
  const onNewMessage = (message) => {
    return message;
  };

  React.useEffect(() => {
    if (user) {
      socket.emit("user-logged-in", user.UserID);
      socket.on("online-users", (users) => {
        // localStorage.setItem("onlineUsers", JSON.stringify(users));
        dispatch(setUsers(users));
      });
      socket.on("receive-message", (message) => {
        onMessageReceived(message);
        console.log("receive-message:", message);
      });
      socket.on("new-message", (message) => {
        onNewMessage(message);
        console.log("new-message:", message);
      });
    } else {
      socket.on("user-logged-out", () => {
        socket.disconnect();
        // localStorage.removeItem("onlineUsers");
      });
      // localStorage.removeItem("onlineUsers");
    }
  }, [socket, user, io]);
  return (
    <SocketContext.Provider
      value={{ socket, sendNewMessage, onMessageReceived, onNewMessage }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
