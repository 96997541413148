import React, { useState, useEffect, Suspense } from "react";
import Helmet from "../../components/Helmet/Helmet";
import { Button } from "reactstrap";
import "../../styles/home.css";
// import NewListings from "./NewListings";
// import BrowseByCategory from "./BrowseByCategory";
// import VideoIntroduction from "./VideoIntroduction";
// import HowItWorks from "./HowItWorks";
// import FeaturedProviders from "./FeaturedProviders";
// import HomeFlashDeals from "./HomeFlashDeals";
// import NewEngageCouple from "./NewEngage";
// import ConnectVendors from "./ConnectVendors";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { imageArray } from "../DummyData";
import { fetchCategories, fetchBWV } from "../../components/Header/Data";
import { toast } from "react-toastify";
import { SearchBwv } from "../../components/Header/WeddingVendors/SearchBwv";
import RecommendedVendors from "./RecommandedVenndors";
import FreeGifts from "./FreeGifts";
import { bwvSelector } from "../../redux/slices/bwvSlice";

const NewListings = React.lazy(() => import("./NewListings"));
const BrowseByCategory = React.lazy(() => import("./BrowseByCategory"));
const VideoIntroduction = React.lazy(() => import("./VideoIntroduction"));
const HowItWorks = React.lazy(() => import("./HowItWorks"));
const FeaturedProviders = React.lazy(() => import("./FeaturedProviders"));
const HomeFlashDeals = React.lazy(() => import("./HomeFlashDeals"));
const NewEngageCouple = React.lazy(() => import("./NewEngage"));
const ConnectVendors = React.lazy(() => import("./ConnectVendors"));

const Home = () => {
  const [bwv, setBwv] = useState([]);
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const services = useSelector((state) => state.bwv.bwvData);
  const categorieslist = useSelector((state) => state?.categories?.categories);
  console.log(services, "all services");
  console.log(categorieslist, "categorieslist");
  const user = islogin ? JSON.parse(userinfo) : null;
  const [categories, setCategories] = useState([]);
  const [selectedType, setSelectedType] = useState("buyers");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [recentBwv, setRecentBwv] = useState([]);
  const [freeGiftVendors, setFreeGiftVendors] = useState([]);
  const navigate = useNavigate();
  const handleSearchBwv = (searchQuery) => {
    const trimmedQuery = searchQuery.trim().toLowerCase(); // Remove leading and trailing spaces and convert to lowercase
    const filteredBwv = bwv.filter((bwv) => {
      const listNameMatch =
        bwv.listName && bwv.listName.toLowerCase().includes(trimmedQuery);
      const categoryNameMatch =
        bwv.categoryName &&
        bwv.categoryName.toLowerCase().includes(trimmedQuery);
      const subCategoryNameMatch =
        bwv.subCategoryName &&
        bwv.subCategoryName.toLowerCase().includes(trimmedQuery);
      return listNameMatch || categoryNameMatch || subCategoryNameMatch;
    });
    console.log("Filtered BWV:", filteredBwv);
    const state = {
      searchResults: filteredBwv,
      cat: null,
    };
    navigate("/weddingvendors", { state });
  };
  const fetchData = async () => {
    const categoriesData = await fetchCategories();
    const bwvData = await fetchBWV();
    const filterRecent = bwvData
      .filter((item) => item.isactive === "3")
      .slice(0, 4);
    const filterApproved = bwvData.filter((item) => item.isactive === "3");
    const filterFreeGiftVendors = bwvData.filter(
      (item) => (item.giftAmount || item.giftTitle) && item.isactive === "3"
    );
    setFreeGiftVendors(filterFreeGiftVendors);
    setBwv(filterApproved);
    setRecentBwv(filterRecent);
    setCategories(categoriesData);
    console.log(categories, "categories");
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageArray.length);
    }, 2000);
    return () => clearInterval(intervalId);
  }, [currentImageIndex, imageArray.length]);

  let wed =
    "https://mcmscache.epapr.in/post_images/website_350/post_25610392/full.jpeg";
  return (
    <Helmet title={"Home"}>
      <section className="hero-section" style={{ position: "relative" }}>
        <div className="hero-content">
          <div className="content-selector">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 40,
                marginBottom: "30px",
              }}
              className="tgl-hero"
            >
              {" "}
              <h3
                onClick={() => {
                  setSelectedType("buyers");
                }}
                style={{
                  color: selectedType === "buyers" ? "yellow" : "#fff",
                  cursor: "pointer",
                }}
              >
                Buyers
              </h3>{" "}
              <h3
                onClick={() => {
                  setSelectedType("sellers");
                }}
                style={{
                  color: selectedType === "sellers" ? "yellow" : "#fff",
                  cursor: "pointer",
                }}
              >
                Sellers
              </h3>
            </div>

            {/* </Row> */}
          </div>
          {selectedType === "buyers" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1
                style={{
                  fontFamily: "sans-serif",
                  // fontSize: "xx-large",
                  fontWeight: "bolder",
                  color: "#fff",
                  marginBottom: "20px",
                }}
                className="hero-hd-text"
              >
                {/* Search for local Indian Wedding Vendors */}
                Search for local Event Vendors
              </h1>
              <div
                style={{
                  width: "70%",
                }}
              >
                <SearchBwv handleSearchBwv={handleSearchBwv} />
              </div>
            </div>
          )}
          {selectedType === "sellers" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1
                style={{
                  fontFamily: "sans-serif",
                  // fontSize: "xx-large",
                  fontWeight: "bolder",
                  color: "#fff",
                  marginBottom: "20px",
                }}
                className="hero-hd-text"
              >
                Offer your services to thousands of consumers within the <br />
                Indian wedding industry
              </h1>
              <div
                style={{
                  width: "70%",
                }}
              >
                <SearchBwv handleSearchBwv={handleSearchBwv} />
              </div>
            </div>
          )}
          <span>
            Just looking around? Let us suggest you something hot & happening!
          </span>
          <div className="mt-3">
            <Link to={"/weddingvendors"}>
              <button className="btn btn-primary px-3 py-2">
                Browse Local Wedding Vendors
              </button>
            </Link>
          </div>
        </div>
        {imageArray.map((image, index) => (
          <img
            key={index}
            src={image}
            className="w-100 home-images"
            style={{ display: index === currentImageIndex ? "block" : "none" }}
          />
        ))}

        <div className="dark-overlay"></div>
      </section>
      <Suspense fallback={<div>Loading...</div>}>
        <section className="sections-content pt-5">
          <div className="container pb-5">
            <BrowseByCategory categories={categories} />
          </div>
        </section>
        <section className="sections-content">
          <div className="container pb-5">
            <HomeFlashDeals />
          </div>
        </section>
        <section className="sections-content">
          <div className="container pb-5">
            <NewEngageCouple />
          </div>
        </section>

        <section className="sections-content">
          <div className="container pb-5">
            <FeaturedProviders bwv={bwv} />
          </div>
        </section>

        <section className="sections-content">
          <div className="container pb-5">
            <NewListings recentBwv={recentBwv} />
          </div>
        </section>
        <section className="sections-content">
          <div className="container pb-5">
            <RecommendedVendors />
          </div>
        </section>
        <section className="sections-content">
          <div className="container pb-5">
            <FreeGifts freeGiftVendors={freeGiftVendors} />
          </div>
        </section>
        <section className="sections-content">
          <div className="text-center mb-3 ms-4">
            <Link to={"/weddingvendors"}>
              <button className="btn btn-primary px-3 py-2 ">
                Browse Local Wedding Vendors
              </button>
            </Link>
          </div>
        </section>
        <section className="sections-content">
          <div className="container pb-5">
            <VideoIntroduction />
          </div>
        </section>
        <section className="sections-content">
          <div className="container pb-5">
            <HowItWorks />
          </div>
        </section>
        <section className="connect-vendors py-5">
          <ConnectVendors wed={wed} />
        </section>
      </Suspense>
    </Helmet>
  );
};

export default Home;
