import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import BwvServiceInfo from "../Bwv/BwvServiceInfo";
import BwvMedia from "../Bwv/BwvMedia";
import BwvVideo from "../Bwv/BwvVdeos";
import GoogleCalendar from "../../DashboardCalenderPages/Calender";
import { Link, Element } from "react-scroll";
import {
  setActiveLink,
  setIsReviewModalOpen,
  setLoadingEndorse,
  setQuoteModal,
  setQuotePop,
  setSelectedPackageRate,
} from "../../../redux/slices/BwvData";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { serviceEndorsement } from "../Data";
import { FaCheck, FaSync } from "react-icons/fa";
import RequestAppointment from "../modals/RequestAppointmentModal";
import BwvReviews from "./BwvReviews";

const VendorServiceDt = ({
  selectedHour,
  setSelectedHour,
  openHireModal,
  fetchStatus,
}) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logUser = localStorage.getItem("USER_ROLE");
  const selectedCard = useSelector((state) => state.bwvData.selectedService);
  const availableDays = selectedCard?.availableDays;
  const {
    activeLink,
    endorStatus,
    loadingEndorse,
    quoteModal,
    quotePop,
  } = useSelector((state) => state.bwvData);
  const tabs = [
    { id: "service-information", label: "Service Info" },
    { id: "availability", label: "Availability" },
    { id: "flat-rate-services", label: "Prices" },
    { id: "media", label: "Images & Videos" },
    { id: "reviews", label: "Reviews" },
    { id: "deal-terms", label: "Deal Terms" }, // Special case for non-ScrollLink
  ];
  const flatRate = (packageDetails) => {
    dispatch(setSelectedPackageRate(packageDetails));
    openHireModal();
  };

  const handleDownload = async () => {
    try {
      if (typeof selectedCard?.pdfListing === "string") {
        const response = await fetch(selectedCard?.pdfListing); // Fetch the PDF file
        if (!response.ok) {
          throw new Error("Failed to fetch the PDF.");
        }
        const blob = await response.blob(); // Convert response to Blob
        saveAs(blob, `${selectedCard?.listName}_File.pdf`); // Use Blob to download the file
      } else {
        toast.error("No valid PDF found to download.");
      }
    } catch (error) {
      console.error("Download failed:", error);
      toast.error("Download failed. Please try again.");
    }
  };

  const handleLinkClick = (link) => {
    dispatch(setActiveLink(link));
  };
  const packages = selectedCard?.Package
    ? JSON.parse(selectedCard.Package)
    : {};
  const openQuoteModal = () => {
    const validAvailableDays = availableDays.filter((available) => {
      return available.days.length > 0 && available.from && available.to;
    });
    if (!islogin) {
      toast.info("Please Login");
      navigate("/login");
    } else if (Object.keys(validAvailableDays).length <= 0) {
      toast.warning("Provider Not Available");
    } else {
      dispatch(setQuoteModal(true));
    }
  };
  const handleEndorse = async (Id) => {
    if (loadingEndorse) return;
    dispatch(setLoadingEndorse(true));
    let formData = {
      endStatus: endorStatus?.end_status === 1 ? 2 : 1,
      userId: user?.UserID,
      Message: "",
    };
    await serviceEndorsement(Id, formData);
    fetchStatus();
  };
  const openReviewModal = () => {
    if (!islogin) {
      toast.info("Please Login");
      navigate("/login");
    } else {
      dispatch(setIsReviewModalOpen(true));
    }
  };
  return (
    <div className="details p-lg-5 p-md-4">
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ width: "85%" }}
      >
        <h4 className="p-1">{selectedCard?.categoryName}</h4>
      </div>
      <div
        className="position-sticky"
        style={{ position: "sticky", top: 0, zIndex: "4" }}
      >
        {/* <Row className="d-flex justify-content-between align-items-center mb-2 mt-0">
          <Col sm={9} xs={12}>
            <h6 className="p-1">{selectedCard?.specialOffer}</h6>
          </Col>
        </Row> */}
        <div className="wc-scroll-tabs pb-3 d-lg-flex d-md-flex">
          {tabs.map((tab) => (
            <Link
              key={tab.id}
              to={tab.id}
              // smooth={true}
              duration={500}
              offset={-70}
              className="wc-scroll-tab"
              style={{
                color: activeLink === tab.id ? "blue" : "inherit",
                backgroundColor: activeLink === tab.id ? "#fafafa" : "#eaeaea",
                borderRadius: "5px",
                padding: "5px",
                margin: "2px",
              }}
              onClick={() => handleLinkClick(tab.id)}
            >
              {tab.label}
            </Link>
          ))}
        </div>
      </div>
      <div className="pb-3" style={{ fontSize: "small" }}>
        {selectedCard?.Description}
      </div>
      <Row className="service-information pb-3">
        <BwvServiceInfo />
      </Row>
      <div className="media">
        <div className="pb-3">
          <BwvMedia />
        </div>
        <div className="pb-3">
          <BwvVideo />
        </div>
      </div>
      <Col className="flat-rate-services pb-3">
        <h6 style={{ fontWeight: "bold" }} className="text-center">
          Service Rate & Packages
        </h6>
        {Object.values(packages).length > 0 ? (
          Object.values(packages).map((packageDetails, index) => (
            <Row className="services p-lg-4 p-md-4 p-3 mt-4" key={index}>
              <h6 className="pb-3" style={{ fontWeight: "bold" }}>
                {packageDetails.serviceName}
              </h6>
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Col sm={9}>{packageDetails?.description}</Col>
                <Col className="pt-3" style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    className="btn btn-warning text-center"
                    onClick={() => flatRate(packageDetails)}
                    style={{ fontSize: "small" }}
                  >
                    Add to Cart $ {packageDetails?.price}
                  </button>
                </Col>
              </Row>
            </Row>
          ))
        ) : (
          <p style={{ textAlign: "center" }}>No services available</p>
        )}
      </Col>
      <Row className="availability pb-3">
        <h6 style={{ fontWeight: "bold" }}>
          {" "}
          Availability <i className="fas fa-sync"></i> <FaSync size={13} />
        </h6>
        <GoogleCalendar
          openQuoteModal={openQuoteModal}
          selectedHour={selectedHour}
          setSelectedHour={setSelectedHour}
        />
        <RequestAppointment
          isOpen={quoteModal}
          selectedHour={selectedHour}
          setSelectedHour={setSelectedHour}
          quotePop={quotePop}
          closeModal={() => {
            dispatch(setQuoteModal(false));
            dispatch(setQuotePop(false));
          }}
        />
      </Row>
      <Row className="reviews pb-3 d-flex justify-content-between">
        <Col sm="8" xs="12">
          <h6 className="fw-bold">Reviews</h6>{" "}
        </Col>
        <Col sm="4" xs="12">
          <div className="d-flex justify-content-center align-items-center gap-1">
            <button
              type="button"
              className="btn btn-outline-primary btn-sm mt-1 text-nowrap"
              onClick={openReviewModal}
            >
              Leave a Review
            </button>
            {islogin &&
              logUser === "provider" &&
              user?.UserID !== parseInt(selectedCard.createdBy) && (
                <button
                  type="button"
                  className={`btn btn-${
                    endorStatus?.end_status !== 1 ? `primary` : `success`
                  } btn-sm mt-1`}
                  onClick={() => handleEndorse(selectedCard?.Id)}
                >
                  {endorStatus?.end_status === 1 ? (
                    <span className="text-nowrap">
                      <FaCheck /> Endorsed
                    </span>
                  ) : (
                    // ) : loading ? (
                    //   <ImSpinner2 />
                    "Endorse"
                  )}
                </button>
              )}
          </div>
        </Col>
      </Row>
      <BwvReviews />
      <div className="deal-terms pb-3">
        <h6 className="pb-3 fw-bold text-center">Deal Terms</h6>
        <span>{selectedCard?.dealTerm}</span>
      </div>
      <div>
        <h6 className="pb-3 fw-bold text-center">File</h6>
        {selectedCard?.pdfListing && selectedCard?.pdfListing != "string" ? (
          <>
            <embed
              src={selectedCard?.pdfListing}
              width="250"
              height="220"
              type="application/pdf"
            />
            <div className="text-center mt-3">
              <button onClick={handleDownload} className="btn btn-primary">
                Download PDF
              </button>
            </div>
          </>
        ) : (
          <p className="text-center">PDF not available.</p>
        )}
      </div>
    </div>
  );
};

export default VendorServiceDt;
