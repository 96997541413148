// FollowUpTasks.js
import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { FaChevronDown } from "react-icons/fa";
import { Menu, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import CreateFollowUpMd from "../../../../components/Header/modals/CreateFollowUpMd";
import { fetchMyServices } from "../../../../components/Header/Data";
import AllFollowUpTasks from "../../../../components/Header/modals/AllFollowUpTasks";

const FollowUpTasks = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMenu, setCurrentMenu] = useState(false);
  const [openCreateModal, setCreateModal] = useState(false);
  const [openAllFollowUpsModal, setOpenAllFollowUpsModal] = useState(false);
  const { selectedConvo } = useSelector((state) => state.messages);
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [teamMembers, setTeamMembers] = useState([]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setCurrentMenu(true);
  };
  const fetchServices = async () => {
    try {
      const response = await fetchMyServices({
        createdBy: selectedConvo?.createdBy,
      });
      const service = response.filter((service) => service.isactive === "3");
      const additionalPeople = service[0]?.additionalPeople;
      const parsedPeople = additionalPeople ? JSON.parse(additionalPeople) : [];

      // Filter out team members with empty values
      const validTeamMembers = parsedPeople.filter(
        (member) => member.email && member.phoneNumber && member.countryCode
      );

      setTeamMembers(validTeamMembers);
      console.log(validTeamMembers, "teamMembers");
    } catch (error) {
      console.error("Error fetching services or parsing data:", error);
    }
  };

  useEffect(() => {
    if (user?.UserID) {
      fetchServices();
    }
  }, [selectedConvo, user?.UserID]);

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentMenu(false);
  };
  const openCreate = () => {
    setCreateModal(true);
    handleClose();
  };
  const openAllFollows = () => {
    setOpenAllFollowUpsModal(true);
    handleClose();
  };

  return (
    <div className="wc-chat-drop">
      <Button
        className="drop-button"
        style={{
          backgroundColor: "#01a2e8",
          color: "white",
          fontSize: "x-small",
        }}
        size="sm"
        onClick={handleClick}
      >
        <span className="drop-button-title">
          Follow Up Tasks
          <FaChevronDown />
        </span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={currentMenu}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: "120px",
            maxWidth: "200px",
          },
        }}
      >
        <MenuItem onClick={openAllFollows}>View Tasks</MenuItem>
        <MenuItem onClick={openCreate}>Add Task</MenuItem>
      </Menu>
      <CreateFollowUpMd
        open={openCreateModal}
        teamMembers={teamMembers}
        handleClose={() => setCreateModal(false)}
      />
      <AllFollowUpTasks
        open={openAllFollowUpsModal}
        handleClose={() => setOpenAllFollowUpsModal(false)}
      />
    </div>
  );
};

export default FollowUpTasks;
