import React, { useEffect, useState } from "react";
import Sidebar from "../../SideBar";
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";
import { BiPlusCircle, BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ManageGurdwara from "./ManageGurdwara";
import {
  detailsGurudwara,
  fetchGurdwaraBookingAdmin,
  fetchGurudwaraBooking,
  ListGurudwaraDonation,
} from "../../../components/Header/Data";
import BookingList from "./BookingList";
import DonationList from "./DonationList";

const MyGurudwaraPage = () => {
  const [activeTab, setActiveTab] = useState("ManageGurdwara");
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  const id = user?.Gurudwara;
  const [isLoading, setIsLoading] = useState(false);
  const [gurdwara, setGurdwara] = useState([]);
  const [bookingDetails, setBookingDetails] = useState([]);
  const [donationDetails, setDonationDetails] = useState([]);
  const [years, setYears] = useState([]); // State for unique years
  const [serviceTypes, setServiceTypes] = useState([]);
  const { responseId } = useSelector((state) => state.gurdwara);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [filteredBookingDetails, setFilteredBookingDetails] = useState([]);
  const [selectedServiceType, setSelectedServiceType] = useState("");
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const options = [
    "Anand Karaj (Sikh Wedding)",
    "Akhand Path (48 hour recitation of the Guru Granth Sahib)",
    "Kirtan & Bhog ceremony (devotional singing & prayer for religious events, birthdays, anniversaries, memorials)",
    "Ardaas (prayer for occasions such as housewarming, birth of child, new business)",
    "Sukhmani Sahib Path (prayer for seeking peace & healing)",
    "Langar Hall Rental",
    "Paath Services (specific readings)",
    "Amrit Sanchar",
    "Antim Ardasm (funeral services)",
    "Naam Karan (naming ceremony)",
  ];
  const fetchDetailsGurdwara = async () => {
    setIsLoading(true);
    if (!isAdmin) {
      const response = await detailsGurudwara(id);
      setGurdwara(response.Data);
    }
    window.scroll(0, 0);
    setIsLoading(false);
  };

  const fetchBookingDetails = async () => {
    if (isAdmin) {
      const bookingDetails = await fetchGurdwaraBookingAdmin();
      console.log(bookingDetails, "bookingDetails");
      setBookingDetails(Array.isArray(bookingDetails) ? bookingDetails : []);
      window.scroll(0, 0);
    } else {
      // const ID = user?.UserID;
      // const formData = {
      //   gurudwaraId: id,
      // };
      const bookingDetails = await fetchGurudwaraBooking(id);
      console.log(bookingDetails, "bookingDetails");
      setBookingDetails(Array.isArray(bookingDetails) ? bookingDetails : []);
      window.scroll(0, 0);
    }
  };

  const fetchDonationDetails = async () => {
    if (isAdmin) {
      const donationDetails = await ListGurudwaraDonation();
      console.log(donationDetails, "donationDetails");
      setDonationDetails(Array.isArray(donationDetails) ? donationDetails : []);
      window.scroll(0, 0);
    } else {
      const formData = {
        gurudwaraId: id,
      };
      const bookingDetails = await ListGurudwaraDonation(formData);
      console.log(bookingDetails, "donationDetailsAdmin");
      setDonationDetails(Array.isArray(bookingDetails) ? bookingDetails : []);
      window.scroll(0, 0);
    }
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleServiceTypeChange = (event) => {
    setSelectedServiceType(event.target.value);
  };
  const extractUniqueYearsAndServiceTypes = () => {
    const uniqueYears = new Set();
    const uniqueServiceTypes = new Set();

    bookingDetails.forEach((booking) => {
      const { eventDate, serviceType } = booking;

      // Extract year from eventDate
      if (eventDate && typeof eventDate === "string") {
        const year = eventDate.split("-")[0]; // Get the year part
        uniqueYears.add(year); // Add to set
      }

      // Add serviceType to set
      if (serviceType) {
        uniqueServiceTypes.add(serviceType);
      }
    });

    // Update state with unique values
    setYears(Array.from(uniqueYears));
    setServiceTypes(Array.from(uniqueServiceTypes));
  };
  useEffect(() => {
    extractUniqueYearsAndServiceTypes(); // Call this function whenever bookingDetails change
  }, [bookingDetails]);
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    console.log("Search Query Updated: ", event.target.value);
  };
  console.log(bookingDetails, "bookingDetails");
  // const filteredBookingDetails = Array.isArray(bookingDetails)
  //   ? bookingDetails.filter((booking) => {
  //       const {
  //         Name,
  //         Email,
  //         mobileOne,
  //         mobileTwo,
  //         mobileThree,
  //         eventDate,
  //         serviceType,
  //         Amount,
  //       } = booking;

  //       let year, month, day;
  //       if (eventDate && typeof eventDate === "string") {
  //         [year, month, day] = eventDate.split("-");
  //       }
  //       let monthName = ""; // Default to empty string
  //       if (month && !isNaN(month)) {
  //         const monthIndex = parseInt(month, 10) - 1;
  //         if (monthIndex >= 0 && monthIndex < monthNames.length) {
  //           monthName = monthNames[monthIndex].toLowerCase();
  //         }
  //       }
  //       const query = searchQuery.toLowerCase(); // Convert query to lowercase
  //       console.log(`Query: ${query}, Month Name: ${monthName}`); // Debug log
  //       const matchesYear = selectedYear ? year === selectedYear : true;
  //       const matchesServiceType = selectedServiceType
  //         ? serviceType === selectedServiceType
  //         : true;

  //       return (
  //         Name.toLowerCase().includes(query) ||
  //         Email.toLowerCase().includes(query) ||
  //         (mobileOne && mobileOne.includes(query)) ||
  //         (mobileTwo && mobileTwo.includes(query)) ||
  //         (mobileThree && mobileThree.includes(query)) ||
  //         monthName.includes(query) || // Month name search
  //         day?.includes(query) ||
  //         serviceType.toLowerCase().includes(query) ||
  //         (Amount.toString().includes(query) && // Convert Amount to string for comparison
  //           matchesYear &&
  //           matchesServiceType)
  //       );
  //     })
  //   : // .sort((a, b) => {
  //     //   const dateA = new Date(a.eventDate);
  //     //   const dateB = new Date(b.eventDate);

  //     //   // Ensure the dates are valid
  //     //   if (isNaN(dateA) || isNaN(dateB)) {
  //     //     return 0; // Maintain original order if dates are invalid
  //     //   }
  //     //   return dateB - dateA; // Sort by eventDate descending
  //     // })
  //     [];

  useEffect(() => {
    // Trim the search query to remove leading/trailing spaces and convert to lowercase
    const query = searchQuery.trim().toLowerCase();

    // Regular expressions to match different date formats:
    const fullDateRegex = /^([a-z]+)\s(\d{1,2}),?\s(\d{4})$/i; // e.g., "November 27, 2024"
    const monthDayRegex = /^([a-z]+)\s(\d{1,2})$/i; // e.g., "November 27"
    const monthRegex = /^([a-z]+)\s?$/i; // e.g., "November"
    const yearRegex = /^\s*(\d{4})\s*$/; // e.g., "2024"

    // Try to match the query against the different formats
    const matchFullDate = query.match(fullDateRegex); // Match full date "Month Day Year"
    const matchMonthDay = query.match(monthDayRegex); // Match "Month Day"
    const matchMonth = query.match(monthRegex); // Match only "Month"
    const matchYear = query.match(yearRegex); // Match only year (e.g., "2024")

    // Filtering logic based on the search query, selected year, and selected service type
    const filtered = bookingDetails.filter((booking) => {
      const {
        Id,
        Name,
        Email,
        mobileOne,
        mobileTwo,
        mobileThree,
        eventDate,
        serviceType,
        Amount,
      } = booking;

      let year, month, day;
      if (eventDate && typeof eventDate === "string") {
        [year, month, day] = eventDate.split("-"); // Split date as "YYYY-MM-DD"
      }

      const matchesYear = selectedYear ? year === selectedYear : true;
      const matchesServiceType = selectedServiceType
        ? serviceType === selectedServiceType
        : true;

      // Extract month name and day from eventDate
      const monthName = month
        ? monthNames[parseInt(month) - 1]?.toLowerCase()
        : "";
      const dayString = day ? day : "";

      // Handle matching full date "Month Day Year"
      let dateMatch = false;

      if (matchFullDate) {
        const queryMonth = matchFullDate[1]; // e.g., "November"
        const queryDay = matchFullDate[2]; // e.g., "27"
        const queryYear = matchFullDate[3]; // e.g., "2024"

        // Check if the eventDate matches the exact date
        dateMatch =
          monthName === queryMonth.toLowerCase() &&
          dayString === queryDay &&
          year === queryYear;
      }
      // Handle matching "Month Day" (ignoring the year)
      else if (matchMonthDay) {
        const queryMonth = matchMonthDay[1]; // e.g., "November"
        const queryDay = matchMonthDay[2]; // e.g., "27"

        // Check if the eventDate matches the month and day (ignoring year)
        dateMatch =
          monthName === queryMonth.toLowerCase() && dayString === queryDay;
      }
      // Handle matching just "Month"
      else if (matchMonth) {
        const queryMonth = matchMonth[1]; // e.g., "November"

        // Check if the eventDate matches the month (ignoring day and year)
        dateMatch = monthName.includes(queryMonth.toLowerCase());
      }

      // Handle matching only the "Year" (e.g., "2024")
      let yearMatch = false;
      if (matchYear) {
        const queryYear = matchYear[1]; // e.g., "2024"
        yearMatch = year === queryYear; // Match the exact year
      }

      // Now, handle service type matching (if serviceType is a number or string)
      let serviceMatch = false;
      const isNumber = (value) => !isNaN(value);
      // Check if serviceType is a number (index)
      if (isNumber(serviceType)) {
        const serviceString = options[serviceType - 1] || ""; // Map number to option
        serviceMatch = serviceString.toLowerCase().includes(query); // Check if the option matches the query
      }
      // Check if serviceType is a string
      else {
        serviceMatch = serviceType.toLowerCase().includes(query);
      }

      // Perform the final check for name, email, phone, and other fields
      return (
        ((Id && Id.toString().includes(query)) ||
          (typeof Name === "string" && Name.toLowerCase().includes(query)) ||
          (typeof Email === "string" && Email.toLowerCase().includes(query)) ||
          (typeof mobileOne === "string" && mobileOne.includes(query)) ||
          (typeof mobileTwo === "string" && mobileTwo.includes(query)) ||
          (typeof mobileThree === "string" && mobileThree.includes(query)) ||
          dateMatch || // Check for matching full date (Month Day Year) or partial date
          serviceMatch || // Check for matching serviceType
          yearMatch || // Check for matching year
          (Amount && Amount.toString().includes(query))) &&
        matchesYear &&
        matchesServiceType
      );
    });

    setFilteredBookingDetails(filtered);
  }, [searchQuery, selectedYear, selectedServiceType, bookingDetails]);

  useEffect(() => {
    fetchDetailsGurdwara();
    fetchBookingDetails();
    if (isAdmin) {
      setActiveTab("BookingList");
    }
  }, [id]);
  let bags =
    "https://e7.pngegg.com/pngimages/686/749/png-clipart-golden-temple-amritsar-car-hire-self-driven-car-rental-in-amritsar-self-drive-car-in-amritsar-india-gurdwara-gurdwara-building-medieval-architecture-thumbnail.png";
  const navigate = useNavigate();
  console.log(years, "years");
  console.log(filteredBookingDetails, "filteredBookingDetails");
  return (
    <section className="home-after-login bg-white">
      <div className="container">
        <Row>
          <Col sm={4} lg={3}>
            <Sidebar />
          </Col>
          <Col sm={8} lg={9} className="gurdwara-container position-relative ">
            <Row
              className="d-flex row my-2 wc_ib-tabs w-100"
              style={{ marginLeft: "0px" }}
            >
              <Col
                sm={12}
                lg={6}
                className="d-flex mb-2 gap-10 justify-content-end w-100"
              >
                {(activeTab === "BookingList" ||
                  (isAdmin && activeTab === "BookingList")) && (
                  <Row>
                    <Col lg={7} sm={6} className="p-0">
                      <div style={{ height: "50px" }}>
                        <Input
                          type="search"
                          name="search"
                          id="search"
                          placeholder="Search by Name, Phonenumber, Email, Event Date, Event Month, Event Type..."
                          value={searchQuery}
                          onChange={handleSearchChange}
                          style={{ height: "100%" }}
                        />
                      </div>
                    </Col>
                    <Col lg={5} sm={6} className="p-0">
                      <InputGroupText>
                        <div style={{ width: "200px" }}>
                          <Input
                            type="select"
                            name="year"
                            id="year"
                            placeholder="Select Year"
                            value={selectedYear}
                            onChange={handleYearChange}
                          >
                            <option value="">Year</option>
                            {years.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </Input>
                        </div>
                        <div>
                          <Input
                            type="select"
                            name="serviceType"
                            id="serviceType"
                            placeholder="Select Service Type"
                            value={selectedServiceType}
                            onChange={handleServiceTypeChange}
                          >
                            <option value="">Service Type</option>
                            {serviceTypes.map((serviceType) => (
                              <option key={serviceType} value={serviceType}>
                                {isNaN(serviceType)
                                  ? serviceType
                                  : options[serviceType - 1]}
                              </option>
                            ))}
                          </Input>
                        </div>
                        <div className="mx-2">
                          <BiSearch style={{ cursor: "pointer" }} />
                        </div>
                      </InputGroupText>
                    </Col>
                  </Row>
                )}
              </Col>
              {!isAdmin ? (
                <div className="d-flex p-0">
                  <h5
                    className={`col-sm-4 ${
                      activeTab === "ManageGurdwara" ? "active " : ""
                    } mx-0`}
                    onClick={() => {
                      setActiveTab("ManageGurdwara");
                    }}
                    style={{
                      textDecoration: activeTab !== "" && "none", // Apply underline when active
                      cursor: "pointer", // Add cursor style to indicate clickable
                      width: "34%",
                    }}
                  >
                    Manage Gurdwara
                  </h5>

                  <h5
                    className={`col-sm-4 ${
                      activeTab === "BookingList" ? "active" : ""
                    } mx-0`}
                    onClick={() => {
                      setActiveTab("BookingList");
                      fetchBookingDetails();
                    }}
                    style={{
                      textDecoration: activeTab !== "ManageGurdwara" && "none", // Apply underline when active
                      cursor: "pointer", // Add cursor style to indicate clickable
                      width: "33%",
                    }}
                  >
                    Booking List
                  </h5>
                  <h5
                    className={`col-sm-4 ${
                      activeTab === "DonationList" ? "active" : ""
                    } mx-0`}
                    onClick={() => {
                      setActiveTab("DonationList");
                      fetchDonationDetails();
                    }}
                    style={{
                      textDecoration: activeTab !== "ManageGurdwara" && "none", // Apply underline when active
                      cursor: "pointer", // Add cursor style to indicate clickable
                      width: "33%",
                    }}
                  >
                    Donation List
                  </h5>
                </div>
              ) : (
                <>
                  <h5
                    // className="fw-bold fs-3 inter-font text-decoration-underline"
                    className={`col-sm-4 ${
                      activeTab === "BookingList" ? "active" : ""
                    } mx-0`}
                    onClick={() => {
                      setActiveTab("BookingList");
                      fetchBookingDetails();
                    }}
                    style={{
                      textDecoration: activeTab !== "ManageGurdwara" && "none", // Apply underline when active
                      cursor: "pointer", // Add cursor style to indicate clickable
                      width: "50%",
                    }}
                  >
                    Booking List
                  </h5>
                  <h5
                    className={`col-sm-4 ${
                      activeTab === "DonationList" ? "active" : ""
                    } mx-0`}
                    onClick={() => {
                      setActiveTab("DonationList");
                      fetchDonationDetails();
                    }}
                    style={{
                      textDecoration: activeTab !== "ManageGurdwara" && "none", // Apply underline when active
                      cursor: "pointer", // Add cursor style to indicate clickable
                      width: "50%",
                    }}
                  >
                    Donation List
                  </h5>
                </>
              )}
            </Row>
            <div className="tab-content">
              {activeTab === "ManageGurdwara" && (
                <div>
                  <ManageGurdwara isLoading={isLoading} gurdwara={gurdwara} />
                </div>
              )}
              {activeTab === "BookingList" && (
                <div>
                  <BookingList
                    isLoading={isLoading}
                    bookingDetails={filteredBookingDetails}
                    fetchBookingDetails={fetchBookingDetails}
                  />
                </div>
              )}
              {activeTab === "DonationList" && (
                <div>
                  <DonationList
                    isLoading={isLoading}
                    donationDetails={donationDetails}
                    fetchDonationDetails={fetchDonationDetails}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default MyGurudwaraPage;
