import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Rating,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useDispatch, useSelector } from "react-redux";
import { setItemsInAds } from "../../../redux/slices/LatestAdsSlice";
import { useParams } from "react-router-dom";
import axiosClient from "../../../axios-client";
import { toast } from "react-toastify";
import { FadeLoader } from "react-spinners";
import "./ItemDetails.css";
import { setMyCartItems } from "../../../redux/slices/ItemsDataSlice";
import ConfirmRemoveModal from "../../../pages/PeopleInMyCommunity/ConfirmRemoveModal";
import AddToCartModal from "../../../pages/PeopleInMyCommunity/AddToCartModal";

const ItemDetails = () => {
  const [loading, setLoading] = useState(false);
  const { itemsInAds } = useSelector((state) => state.latestState);
  const { myCartItems } = useSelector((state) => state.ItemsData);
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const { Id: itemId } = useParams();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [alertModal, setAlertModal] = useState(false);
  const toggle = () => setOpenModal(!openModal);
  const toggleAlert = () => setAlertModal(!alertModal);
  const toggleConfirm = () => setConfirmModal(!confirmModal);
  const handleConfirmRemove = async () => {
    const request = {
      cartStatus: 2,
    };
    try {
      const response = await axiosClient.put(
        `item/StatusUpdateCart/${user.UserID}`,
        request
      );
      if (response.status === 200) {
        getCartItems();
        toggleAlert();
        toggleConfirm(); // Close the confirmation modal
        toggle(); // Open the AddToCartModal after clearing the cart
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAllIfs = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.get(`item/getAllItem`);
      if (response.data.statusCode === 200) {
        let items = response.data.itemList.filter(
          (item) => item.item_status === 1
        );
        dispatch(setItemsInAds(items));
      } else {
        toast.error(`${response.data.message} ${response.data.statusCode}`);
      }
    } catch (error) {
      console.error("Error fetching items:", error);
      toast.error("Failed to fetch items.");
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllIfs();
  }, []);

  useEffect(() => {
    getCartItems();
    if (itemsInAds.length > 0 && itemId) {
      const item = itemsInAds.find((item) => item.Id == itemId);
      if (item) {
        setSelectedItem(item);
      }
    }
  }, [itemsInAds, itemId]); // Run this effect when itemsInAds or itemId changes
  useEffect(() => {
    if (itemsInAds && myCartItems) {
      const updatedItems = itemsInAds.map((item) => {
        if (myCartItems.some((cartItem) => cartItem.item_id === item.Id)) {
          return { ...item, isAdded: true };
        }
        return item;
      });
      if (JSON.stringify(itemsInAds) !== JSON.stringify(updatedItems)) {
        dispatch(setItemsInAds(updatedItems));
      }
    }
  }, [itemsInAds, myCartItems]); // Remove openModal

  const deliveryOptionsMap = {
    1: { label: "Standard 3 Days Delivery", cost: "$5.00" },
    2: { label: "Same Day Delivery", cost: "$10.00" },
    3: { label: "Pick Up In Person", cost: "Free" },
  };

  const Options =
    selectedItem && selectedItem.delivery_option
      ? JSON.parse(selectedItem.delivery_option)
      : [];

  const deliveryOptions = Options.map((value) => ({
    value,
    ...deliveryOptionsMap[value],
  }));
  const handleAdd = (item) => {
    if (
      myCartItems.length > 0 &&
      myCartItems.some((cartItem) => cartItem.created_by !== item.created_by)
    ) {
      toggleConfirm(); // Open the confirmation modal if different user items are in the cart
    } else {
      toggle(); // Open AddToCartModal if same user or cart is empty
    }
  };
  const getCartItems = async () => {
    try {
      const response = await axiosClient.get(`item/getCartItem/${user.UserID}`);
      if (response.status === 200) {
        dispatch(setMyCartItems(response.data.cartList));
      }
    } catch (error) {
      console.log(error);
    }
  };
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "150px",
        }}
      >
        <FadeLoader color="#36d7b7" />
      </div>
    );
  }

  return (
    <div>
      {selectedItem && (
        <Box sx={{ padding: 3, maxWidth: "1200px", margin: "0 auto" }}>
          <Grid container spacing={4}>
            {/* Product Image */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardMedia
                  component="img"
                  height="400"
                  image={
                    selectedItem?.image
                      ? selectedItem?.image
                      : "https://st3.depositphotos.com/6672868/13801/v/600/depositphotos_138013506-stock-illustration-user-profile-group.jpg"
                  }
                  alt="Product Image"
                  sx={{ objectFit: "cover" }}
                />
              </Card>
            </Grid>

            {/* Product Details */}
            <Grid item xs={12} md={6}>
              <CardContent>
                <Typography variant="h4" gutterBottom>
                  {selectedItem.name}
                </Typography>
                {/* <Rating
                  value={4.5}
                  precision={0.5}
                  readOnly
                  sx={{ marginBottom: 2 }}
                /> */}

                <Typography
                  variant="h6"
                  color="text.secondary"
                  sx={{ marginBottom: 2 }}
                >
                  ${selectedItem.price}
                </Typography>

                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ marginBottom: 3 }}
                >
                  {selectedItem?.description}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ marginBottom: 3 }}
                >
                  <b>Delivery Options:</b>
                  <ul>
                    {deliveryOptions.map((option) => (
                      <li key={option.value}>
                        {option.label}
                        <span className="text-primary fw-bold ps-1">
                          {option.cost}
                        </span>
                      </li>
                    ))}
                  </ul>
                </Typography>

                <Button
                  variant="contained"
                  color="primary"
                  disabled={selectedItem.isAdded}
                  startIcon={<ShoppingCartIcon />}
                  onClick={() => handleAdd(selectedItem)}
                  className={`item-to-cart d-inline ${
                    selectedItem.isAdded
                      ? `bg-success text-white`
                      : `bg-warning text-black `
                  }`}
                >
                  {selectedItem.isAdded ? "Added" : "Buy Now"}
                </Button>
              </CardContent>
            </Grid>
          </Grid>
        </Box>
      )}
      <AddToCartModal
        open={openModal}
        toggle={toggle}
        selectedItem={selectedItem}
        getAllIfs={getAllIfs}
        myCartItems={getCartItems}
      />

      <ConfirmRemoveModal
        open={confirmModal}
        toggle={toggleConfirm}
        onConfirm={handleConfirmRemove}
      />
    </div>
  );
};

export default ItemDetails;
