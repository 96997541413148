import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categoryId: null,
  subCategoryId: null,
  itemId: null,
  categoryName: "",
  subCategoryName: "",
  itemName: "",
  Title: "",
  Description: "",
  Photo: [""],
  Zipcode: "",
  FullName: "",
  Phone: null,
  Terms: 0,
  adPosting: 0,
  dialExtension: "",
  Gurudwara: "",
  eventDate: "",
  eventTime: "",
  eventDetails: [],
  availableFrom: [{ days: [], from: "", to: "" }],
  availableTo: "",
  engagedCouple: 0,
  Status: 0,
  Email: "",
  createdEmail: "", // Update this as per your login logic
  categoryAnswer: "",
  notifyItemList: "",
  updatedBy: null,
  createdBy: null,
  alternativePhoneOne: "",
  alternativePhoneTwo: "",
  timeZone: "America/Los_Angeles, Pacific Time",
  needRadius: null,
};

const RequestFormSlice = createSlice({
  name: "requestForm",
  initialState,
  reducers: {
    resetFormState: (state) => initialState,
    setCategoryId: (state, action) => {
      state.categoryId = action.payload;
    },
    setSubCategoryId: (state, action) => {
      state.subCategoryId = action.payload;
    },
    setItemId: (state, action) => {
      state.itemId = action.payload;
    },
    setCategoryName: (state, action) => {
      state.categoryName = action.payload;
    },
    setSubCategoryName: (state, action) => {
      state.subCategoryName = action.payload;
    },
    setItemName: (state, action) => {
      state.itemName = action.payload;
    },
    setTitle: (state, action) => {
      state.Title = action.payload;
    },
    setDescription: (state, action) => {
      state.Description = action.payload;
    },
    setPhoto: (state, action) => {
      state.Photo = action.payload;
    },
    setZipcode: (state, action) => {
      state.Zipcode = action.payload;
    },
    setFullName: (state, action) => {
      state.FullName = action.payload;
    },
    setPhone: (state, action) => {
      state.Phone = action.payload;
    },
    setTerms: (state, action) => {
      state.Terms = action.payload;
    },
    setAdPosting: (state, action) => {
      state.adPosting = action.payload;
    },
    setDialExtension: (state, action) => {
      state.dialExtension = action.payload;
    },
    setGurudwara: (state, action) => {
      state.Gurudwara = action.payload;
    },
    setEventDate: (state, action) => {
      state.eventDate = action.payload;
    },
    setEventTime: (state, action) => {
      state.eventTime = action.payload;
    },
    setEventDetails: (state, action) => {
      state.eventDetails = action.payload;
    },
    setAvailableFrom: (state, action) => {
      state.availableFrom = action.payload;
    },
    setAvailableTo: (state, action) => {
      state.availableTo = action.payload;
    },
    setEngagedCouple: (state, action) => {
      state.engagedCouple = action.payload;
    },
    setStatus: (state, action) => {
      state.Status = action.payload;
    },
    setEmail: (state, action) => {
      state.Email = action.payload;
    },
    setCreatedEmail: (state, action) => {
      state.createdEmail = action.payload;
    },
    setCategoryAnswer: (state, action) => {
      state.categoryAnswer = action.payload;
    },
    setNotifyItemList: (state, action) => {
      state.notifyItemList = action.payload;
    },
    setUpdatedBy: (state, action) => {
      console.log("Reducer setUpdatedBy called with payload:", action.payload);
      state.updatedBy = action.payload;
    },
    setCreatedBy: (state, action) => {
      state.createdBy = action.payload;
    },
    setAlternativePhoneOne: (state, action) => {
      state.alternativePhoneOne = action.payload;
    },
    setAlternativePhoneTwo: (state, action) => {
      state.alternativePhoneTwo = action.payload;
    },
    setTimeZone: (state, action) => {
      state.timeZone = action.payload;
    },
    setNeedRadius: (state, action) => {
      state.needRadius = action.payload;
    },
  },
});

export const {
  resetFormState,
  setCategoryId,
  setSubCategoryId,
  setItemId,
  setCategoryName,
  setSubCategoryName,
  setItemName,
  setTitle,
  setDescription,
  setPhoto,
  setZipcode,
  setFullName,
  setPhone,
  setTerms,
  setAdPosting,
  setDialExtension,
  setGurudwara,
  setEventDate,
  setEventTime,
  setEventDetails,
  setAvailableFrom,
  setAvailableTo,
  setEngagedCouple,
  setStatus,
  setEmail,
  setCreatedEmail,
  setCategoryAnswer,
  setNotifyItemList,
  setUpdatedBy,
  setCreatedBy,
  setAlternativePhoneOne,
  setAlternativePhoneTwo,
  setTimeZone,
  setNeedRadius,
} = RequestFormSlice.actions;

export default RequestFormSlice.reducer;
