import React, { useState } from "react";
import { Modal, ModalBody, Button, Input } from "reactstrap";
import { toast } from "react-toastify";
import {
  downloadPaymentCSV,
  addPaypalPament,
} from "../../../components/Header/Data";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const PayModal = ({ isModalOpen, handleToggleModal, selectedMsg }) => {
  // const selectedMsg = useSelector((state) => state.messages.selectedConvo);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const Id = queryParams.get("id");
  const Name = queryParams.get("Name");
  const Email = queryParams.get("email");
  const phone = queryParams.get("phone");
  const Location = queryParams.get("Location");
  const navigate = useNavigate();
  const handlePay = () => {
    if (islogin) {
      navigate("/order/summary", {
        state: {
          msg: selectedMsg,
          reqPhoneAmount: 10,
          reqPhone: true,
        },
      });
    } else {
      navigate("/login");
      toast.info("Please Login");
    }
  };

  // const days = {};
  const days =
    selectedMsg?.availableFrom && JSON.parse(selectedMsg?.availableFrom);
  console.log(days, "days");

  const handleDownloadCSV = async () => {
    try {
      if (!startDate) {
        toast.error("Please select start date");
        return;
      }
      if (!endDate) {
        toast.error("Please select end date");
        return;
      }

      if (startDate > endDate) {
        toast.info("Start Date should be before End Date");
        return;
      }
      const formData = {
        startTime: startDate,
        endTime: endDate,
      };
      console.log(formData, "formData");
      const response = await downloadPaymentCSV(formData);
      console.log(response, "Response data");
      toast.success("Downloaded");

      // Format response data as CSV
      let csvContent =
        "Amount,Date,Email,First Name,Last Name,Paypal Email,Status\n"; // CSV header

      response.forEach((row) => {
        const values = [
          row.amount,
          row.created_at,
          row.email,
          row.first_name,
          row.last_name,
          row.paypal_email || "", // Handle null values
          row.paypal_status,
        ];
        csvContent += values.join(",") + "\n";
      });

      // Create Blob for CSV content
      const csvBlob = new Blob([csvContent], {
        type: "text/csv;charset=utf-8",
      });

      // Trigger file download
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(csvBlob);
      downloadLink.setAttribute("download", "payment_history.csv");
      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);

      handleToggleModal();
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  const handleCheck = (e) => {
    console.log(e.target.checked, "termsAccepted");
    setTermsAccepted(e.target.checked);
  };

  const addPayment = async () => {
    if (!termsAccepted) {
      toast.error("Terms and Conditions not accepted");
      return;
    }
    setPaymentInProgress(true);
    const formData = {
      senderId: user.RoleId,
      receiverId: parseInt(Id),
      Amount: Math.floor(selectedMsg?.total_amount),
      Email: Email,
      orderId: selectedMsg?.id,
    };
    await addPaypalPament(formData);
    toast.success("Payment added successfully");
    handleToggleModal();
    setPaymentInProgress(false);
    setTermsAccepted(false);
    window.location.reload();
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
    return formattedDate;
  }

  function convertTo12HourFormat(time24) {
    if (!time24) {
      return "";
    }
    const [hour24, minute] = time24.split(":").map(Number);
    if (isNaN(hour24) || isNaN(minute)) {
      return "";
    }
    const period = hour24 >= 12 ? "PM" : "AM";
    const hour12 = hour24 % 12 || 12;
    return `${hour12.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")} ${period}`;
  }
  return (
    <>
      <Modal isOpen={isModalOpen} toggle={handleToggleModal}>
        <ModalBody
          style={{
            height: `${
              selectedMsg
                ? !selectedMsg?.availableFrom
                  ? "455px"
                  : "375px"
                : "335px"
            }`,
            //position: "relative",
            //marginBottom: "10px",
          }}
        >
          {!selectedMsg ? (
            <div className="p-3 m-2">
              <h4>
                Download CSV
                {/* <Button
                  close
                  onClick={handleToggleModal}
                  style={{ position: "absolute", top: "32px", right: "40px" }}
                /> */}
              </h4>
              <div className="mb-3 mt-3">
                <label htmlFor="startDate" className="form-label">
                  Start Date:
                </label>
                <Input
                  type="date"
                  id="startDate"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="endDate" className="form-label">
                  End Date:
                </label>
                <Input
                  type="date"
                  id="endDate"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
              <div className="d-flex justify-content-end gap-2">
                <Button color="primary" onClick={handleDownloadCSV}>
                  Download
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={() => {
                    handleToggleModal();
                    setStartDate("");
                    setEndDate("");
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <>
              {selectedMsg?.availableFrom ? (
                <div className="p-2 my-4 m-2">
                  <h5 className="text-center">
                    {/* <Button
                  close
                  onClick={handleToggleModal}
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                /> */}
                    <div>
                      Please contact user at their preferred availability time
                      as mentioned below <br /> <br />
                      Available On:
                      {selectedMsg?.createdBy === user?.UserID ? (
                        <>
                          {days?.map((day) => (
                            <h6 key={day} className="mt-2">
                              {day?.days.map((day) => day + ", ")}
                              <h6 className="mt-2">
                                {day?.from && day?.to !== "" && (
                                  <>
                                    From: {convertTo12HourFormat(day?.from)} To:{" "}
                                    {convertTo12HourFormat(day?.to)}
                                  </>
                                )}
                              </h6>
                            </h6>
                          ))}
                        </>
                      ) : (
                        <>
                          <h6 className="mt-5 mb-5">
                            No Available Time Provided
                          </h6>
                        </>
                      )}
                      {/* Available Time: {formatDate(
                        selectedMsg?.reserveDate
                      )}{" "}
                      {convertTo12HourFormat(selectedMsg?.startTime)} */}
                    </div>
                  </h5>
                  <p className=" mt-3 text-black">
                    <b>Note:</b> Communicate for FREE by scheduling a call!
                  </p>
                  <div className="gap-2 d-flex justify-content-end">
                    <small className="mt-2"></small>
                    <Button color="success" onClick={handlePay}>
                      Pay
                    </Button>
                    <Button color="secondary" onClick={handleToggleModal}>
                      Cancel
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="p-4">
                    <form className="">
                      <p className="fw-bold mb-3" style={{ fontSize: "20px" }}>
                        Please check below details:
                      </p>
                      <div className="form-group pb-2">
                        <strong>Provider Name:&nbsp;</strong>
                        <span>{Name}</span>
                      </div>
                      <div className="form-group pb-2">
                        <strong htmlFor="paypalId">
                          PayPal ID (Email):&nbsp;
                        </strong>
                        <span> {Email}</span>
                      </div>
                      <div className="form-group pb-2">
                        <strong htmlFor="phoneNumber">
                          Phone Number:&nbsp;
                        </strong>
                        <span> {phone}</span>
                      </div>
                      <>
                        <div className="form-group pb-2">
                          <strong htmlFor="eventName">
                            Service Type:&nbsp;
                          </strong>
                          <span>{selectedMsg?.list_name}</span>
                        </div>
                        <div className="form-group pb-2">
                          <strong htmlFor="eventDate">Event Date:&nbsp;</strong>
                          <span>{formatDate(selectedMsg?.reserve_date)}</span>
                        </div>
                        <div className="form-group pb-2">
                          <strong htmlFor="paidDate">
                            Paid by Customer:&nbsp;
                          </strong>
                          <span>{formatDate(selectedMsg?.created_at)}</span>
                        </div>
                        <div className="form-group pb-2">
                          <strong htmlFor="amountToBePaid">
                            Amount to be paid:&nbsp;
                          </strong>
                          <span>{selectedMsg?.total_amount}</span>
                        </div>
                      </>
                      <div className="form-group pb-2 mb-2">
                        <strong htmlFor="location">Location:&nbsp;</strong>
                        <span>{Location}</span>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          onChange={(e) => handleCheck(e)}
                        />
                        <span className="mx-2">
                          I accept terms & conditions
                        </span>
                      </div>
                    </form>
                    <div className="mt-2 d-flex justify-content-end gap-2">
                      <Button
                        color="success"
                        onClick={addPayment}
                        disabled={paymentInProgress}
                      >
                        {paymentInProgress ? "Processing..." : "Pay"}
                      </Button>
                      <Button
                        color="secondary"
                        onClick={handleToggleModal}
                        disabled={paymentInProgress}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default PayModal;
