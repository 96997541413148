import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  FaDollarSign,
  FaLocationArrow,
  FaMapMarked,
  FaMapMarker,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import axiosClient from "../../../axios-client";
import userIcon from "../../../assets/images/ext/user-profile.jpg"; // Assuming this is the placeholder image
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import OrderSummary from "./OrderSummary";
import { getAmount } from "../../GlobalFunctions/Global";
import { FadeLoader } from "react-spinners";
import { getMyCartItems } from "../../../components/Header/MyCart";
// import "./itemCard.css"; // Optional, for additional styling if needed

export default function ItemsInCart() {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [profileItems, setProfileItems] = React.useState([]);
  const [sending, setSending] = React.useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const myCartItems = useSelector((state) => state.cart.myCartItems);

  const handleRemove = async (item) => {
    setSending(true);
    console.log(item, "item");
    const request = {
      itemId: item.item_id,
      cartStatus: 2,
    };
    try {
      const response = await axiosClient.put(
        `item/StatusUpdateCart/${item?.cart_id}`,
        request
      );
      if (response.data.statusCode === 200) {
        toast.success(`${item.name} Removed`);
        getMyCartItems(user.UserID, dispatch);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setSending(false);
  };

  const handlePlaceOrder = async (item) => {
    navigate("/order/summary", {
      state: {
        itemOrder: item,
        itemCost: getAmount(item.price, item.delivery_option),
        item: true,
      },
    });
  };
  const getDeliveryText = (option) => {
    switch (option) {
      case "1":
        return "Standard 3 Days Delivery";
      case "2":
        return "Same Day Delivery";
      case "3":
        return "Pick Up In Person";
      default:
        return "not available";
    }
  };
  const getShipping = (option) => {
    switch (option) {
      case "1":
        return "$ 5.00";
      case "2":
        return "$ 10.00";
      case "3":
        return "Free";
      default:
        return "not available";
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "50px",
        }}
      >
        <FadeLoader color="#36d7b7" />
      </div>
    );
  }

  if (!myCartItems || myCartItems.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "50px",
        }}
      >
        <h3>No items In the Cart</h3>
      </div>
    );
  }

  return (
    <div
      className="d-flex flex-wrap"
      style={{
        whiteSpace: "nowrap",
      }}
    >
      {myCartItems &&
        myCartItems.map((item) => (
          <Card
            sx={{ maxWidth: 200, minWidth: 150, margin: "4px 4px" }}
            key={item.id}
          >
            <CardMedia
              component="img"
              height="140"
              image={item.image && item.image !== "" ? item.image : userIcon} // Adjust to your item image URL field
              alt={item.name} // Adjust to your item title
            />
            <CardContent className="p-2">
              <Typography
                gutterBottom
                variant="body1"
                component="div"
                noWrap
                className="d-flex justify-content-between"
              >
                <span
                  style={{
                    color: "black",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  {item.name}
                </span>
                <span
                  className="px-2 text-center bg-warning"
                  style={{
                    color: "black",
                    fontSize: "12px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  $ {item.price}
                </span>
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                  marginBottom: "2px",
                  textWrap: "wrap",
                }}
              >
                {/* <span style={{ fontFamily: "serif" }}>Delivery :</span> */}
                {getDeliveryText(item.delivery_option)}
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "2px" }}>
                <span style={{ fontFamily: "serif" }}>Shipping Charges :</span>{" "}
                <span className="text-primary">
                  {getShipping(item.delivery_option)}
                </span>
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "2px" }}>
                <span style={{ fontFamily: "serif" }}>Amount :</span>
                <span className="text-primary">
                  {getAmount(item.price, item.delivery_option)}
                  {/* {Number(item.price)} */}
                </span>
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "10px" }}>
                <span style={{ fontFamily: "serif" }}>Address :</span>
                {item.address || item.selleraddress || "N/A"}
                <FaMapMarker
                  color="danger"
                  style={{
                    cursor: "pointer",
                    display: "inline",
                    verticalAlign: "middle",
                    color: "red",
                  }}
                />
              </Typography>
              {item.item_status === 3 && (
                <Typography
                  sx={{ marginBottom: "10px", fontFamily: "serif" }}
                  variant="body2"
                >
                  Status : <span className="text-danger">Out of Stock</span>
                </Typography>
              )}

              <div
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                {/* Remove Button */}
                <Button
                  onClick={() => handleRemove(item)}
                  className="btn btn-sm btn-danger"
                  disabled={sending}
                >
                  Remove
                </Button>
                <Button
                  onClick={() => handlePlaceOrder(item)}
                  className="btn btn-sm btn-success"
                  disabled={sending || item.item_status === 3}
                >
                  Place Order
                </Button>
              </div>
            </CardContent>
          </Card>
        ))}
    </div>
  );
}
