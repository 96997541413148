import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedGurdwara: null,
  selectedAmount: null,
  monthlyDonation: null,
  openPaypal: false,
  donationClientId: "",
  donationSecretKey: "",
  clientIdValid: true,
  donationStatus: "",
  donationDetails: "",
  donationResponseId: null,
  donationFrom: "",
};

const donationSlice = createSlice({
  name: "gurdwaraDonation",
  initialState,
  reducers: {
    resetFormState: (state) => initialState,
    setSelectedGurdwara: (state, action) => {
      state.selectedGurdwara = action.payload;
    },
    setSelectedAmount: (state, action) => {
      state.selectedAmount = action.payload;
    },
    setMonthlyDonation: (state, action) => {
      state.monthlyDonation = action.payload;
    },
    setOpenPaypal: (state, action) => {
      state.openPaypal = action.payload;
    },
    setDonationClientId: (state, action) => {
      state.donationClientId = action.payload;
    },
    setDonationSecretKey: (state, action) => {
      state.donationSecretKey = action.payload;
    },
    setClientIdValid: (state, action) => {
      state.clientIdValid = action.payload;
    },
    setDonationStatus: (state, action) => {
      state.donationStatus = action.payload;
    },
    setDonationDetails: (state, action) => {
      state.donationDetails = action.payload;
    },
    setDonationResponseId: (state, action) => {
      state.donationResponseId = action.payload;
    },
    setDonationFrom: (state, action) => {
      state.donationFrom = action.payload;
    },
  },
});

export const {
  setSelectedGurdwara,
  setSelectedAmount,
  setMonthlyDonation,
  setOpenPaypal,
  setDonationClientId,
  setDonationSecretKey,
  setClientIdValid,
  setDonationStatus,
  setDonationDetails,
  setDonationResponseId,
  setDonationFrom,
} = donationSlice.actions;

export default donationSlice.reducer;
