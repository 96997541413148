import React, { useEffect, useState, useRef } from "react";
import { Form, FormGroup, Input, Label, Col, Row, Button } from "reactstrap";
import Select from "react-select";
import { FaInstagram, FaCloudUploadAlt } from "react-icons/fa";
import Maps from "../Maps";
import { countryCodes } from "../../../components/CountryCodes";
import PlaceSearch from "../../../components/PlacesSearch";
import NewlyEngagedForm from "./NewlyEngagedForm";
import heic2any from "heic2any";
import imageCompression from "browser-image-compression";
import { toast } from "react-toastify";
import { FcApprove } from "react-icons/fc";
import uploadToS3 from "../../../utils/awsS3Upload";
import { Link } from "react-router-dom";
import axios from "axios";
export const Profile = ({
  userDetails,
  setUserDetails,
  searchTerm,
  setSearchTerm,
  user,
}) => {
  const [location, setLocation] = useState(true);
  const [city, setCity] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(
    userDetails.CountryCode || "+1"
  );
  const formRef = useRef(null);
  const [accessToken, setAccessToken] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [timeZone, setTimeZone] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [userName, setUserName] = useState("");
  const countryOptions = countryCodes.map((country) => ({
    label: `${country.name} (${country.dial_code}) `,
    value: country.dial_code,
  }));

  useEffect(() => {
    setSelectedCountry(userDetails.CountryCode || "+1");
  }, [userDetails]);
  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   if (name === "ZipCode") {
  //     // Handle clearing the ZipCode field
  //     if (value === "") {
  //       setUserDetails((prevUserDetails) => ({
  //         ...prevUserDetails,
  //         ZipCode: "", // Clear the ZipCode field
  //       }));
  //     } else if (!/^\d+$/.test(value)) {
  //       // Ensure ZipCode is numeric only
  //       return;
  //     } else if (value.length > 6) {
  //       return; // Limit to 6 characters
  //     }
  //   }

  //   // Update other fields normally
  //   setUserDetails((prevUserDetails) => ({
  //     ...prevUserDetails,
  //     [name]: value,
  //   }));
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "ZipCode") {
      if (value === "") {
        setUserDetails((prevUserDetails) => ({
          ...prevUserDetails,
          ZipCode: "",
        }));
      } else if (!/^\d+$/.test(value)) {
        // Ensure ZipCode is numeric only
        return;
      } else if (value.length > 6) {
        return; // Limit to 6 characters
      }
    } else if (
      ["TimeZone", "Email", "Phone", "CountryCode", "UserName"].includes(name)
    ) {
      updateLocalStorage(name, value);
    }

    // Update other fields normally
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      [name]: value,
    }));
  };
  const updateLocalStorage = (key, value) => {
    setUserDetails((prevUserDetails) => {
      const storedData = JSON.parse(localStorage.getItem("USER_DATA")) || {};

      // Check if the value is different before updating
      if (storedData[key] !== value) {
        const updatedData = {
          ...storedData,
          [key]: value,
        };
        localStorage.setItem("USER_DATA", JSON.stringify(updatedData));
      }

      return {
        ...prevUserDetails,
        [key]: value,
      };
    });
  };
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("USER_DATA")) || {};

    const updates = {
      ...(timeZone && { Timezone: timeZone }),
      ...(selectedCountry && { CountryCode: selectedCountry }),
      ...(phone && { Phone: phone }),
      ...(email && { Email: email }),
      ...(userName && { UserName: userName }),
    };

    const hasUpdates = Object.keys(updates).some(
      (key) => storedData[key] !== updates[key]
    );

    if (hasUpdates) {
      const updatedData = { ...storedData, ...updates };
      localStorage.setItem("USER_DATA", JSON.stringify(updatedData));
    }

    console.log(timeZone, "timeZone");
  }, [timeZone, phone, email, userName, selectedCountry]);

  const handleCodeChange = (value) => {
    console.log(value, "value");
    setSelectedCountry(value);
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      CountryCode: value,
    }));
  };

  const handleProfileChange = async (e) => {
    const file = e.target.files[0];
    console.log(file, "file");
    let folder = "profile/";
    if (file) {
      try {
        // if (file.type === "image/heic") {
        //   const convertedBlob = await heic2any({
        //     blob: file,
        //     toType: "image/jpeg",
        //   });
        //   processedFile = new File(
        //     [convertedBlob],
        //     file.name.replace(/\.[^/.]+$/, ".jpg"),
        //     { type: "image/jpeg" }
        //   );
        // }

        // const options = {
        //   maxSizeMB: 1,
        //   maxWidthOrHeight: 800,
        //   useWebWorker: true,
        // };
        // const compressedFile = await imageCompression(processedFile, options);

        const imageUrl = await uploadToS3(file, folder);
        console.log(imageUrl, "imageUrl");

        const reader = new FileReader();
        // reader.onload = (event) => {
        setUserDetails((prevUserDetails) => ({
          ...prevUserDetails,
          Photo: imageUrl,
        }));
        reader.readAsDataURL(file);
      } catch (error) {
        console.error("Error processing file:", error);
      }
    }
  };
  const handleEngaged = () => {
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      EngagedCoupled: prevUserDetails.EngagedCoupled ? 0 : 1, // Toggle between 0 and 1
    }));
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    localStorage.setItem("code", code);

    if (code && !accessToken) {
      axios
        .post("https://api.instagram.com/oauth/access_token", {
          client_id: 479390781368780,
          client_secret: "adf0e37725fba7e5ee43f1fbe1ae46b3",
          grant_type: "authorization_code",
          redirect_uri: "https://weddingcommunity.in/",
          code,
        })
        .then((response) => {
          console.log(response, "response");
          setAccessToken(response.data.access_token);
          getInstagramPhotos(response.data.access_token);
        })
        .catch((error) => {
          console.error(
            "Error exchanging code for access token:",
            error.response?.data || error.message
          );
        });
    }
  }, []);
  console.log(photos, "photos");
  console.log(accessToken, "accessToken");
  const getInstagramPhotos = async (token) => {
    try {
      const response = await axios.get(
        `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink&access_token=${token}`
      );

      if (response.status === 200) {
        setPhotos(response.data.data);
      } else {
        console.error(
          "Error fetching Instagram photos:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 200 range
        console.error(
          "Error fetching Instagram photos:",
          error.response.status,
          error.response.data
        );
      } else if (error.request) {
        // Request was made but no response received
        console.error("Error fetching Instagram photos:", error.request);
      } else {
        // Something else happened
        console.error("Error fetching Instagram photos:", error.message);
      }
    }
  };

  console.log(photos, "photos");
  const handleInsta = (e) => {
    e.stopPropagation();
    const clientId = 479390781368780;
    const redirectUri = "https://weddingcommunity.in/";
    const scope = "user_profile,user_media";
    const authUrl = `https://api.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&scope=${scope}&response_type=code`;
    window.location.href = authUrl;
  };
  useEffect(() => {
    if (formRef.current && userDetails.EngagedCoupled === 1) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [userDetails.EngagedCoupled]);
  console.log(userDetails, "userDetails");
  return (
    <Form>
      <Row className="Profile-Container">
        <div className="row mb-5">
          {" "}
          <h6 style={{ fontWeight: "bold" }} className="col">
            Personal Information
          </h6>
        </div>

        <Col sm={3} className="prof-col mb-3">
          <div
            style={{ cursor: "pointer" }}
            className="profile-pic"
            onClick={() => document.getElementById("fileInput").click()}
          >
            <img
              src={
                userDetails.Photo && userDetails.Photo !== ""
                  ? userDetails.Photo
                  : "https://st4.depositphotos.com/6672868/20448/v/600/depositphotos_204485038-stock-illustration-user-vector-icon-simple-illustration.jpg"
              }
              style={{ borderRadius: "50%" }}
              alt="Profile"
            />

            <input
              type="file"
              id="fileInput"
              accept="image/*,image/heic"
              style={{
                display: "none",
              }}
              onChange={handleProfileChange}
            />
            <span
              style={{
                color: "blue",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              Upload{" "}
              <FaCloudUploadAlt
              // onClick={() => document.getElementById("fileInput").click()}
              />
            </span>
          </div>
          {/* <div className="mt-5 badge badge-success ">
            {userDetails?.caIdStatus === 1 && (
              <h5
                className="text-white  text-center px-2 py-1 m-0 rounded w-75"
                aria-disabled={true}
                onClick={(e) => e.stopPropagation()}
              >
                <FcApprove size={25} /> Verified &nbsp;
              </h5>
            )}
          </div> */}
        </Col>
        <Col sm={9} className="profile-info">
          <Row>
            <Col sm={6} xs={12}>
              <FormGroup>
                <Label>
                  First Name <b className="text-danger">*</b>{" "}
                </Label>
                <Input
                  type="text"
                  name="FirstName"
                  value={userDetails.FirstName}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
            <Col sm={6} xs={12}>
              <FormGroup>
                <Label>
                  Last Name <b className="text-danger">*</b>{" "}
                </Label>
                <Input
                  type="text"
                  name="LastName"
                  value={userDetails.LastName}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label>
              User Name <b className="text-danger">*</b>{" "}
            </Label>
            <Input
              type="text"
              name="UserName"
              value={userDetails.UserName}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              Email <b className="text-danger">*</b>{" "}
            </Label>
            <Input
              type="email"
              name="Email"
              value={userDetails.Email}
              onChange={handleChange}
            />
          </FormGroup>
          <Row>
            <Col sm={3}>
              <Label>Country Code:</Label>
              <FormGroup>
                <div className="d-flex align-items-center login-ctr-select">
                  <Select
                    options={countryOptions}
                    name="CountryCode"
                    placeholder="Select"
                    value={
                      // selectedCountry
                      //   ? selectedCountry
                      //   : userDetails.CountryCode
                      selectedCountry
                        ? {
                            label: selectedCountry,
                            value: selectedCountry,
                          }
                        : null
                    }
                    onChange={(selectedOption) => {
                      handleCodeChange(selectedOption.value);
                    }}
                    className="w-100"
                    // styles={{
                    //   control: (styles) => ({
                    //     ...styles,
                    //     width: "150px",
                    //     borderColor: "black",
                    //   }),
                    //   option: (styles) => ({
                    //     ...styles,
                    //     color: "black",
                    //     fontSize: "small",
                    //     width: "170px",
                    //   }),
                    //   menu: (styles) => ({
                    //     ...styles,
                    //     width: "150px", // Adjust the width of the menu as needed
                    //   }),
                    // }}
                  />
                </div>
              </FormGroup>
            </Col>
            <Col sm={4}>
              <FormGroup>
                <Label>Phone Number</Label>
                <Input
                  type="tel"
                  name="Phone"
                  value={userDetails.Phone}
                  onChange={handleChange}
                  maxLength={10}
                />
              </FormGroup>
            </Col>
            <Col sm={5}>
              <FormGroup>
                <Label>Secondary Phone Number</Label>
                <Input
                  type="tel"
                  name="secondaryPhone"
                  value={userDetails?.secondaryPhone}
                  onChange={handleChange}
                  maxLength={10}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="pb-3">
            <Col sm={6} xs={12}>
              <Label>Show my Instagram Photos</Label>
              <Button
                className="insta-button m-0"
                onClick={(e) => handleInsta(e)}
              >
                <FaInstagram /> Connect Instagram Account
              </Button>
            </Col>
            <Col sm={6} xs={12}>
              <Label>Do you have an upcoming Event?</Label>{" "}
              <div
                className="btn btn-danger m-0"
                // onClick={(e) => e.stopPropagation()}
              >
                <input
                  type="checkbox"
                  value={userDetails.EngagedCoupled}
                  id="flexCheckDefault"
                  checked={userDetails.EngagedCoupled === 1}
                  onChange={handleEngaged}
                />{" "}
                {/* Yes, I recently got Engaged */}
                Yes, I have upcoming event
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm={4} xs={12}>
              <Label>Facebook URL: </Label>
              <Input
                type="text"
                name="facebookUrl"
                value={userDetails?.facebookUrl}
                onChange={handleChange}
              />
            </Col>
            <Col sm={4} xs={12}>
              <Label>Instagram URL: </Label>
              <Input
                type="text"
                name="instagramUrl"
                value={userDetails?.instagramUrl}
                onChange={handleChange}
              />
            </Col>
            <Col sm={4} xs={12}>
              <Label>Yelp URL: </Label>
              <Input
                type="text"
                name="yelpUrl"
                value={userDetails?.yelpUrl}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <FormGroup>
            <Label>Bio</Label>
            <Input
              type="textarea"
              name="BioData"
              value={userDetails.BioData}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      {userDetails.EngagedCoupled === 1 && (
        <Row ref={formRef}>
          <NewlyEngagedForm
            userDetails={userDetails}
            setUserDetails={setUserDetails}
          />
        </Row>
      )}
      <Row className="Profile-Container">
        <h6 style={{ fontWeight: "bold", marginBottom: "20px" }}>
          Set Up Timezone
        </h6>
        <span style={{ fontSize: "small" }}>
          What timezone do you prefer for your Calendar?
        </span>
        <FormGroup>
          <Label>Timezone</Label>
          <Input
            type="select"
            name="TimeZone"
            value={userDetails?.TimeZone}
            onChange={handleChange}
          >
            {/* <option value="">Select One TimeZone</option> */}
            <option value="America/New_York, Eastern Time">
              America/New_York (Eastern Time)
            </option>
            <option value="America/Chicago, Central Time">
              America/Chicago (Central Time)
            </option>
            <option value="America/Denver, Mountain Time">
              America/Denver (Mountain Time)
            </option>
            <option value="America/Los_Angeles, Pacific Time">
              America/Los_Angeles (Pacific Time)
            </option>
            <option value="UTC, Coordinated Universal Time">
              UTC (Coordinated Universal Time)
            </option>
            <option value="Europe/London, GMT">Europe/London (GMT)</option>
            <option value="Europe/Berlin, Central European Time">
              Europe/Berlin (Central European Time)
            </option>
            {/* Add more options as needed */}
          </Input>
        </FormGroup>
      </Row>
      <Row className="Profile-Container">
        <Col sm={8}>
          <h6 style={{ fontWeight: "bold" }}>Location</h6>
          <p className="pb-3">Specify your location</p>
          <div className="form-check form-switch pb-2">
            <Input
              className="form-check-input"
              type="checkbox"
              role="switch"
              checked={location}
              onChange={() => {
                setLocation(!location);
              }}
            />
            <label className="form-check-label">Show/Hide location</label>
          </div>
          {location && (
            <FormGroup>
              <Row>
                <Col sm={6}>
                  <Label>Location</Label>
                  <p style={{ fontSize: "x-small" }}>Select your location</p>
                  <PlaceSearch
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    Location={userDetails.Location}
                  />
                </Col>
                <Col sm={3}>
                  <Label>
                    Radius <b className="text-danger">*</b>
                  </Label>
                  <p style={{ fontSize: "x-small", textWrap: "nowrap" }}>
                    How far can you travel?
                  </p>
                  <Input
                    type="text"
                    name="Radius"
                    value={userDetails.Radius}
                    onChange={handleChange}
                  />
                </Col>
                <Col sm={3}>
                  <Label>
                    Zipcode <b className="text-danger">*</b>
                  </Label>
                  <p style={{ fontSize: "x-small" }}>Add Zipcode</p>
                  <Input
                    type="text" // Change type from "number" to "text"
                    name="ZipCode"
                    value={userDetails.ZipCode}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
            </FormGroup>
          )}
        </Col>
        <Col sm={4}>
          {location && (
            <>
              <Maps zipCode={userDetails?.ZipCode} setCity={setCity} />
              <Link>{city}</Link>
            </>
          )}
        </Col>
      </Row>
    </Form>
  );
};
export const Notifications = ({ Notification, setNotification }) => {
  const handleSwitchChange = () => {
    setNotification((prevNotification) => (prevNotification === 1 ? 0 : 1));
  };
  return (
    <Form className="Profile-Container">
      <h6 style={{ fontWeight: "bold", marginBottom: "20px" }}>
        Email Notifications
      </h6>
      <FormGroup>
        <div class="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            checked={Notification === 1 ? true : false}
            onChange={handleSwitchChange}
          />
          <label className="form-check-label">On / Off</label>
        </div>
      </FormGroup>
    </Form>
  );
};
