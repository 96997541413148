import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from "react-redux";
import { MessageStatusUpdate } from "../Data";
import { toast } from "react-toastify";
import axiosClient from "../../../axios-client";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ApproveOfferModal({ open, close, msg, fetchMsgs }) {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;

  const [offersList, setOffersList] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleApproveAdmin = async (event) => {
    event.stopPropagation();
    const status = {
      messageStatus: 3,
      updatedBy: user.UserID,
      customerId: msg.ownerId,
      needName: msg.postName,
      Budget: msg.Budget,
      Message: msg.Message,
      Email: msg.customerEmail,
      createdBy: msg.createdBy,
      postId: msg.postId,
      parentId: msg.Id,
    };
    await MessageStatusUpdate(status, msg.Id);
    toast.success("Offer Accepted");
    fetchMsgs();
    close();
  };
  const handleClose = () => {
    close();
    setDropdownVisible(false);
  };

  const fetchOffers = async () => {
    try {
      const response = await axiosClient.put(
        `/message/MessageGetCustomerDetails/${msg.postId}`
      );
      if (response.data.statusCode === 200) {
        setOffersList(response.data.Data);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (msg) {
      fetchOffers();
    }
  }, [msg]);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  const formattedDate = (approve_date) => {
    const date = new Date(approve_date);
    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // For 12-hour format
    });
    return formattedDate;
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <b>{msg?.postName}</b> <br />
          Got an Offer
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography>
            <b> Vendors who got this customer:</b> &nbsp;
            <Button
              onClick={toggleDropdown}
              endIcon={
                dropdownVisible ? <KeyboardArrowUp /> : <KeyboardArrowDown />
              }
              sx={{ display: "contents" }}
            >
              {offersList.length}{" "}
              {offersList.length === 1 ? "Vendor" : "Vendors"}
            </Button>
          </Typography>
          {dropdownVisible && (
            <List>
              {offersList.length > 0 ? (
                offersList.map((offer, index) => (
                  <React.Fragment key={`${offer.email}-${index}`}>
                    <ListItem>
                      <ListItemText
                        primary={`${offer.first_name} ${offer.last_name}`}
                        secondary={
                          <>
                            <Typography>
                              <b>Total Offers Count:</b> {offer.total_count}
                            </Typography>
                            {offer?.approve_date && (
                              <Typography>
                                <b>Date And Time:</b>{" "}
                                {formattedDate(offer?.approve_date)}
                              </Typography>
                            )}
                          </>
                        }
                      />
                    </ListItem>
                    {index < offersList.length - 1 && <Divider />}
                  </React.Fragment>
                ))
              ) : (
                <Typography>No vendors available yet.</Typography>
              )}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={(event) => handleApproveAdmin(event)}>
            Approve
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
