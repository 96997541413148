import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useLocation } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Routers from "../../routers/Routers";
import { useSelector } from "react-redux";
import { fetchMyServices } from "../Header/Data";

// Utility function to debounce calls
const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

// Utility function to detect if the device is an iPad
const isIpad = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return (
    /iPad/.test(userAgent) ||
    (navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 1 &&
      window.innerWidth > 767 &&
      window.innerWidth < 1200)
  );
};

const Layout = () => {
  const location = useLocation();
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [open, setOpen] = useState(0);
  const [isOpen, setIsOpen] = useState(() => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const isHomePage =
      (location.pathname === "/" && location.hash === "") ||
      location.hash === "#/home";
    const iPadView = isIpad() || (screenWidth > 767 && screenWidth < 1200);
    return !(isHomePage && iPadView);
  });

  // Refs to store the last known width and height
  const lastWidthRef = useRef(window.innerWidth);
  const lastHeightRef = useRef(window.innerHeight);

  // A flag to prevent the "Touch Here" screen from showing again after it's closed
  const touchScreenDismissed = useRef(false);

  const handleResize = useCallback(
    debounce(() => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const isHomePage =
        (location.pathname === "/" && location.hash === "") ||
        location.hash === "#/home";
      const iPadView = isIpad() || (screenWidth > 767 && screenWidth < 1200);

      // Only trigger if the screen was not dismissed and there was a significant change in width or height
      if (
        !touchScreenDismissed.current &&
        (Math.abs(screenWidth - lastWidthRef.current) > 50 || // Significant width change
          Math.abs(screenHeight - lastHeightRef.current) > 150) // Significant height change (ignore address bar hide/show)
      ) {
        setIsOpen(!(isHomePage && iPadView));
        lastWidthRef.current = screenWidth;
        lastHeightRef.current = screenHeight;
      }
    }, 300), // Add a 300ms debounce
    [location.pathname, location.hash] // Also listen for hash changes
  );

  useEffect(() => {
    handleResize(); // Run on mount
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  // Reset the touchScreenDismissed flag when the user navigates to a new path
  useEffect(() => {
    // Reset flag when the location changes (e.g., from "/" to "/#/home")
    touchScreenDismissed.current = false;

    // Update isOpen state if needed when the route changes
    const screenWidth = window.innerWidth;
    const isHomePage =
      (location.pathname === "/" && location.hash === "") ||
      location.hash === "#/home";
    const iPadView = isIpad() || (screenWidth > 767 && screenWidth < 1200);
    setIsOpen(!(isHomePage && iPadView));
  }, [location.pathname, location.hash]); // Also listen for hash changes

  const memoizedIsOpen = useMemo(() => isOpen, [isOpen]);
  const baseURl = process.env.REACT_APP_BASEURL;

  useEffect(() => {
    if (baseURl === "https://backend.mysikhcommunity.com/wedcom") {
      const script1 = document.createElement("script");
      script1.async = true;
      script1.src = "https://www.googletagmanager.com/gtag/js?id=G-4F4KKBK6HP";
      document.head.appendChild(script1);

      const script2 = document.createElement("script");
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-4F4KKBK6HP');
      `;
      document.head.appendChild(script2);
    }
    fetchUserRole();
  }, []);
  const fetchUserRole = async () => {
    if (islogin) {
      if (user?.RoleId !== 1) {
        const reqData = {
          roleId: 2,
          createdBy: user?.UserID,
        };
        const services = await fetchMyServices(reqData);
        const filterActive = services.filter(
          (service) => !["0", "1"].includes(service.isactive)
        );
        if (filterActive.length > 0) {
          localStorage.setItem("USER_ROLE", "provider");
        } else {
          localStorage.setItem("USER_ROLE", "customer");
        }
      }
    }
  };
  return (
    <div style={{ overflow: "hidden" }}>
      {!isOpen && (
        <div
          className="hide-listings"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            zIndex: 99,
            cursor: "pointer",
            backgroundColor: "#de1c1c",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            setIsOpen(true);
            touchScreenDismissed.current = true; // Mark screen as dismissed after user clicks
          }}
        >
          <h5 className="text-light display-3 fw-bold">
            <span className="ms-5">Touch Here</span> <br /> to View Listings
          </h5>
        </div>
      )}
      {isOpen && (
        <>
          <Header setOpen={setOpen} />
          <Routers open={open} setOpen={setOpen} />
          <Footer />
        </>
      )}
    </div>
  );
};

export default Layout;
