import React, { useEffect } from "react";
import Sidebar from "../SideBar";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { BsPlus } from "react-icons/bs";
import "./DashboardPages.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { fetchUserDetails } from "../../components/Header/Data";
import { useSelector } from "react-redux";
import PlaceHolder from "../../assets/images/user-icon.png";
import { Button } from "@mui/material";
import CustomerGottonModal from "../../admin/userManagement/CustomerGottonModal";
import { PeopleAltOutlined } from "@mui/icons-material";

const DashboardPage = () => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [orders, setOrders] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();
  const [openGottonModal, setOpenGottenModal] = useState(false);
  const logUser = localStorage.getItem("USER_ROLE");
  useEffect(() => {
    const fetchData = async () => {
      if (!user) return;
      const userData = await fetchUserDetails(user.UserID);
      setUserDetails(userData);
    };
    fetchData();
  }, []);
  const onCustomersGotten = (userDetails) => {
    setOpenGottenModal(true);
  };
  return (
    <section className="home-after-login bg-white">
      <div className="container">
        <Row>
          <Col sm={3}>
            <Sidebar />
          </Col>
          <Col sm={9}>
            <div className="card-container">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="d-flex">
                  <p className="text-dark">Customer</p>
                  <p>
                    <Link to="/create-service">
                      <BsPlus /> Become a Provider
                    </Link>
                  </p>
                </div>
                {/* {logUser && logUser != "customer" && (
                  <div>
                    <button
                      onClick={() => onCustomersGotten(userDetails)}
                      className="btn-gotten btn-sm"
                      variant="contained" // Use Material-UI's contained variant
                      startIcon={<PeopleAltOutlined fontSize="small" />} // Add icon at the start
                      sx={{ display: "unset" }}
                    >
                      Customers Gotten
                    </Button>
                  </div>
                )} */}
              </div>

              <div className="row">
                <div className="col-lg-4 p-4">
                  {/* First Row */}
                  <Row sm={6}>
                    <Card className="w-100">
                      <CardBody className="card-body">
                        {/* Display the profile picture */}
                        <div className="profile-picture">
                          <img
                            src={
                              userDetails.Photo
                                ? userDetails.Photo
                                : PlaceHolder
                            }
                            alt={userDetails.UserName}
                            onClick={() => navigate("/dashboardpage/settings")}
                          />
                        </div>
                        <CardTitle className="card-title d-flex justify-content-center align-items-center">
                          PROFILE
                        </CardTitle>
                        <span>
                          {userDetails.UserName
                            ? userDetails.UserName
                            : "userName"}
                        </span>
                        <p>SignUp Date:{userDetails.SignUpDate} </p>
                        <button
                          type="button"
                          className="btn btn-outline-primary mb-1"
                          onClick={() => navigate("/dashboardpage/settings")}
                        >
                          My settings
                        </button>
                        {logUser && logUser != "customer" && (
                          <button
                            onClick={() => onCustomersGotten(userDetails)}
                            type="button"
                            className="btn btn-outline-primary mb-1"
                            title="View customers gotten"
                          >
                            <PeopleAltOutlined
                              fontSize="small"
                              style={{ marginRight: "0.5rem" }}
                            />
                            Customers Gotten
                          </button>
                        )}
                      </CardBody>
                    </Card>
                  </Row>
                  <Row sm={6}>
                    <Card className="w-100">
                      <CardBody className="card-body">
                        <CardTitle className="card-title d-flex justify-content-between align-items-center">
                          <span>MOST POPULAR</span>
                          <Link
                            to="#"
                            style={{ marginLeft: "4rem", color: "blue" }}
                          >
                            seeAll
                          </Link>
                        </CardTitle>
                      </CardBody>
                    </Card>
                  </Row>
                </div>

                <div className="col-lg-4 middle-card p-4 ">
                  {/* Second Row */}
                  <Row style={{ height: "100%" }}>
                    <Card>
                      <CardBody className="card-body">
                        <CardTitle className="card-title d-flex justify-content-between align-items-center">
                          <span>MY ORDERS </span>{" "}
                          <Link
                            to="/dashboardpage/orders"
                            style={{ marginLeft: "4rem", color: "blue" }}
                          >
                            seeAll
                          </Link>
                        </CardTitle>
                        <div>
                          {orders !== null ? (
                            <span>You have no orders</span>
                          ) : (
                            <p>{orders}</p>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Row>
                </div>

                <div className="col-lg-4 p-4">
                  {/* Third Row */}
                  <Row sm={6} style={{ height: "50%", overflowY: "auto" }}>
                    <Card className="w-100">
                      <CardBody className="card-body">
                        <CardTitle className="card-title d-flex justify-content-between align-items-center">
                          <span>MESSAGES</span>{" "}
                          <Link
                            to="/dashboardpage/inbox"
                            style={{ marginLeft: "4rem", color: "blue" }}
                          >
                            seeAll
                          </Link>
                        </CardTitle>
                        <div>
                          {orders !== null ? (
                            <span>You have no messages</span>
                          ) : (
                            <p>{orders}</p>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Row>
                  <Row sm={6} style={{ height: "50%", overflowY: "auto" }}>
                    <Card
                      className="w-100
                    "
                    >
                      <CardBody className="card-body">
                        <CardTitle className="card-title d-flex justify-content-between align-items-center">
                          <span>CALANDER</span>{" "}
                          <Link
                            to="/dashboardpage/calendar"
                            style={{ color: "blue" }}
                          >
                            Go to my Calendar
                          </Link>
                        </CardTitle>
                      </CardBody>
                    </Card>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <CustomerGottonModal
          open={openGottonModal}
          close={() => setOpenGottenModal(false)}
          user={userDetails}
        />
      </div>
    </section>
  );
};

export default DashboardPage;
