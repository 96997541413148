import React, { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import "../Gurdwara.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedDate,
  setSelectedserviceCount,
  setSelectedTime,
  setTimeZone,
} from "../../../../redux/slices/GurdwaraSlice";
import moment from "moment-timezone";
import {
  fetchGurdwaraBookingAdmin,
  fetchGurudwaraBooking,
} from "../../../../components/Header/Data";
import { useLocation, useParams } from "react-router-dom";

const CalendarTimeSlotSelector = () => {
  const dispatch = useDispatch();
  // const [timezone, setTimezone] = useState("America/New_York");
  const [localTimeSlots, setLocalTimeSlots] = useState([]);
  const [previouslySelectedTime, setPreviouslySelectedTime] = useState(null); // State to keep track of the previously selected time
  const [bookingDetails, setBookingDetails] = useState([]);
  const [timeSlotCounts, setTimeSlotCounts] = useState({});
  const [initialSlotCounts, setInitialSlotCounts] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const Id = queryParams.get("id");
  const userTimezone = moment.tz.guess(); // Detect user's local timezone
  const {
    selectedDate,
    selectedTime,
    timezone,
    selectedServiceType,
    selectedserviceAvaialableDays,
    selectedserviceAvaialableTime,
    selectedserviceAvaialableDuration,
    selectedserviceCount,
  } = useSelector((state) => state.gurdwara);
  console.log(
    selectedserviceAvaialableDays,
    selectedserviceAvaialableTime,
    selectedserviceAvaialableDuration,
    selectedserviceCount,
    selectedServiceType,
    "selectedService"
  );
  const initialDate = selectedDate
    ? moment(selectedDate).tz(userTimezone).toDate()
    : new Date();
  useEffect(() => {
    const generateTimeSlots = () => {
      if (
        !selectedserviceAvaialableTime ||
        !selectedserviceAvaialableDuration ||
        !selectedserviceCount ||
        bookingDetails.length === 0 // Ensure booking details are available
      ) {
        return; // Ensure all required values are available
      }

      const { from, to } = selectedserviceAvaialableTime; // Get start and end time
      const duration = selectedserviceAvaialableDuration; // Duration in hours between slots
      const count = selectedserviceCount; // Number of available slots per time

      // Parse the time range
      const startTime = moment(from, "HH:mm");
      const endTime = moment(to, "HH:mm");

      const slots = [];
      const slotCounts = {};

      // Generate time slots within the available time range
      while (startTime.isBefore(endTime)) {
        const slotTime = startTime.format("HH:mm");
        slots.push(slotTime);
        slotCounts[slotTime] = parseInt(count); // Set available slot count for this time
        startTime.add(duration, "hours"); // Increment time by the duration (e.g., 2 hours)
      }

      // Adjust slot counts based on existing bookings
      const filteredBookings = bookingDetails.filter((booking) => {
        const bookingDate = new Date(booking.eventDate);
        const selectedDateInTimezone = moment(selectedDate)
          .tz(userTimezone.split(",")[0])
          .format("YYYY-MM-DD");
        const bookingDateInTimezone = moment(booking.eventDate)
          .tz(userTimezone.split(",")[0])
          .format("YYYY-MM-DD");
        return (
          bookingDateInTimezone === selectedDateInTimezone &&
          booking.serviceType === selectedServiceType &&
          booking.bookingStatus === "5"
        );
      });

      console.log(filteredBookings, "filteredBookings");

      // Update slot counts by checking the bookings for the selected date
      filteredBookings.forEach((booking) => {
        if (slotCounts[booking.eventTime] > 0) {
          slotCounts[booking.eventTime] -= 1; // Decrease available slot count for this time
        }
      });

      // Dynamically assign this slot configuration to the selectedServiceType
      const selectedServiceConfig = { [selectedServiceType]: slotCounts };

      // Update state with the generated slots and selectedServiceType config
      setLocalTimeSlots(slots); // Set the local time slots as an array of time strings
      setTimeSlotCounts(selectedServiceConfig);
    };

    // Generate time slots whenever relevant data changes
    generateTimeSlots();
  }, [
    selectedserviceAvaialableTime,
    selectedserviceAvaialableDuration,
    selectedserviceCount,
    selectedServiceType,
    selectedDate,
    bookingDetails, // Add bookingDetails as a dependency
  ]);

  const hasUpdatedCounts = useRef(false);
  const fetchBookingDetails = async () => {
    const bookingDetails = await fetchGurudwaraBooking(Id);
    setBookingDetails(Array.isArray(bookingDetails) ? bookingDetails : []);
  };
  useEffect(() => {
    fetchBookingDetails();
  }, [Id]);
  const today = new Date(); // Get today's date
  today.setHours(0, 0, 0, 0); // Set time to start of the day for accurate comparison

  // const filteredBookings = bookingDetails.filter((booking) => {
  //   const bookingDate = new Date(booking.eventDate);
  //   return (
  //     booking.bookingStatus === "5" &&
  //     bookingDate.toISOString().split("T")[0] === selectedDate
  //   );
  // });

  // Now, filteredBookings contains the bookings that meet your criteria
  const [processedDates, setProcessedDates] = useState(new Set()); // Keep track of processed dates

  // Function to reduce time slot counts based on filtered booking
  // const selectedDateString = useSelector(
  //   (state) => state.gurdwara.selectedDate
  // );
  // const selectedDate = new Date(selectedDateString); // Convert back to Date object when needed
  const isAvailable = (date) => {
    const selectedDateInTimezone = moment(date)
      .tz(userTimezone, true)
      .startOf("day");
    const today = moment().tz(userTimezone).startOf("day"); // Today's date in the user's timezone

    return (
      selectedserviceAvaialableDays.includes(
        selectedDateInTimezone.format("dddd")
      ) && selectedDateInTimezone.isSameOrAfter(today)
    );
  };

  const onChange = (date) => {
    if (moment(date).isValid()) {
      const selectedDateInTimezone = moment(date)
        .tz(userTimezone, true)
        .startOf("day");
      dispatch(setSelectedDate(selectedDateInTimezone.format("YYYY-MM-DD")));
      dispatch(setSelectedTime(null)); // Reset selected time when date changes
    } else {
      console.error("Invalid date selected:", date);
    }
  };
  const [previousMonth, setPreviousMonth] = useState(null);
  // Handle the selection of a time slot (only decrease the selected time slot)const [previouslySelectedTime, setPreviouslySelectedTime] = useState(null); // State to keep track of the previously selected time

  const handleTimeSlotSelect = (time) => {
    const availableCount = timeSlotCounts[selectedServiceType]?.[time];
    const previousTime = selectedTime; // Store the previously selected time

    // Check if the time slot is already selected
    if (selectedTime === time) {
      // Optionally, you can reset the selected time if it's already selected
      return; // Deselect the time
    } else {
      // Create a copy of initial counts
      const resetSlotCounts = { ...timeSlotCounts };

      // If a new time slot is selected, decrease the slot count
      if (availableCount > 0) {
        // If there was a previously selected time, increase its count
        if (previousTime) {
          resetSlotCounts[selectedServiceType][previousTime] += 1; // Reset the previous time slot count
        }

        resetSlotCounts[selectedServiceType][time] -= 1; // Decrease the available count for the new selected time slot
        setTimeSlotCounts(resetSlotCounts); // Update the state with the new counts
        dispatch(setSelectedTime(time)); // Set the selected time in Redux
      }
    }
  };

  // Set up time slots when the selected service type or time range changes

  useEffect(() => {
    // Only reset selected time if it is not already set
    if (selectedDate && !selectedTime) {
      dispatch(setSelectedTime(null)); // Reset selected time only if it's null and a date is set
    }
  }, [selectedDate, selectedTime, dispatch]);
  const handleActiveDateChange = ({ activeStartDate }) => {
    const currentMonth = activeStartDate.getMonth();
    const currentYear = activeStartDate.getFullYear();

    // If the month has changed (by comparing to previousMonth), reset selected time
    if (
      previousMonth &&
      (currentMonth !== previousMonth.month ||
        currentYear !== previousMonth.year)
    ) {
      dispatch(setSelectedTime(null)); // Reset time on month change
    }

    // Update the previous month to current month and year
    setPreviousMonth({ month: currentMonth, year: currentYear });
  };

  const tileClassName = ({ date, view }) => {
    // Adjust the date to the user's timezone
    const dateInTimezone = moment(date).tz(userTimezone, true).startOf("day");
    console.log(dateInTimezone, "dateInTimezone");
    if (view === "month" && isAvailable(dateInTimezone)) {
      return "highlight"; // Add a custom class for available days
    }

    return null;
  };

  const tileDisabled = ({ date, view }) => {
    if (view === "month") {
      const selectedDateInTimezone = moment(date).tz(userTimezone, true);
      return !isAvailable(selectedDateInTimezone); // Ensure comparison with correct timezone
    }
    return false;
  };
  // useEffect(() => {
  //   const convertTimeSlots = () => {
  //     const convertedTimeSlots = timeSlots.map((time) => formatTimeSlot(time));
  //     setLocalTimeSlots(convertedTimeSlots);
  //   };

  //   convertTimeSlots();
  // }, [timezone, selectedDate]);
  const handleTimezoneChange = (event) => {
    dispatch(setTimeZone(event.target.value));
  };

  const formatTimeSlot = (time) => {
    const [hour, minute] = time.split(":");
    const period = minute.slice(-2);
    const minutePart = minute.slice(0, -2);

    // Convert to 24-hour format
    const timeIn24HourFormat = moment(`${hour}:${minutePart} ${period}`, [
      "h:mm A",
    ]).format("HH:mm");

    // Adjust time to the user's local timezone
    const dateTime = moment(
      `${moment(selectedDate).format("YYYY-MM-DD")} ${timeIn24HourFormat}`
    );
    return dateTime.tz(userTimezone).format("hh:mma"); // Adjust to the user's local timezone
  };

  const getFirstAvailableDate = () => {
    const today = new Date(); // Get today's date
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Get the current day of the week
    const todayDayIndex = today.getDay(); // 0 - Sunday, 1 - Monday, ..., 6 - Saturday

    // Iterate over the next 7 days
    for (let i = 0; i < 7; i++) {
      const checkDate = new Date(today);
      checkDate.setDate(today.getDate() + i); // Increment by i days

      // Get the day name for the current checkDate
      const checkDayName = daysOfWeek[checkDate.getDay()];

      // If the checkDayName is in the available days, return this date as the first available date
      if (selectedserviceAvaialableDays.includes(checkDayName)) {
        return checkDate;
      }
    }
    return null; // If no available date is found
  };

  useEffect(() => {
    const firstAvailableDate = getFirstAvailableDate();
    if (firstAvailableDate) {
      dispatch(setSelectedDate(moment(firstAvailableDate).format("YYYY-MM-DD")));
      console.log(moment(firstAvailableDate).format("YYYY-MM-DD"), "firstAvailableDate");
    }
    // Reset selected time on initial load

    // Add event listeners for the prev and next buttons to detect month changes
    const prevButton = document.querySelector(
      ".calender .react-calendar__navigation__prev-button"
    );
    const nextButton = document.querySelector(
      ".calender .react-calendar__navigation__next-button"
    );

    const handleMonthChange = () => {
      dispatch(setSelectedTime(null)); // Reset selected time when the month changes
    };

    // Attach listeners to the previous and next month buttons
    if (prevButton) prevButton.addEventListener("click", handleMonthChange);
    if (nextButton) nextButton.addEventListener("click", handleMonthChange);

    // Cleanup event listeners when the component is unmounted
    return () => {
      if (prevButton)
        prevButton.removeEventListener("click", handleMonthChange);
      if (nextButton)
        nextButton.removeEventListener("click", handleMonthChange);
    };
  }, [dispatch]);

  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date); // Ensure that date is a Date object if it's not
    }

    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const weekday = weekdays[date.getDay()];
    const month = months[date.getMonth()];
    const day = date.getDate();

    return `${weekday}, ${month} ${day}`;
  };
  const options = [
    { value: "1", label: "Anand Karaj (Sikh Wedding)" },
    {
      value: "2",
      label: "Akhand Path (48 hour recitation of the Guru Granth Sahib)",
    },
    {
      value: "3",
      label:
        "Kirtan & Bhog ceremony (devotional singing & prayer for religious events, birthdays, anniversaries, memorials)",
    },
    {
      value: "4",
      label:
        "Ardaas (prayer for occasions such as housewarming, birth of child, new business)",
    },
    {
      value: "5",
      label: "Sukhmani Sahib Path (prayer for seeking peace & healing)",
    },
    { value: "6", label: "Langar Hall Rental" },
    { value: "7", label: "Paath Services (specific readings)" },
    { value: "8", label: "Amrit Sanchar" },
    { value: "9", label: "Antim Ardasm (funeral services)" },
    { value: "10", label: "Naam Karan (naming ceremony)" },
  ];

  const matchedOption = options.find(
    (opt) => opt.value === selectedServiceType
  );
  console.log(timeSlotCounts, localTimeSlots, selectedServiceType, "checking");
  console.log(selectedDate, selectedTime, "asfjkbasdkjfkjasdfjk");
  return (
    <div className="row calenderTimeSlot">
      <div className="availableDate">
        <h3 className="">{matchedOption?.label}</h3>
        <h5>Select Date & Time</h5>
        <div className="calender">
          <Calendar
            onChange={onChange}
            value={
              moment(selectedDate).tz(userTimezone).isValid()
                ? moment(selectedDate).tz(userTimezone).toDate()
                : new Date()
            }
            minDate={new Date()}
            tileClassName={tileClassName} // Apply custom tile class
            tileDisabled={tileDisabled} // Disable non-available tiles
            showFixedNumberOfWeeks={true}
          />
        </div>
        <div>
          {/* <div>
            <Label>Timezone</Label>
            <Input
              type="select"
              name="TimeZone"
              value={timezone}
              onChange={handleTimezoneChange}
            >
              <option value="America/New_York, Eastern Time">
                America/New_York (Eastern Time)
              </option>
              <option value="America/Chicago, Central Time">
                America/Chicago (Central Time)
              </option>
              <option value="America/Denver, Mountain Time">
                America/Denver (Mountain Time)
              </option>
              <option value="America/Los_Angeles, Pacific Time">
                America/Los_Angeles (Pacific Time)
              </option>
              <option value="UTC, Coordinated Universal Time">
                UTC (Coordinated Universal Time)
              </option>
              <option value="Europe/London, GMT">Europe/London (GMT)</option>
              <option value="Europe/Berlin, Central European Time">
                Europe/Berlin (Central European Time)
              </option>
            </Input>
          </div> */}
        </div>
      </div>
      <div className="availableTime">
        <h4
          style={{
            marginTop: "5px",
            marginBottom: "15px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {selectedDate ? moment(selectedDate).tz(userTimezone).format("MMMM DD, YYYY") : "Select a date"}
        </h4>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            alignItems: "center",
          }}
        >
          {localTimeSlots.length > 0 ? (
            localTimeSlots.map((time, index) => {
              const availableCount =
                timeSlotCounts[selectedServiceType]?.[time];
              return (
                <Row key={index}>
                  <Col sm={12} className="p-0">
                    <button
                      className={`buttons-group ${
                        selectedTime === time ? "selected" : ""
                      }`}
                      onClick={() => handleTimeSlotSelect(time)}
                      disabled={availableCount <= 0} // Disable button if no slots are available
                    >
                      {formatTimeSlot(time)}
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "0.9rem",
                          marginTop: "5px",
                          color:
                            selectedTime === time
                              ? "white"
                              : availableCount > 0
                              ? "green"
                              : "red",
                        }}
                      >
                        {availableCount > 0
                          ? `${availableCount} slot(s) left`
                          : "No slots left"}
                      </div>
                    </button>
                  </Col>
                </Row>
              );
            })
          ) : (
            <p>No available time slots</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CalendarTimeSlotSelector;
