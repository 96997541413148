import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import LcaCards from "../LcaCards";
import {
  setActiveTab,
  setFinalProducts,
  setMonth,
} from "../../../../redux/slices/LcaSlice";
import BwvCards from "../BwvCards";

const LcaAndBwv = ({ scrollPopupVisible }) => {
  const dispatch = useDispatch();
  const {
    activeTab,
    finalProducts,
    Lca,
    bizzLca,
    bwv,
    finalBwv,
    selectedSubCategory,
    month,
  } = useSelector((state) => state.lcAds);

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10;
  const [carouselData, setCarouselData] = useState({
    row1: [],
    row2: [],
    row3: [],
  });
  const location = useLocation();
  const navigate = useNavigate();
  const currentBwv = useSelector((state) => state.lcAds.bwv); // Access latest bwv
  const [filteredProducts, setFilteredProducts] = useState([]);
  const shuffleArray = (array) => {
    let shuffled = array.slice();
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };
  const fetchData = async () => {
    const allAds = [...bizzLca, ...currentBwv]; // Merge with the latest bwv
    const row1 = [];
    const row2 = [];
    const row3 = [];
    allAds.forEach((data) => {
      if (data.bizzValue === 1) {
        row1.push(data);
      } else if (data.bizzValue === 2) {
        row2.push(data);
      } else if (data.bizzValue === 3) {
        row3.push(data);
      }
    });
    const shuffledRow1 = shuffleArray(row1);
    const shuffledRow2 = shuffleArray(row2);
    const shuffledRow3 = shuffleArray(row3);
    setCarouselData({
      row1: shuffledRow1,
      row2: shuffledRow2,
      row3: shuffledRow3,
    });
  };
  useEffect(() => {
    fetchData();
  }, [bizzLca, currentBwv]);

  const filterProductsByEventDate = (finalProducts, month) => {
    const filteredProducts = finalProducts.filter((product) => {
      let eventDetails = [];
      try {
        eventDetails = JSON.parse(product.eventDetails); // Parse the stringified eventDetails
      } catch (error) {
        console.error("Error parsing eventDetails", error);
        return false; // If parsing fails, exclude this product
      }

      // Filter out invalid or empty events
      const validEventDetails = eventDetails.filter((event) => {
        return (
          event.eventDate &&
          event.eventDate !== "" &&
          event.eventTime &&
          event.eventTime !== "" &&
          event.eventType &&
          event.eventType !== "" &&
          event.eventLocation &&
          event.eventLocation !== "" &&
          event.totalGuestCount &&
          event.totalGuestCount !== ""
        );
      });

      // If no valid eventDetails, exclude the product
      if (validEventDetails.length === 0) {
        return false;
      }

      // Check if any valid event matches the selected month
      return validEventDetails.some((event) => {
        const eventDate = new Date(event.eventDate);
        if (!isNaN(eventDate.getTime())) {
          const eventMonth =
            eventDate.getFullYear() +
            "-" +
            String(eventDate.getMonth() + 1).padStart(2, "0");
          return eventMonth === month; // Match eventMonth with the selected month
        }
        return false;
      });
    });

    return filteredProducts; // Return the filtered list of products
  };

  const handleMonthChange = (newMonth) => {
    dispatch(setMonth(newMonth));
    // If the month is empty, reset the final products
    if (!newMonth) {
      // Reset to original Lca products
      dispatch(setFinalProducts(Lca.filter((ad) => ad.engagedCouple === 1)));
      setFilteredProducts(Lca.filter((ad) => ad.engagedCouple === 1)); // Update local state
      return;
    }

    // First filter Lca where engagedCouple === 1
    const filteredByEngagedCouple = Lca.filter((ad) => ad.engagedCouple === 1);

    // Now set the filtered list as finalProducts
    dispatch(setFinalProducts(filteredByEngagedCouple));

    // Then filter those results by the selected month
    const updatedFilteredProducts = filterProductsByEventDate(
      filteredByEngagedCouple,
      newMonth
    );

    // Finally, dispatch the filtered products to the Redux store after filtering by month
    dispatch(setFinalProducts(updatedFilteredProducts));

    // Update the local filteredProducts state as well
    setFilteredProducts(updatedFilteredProducts);
  };

  const sortedProducts = [...finalProducts].sort((a, b) => {
    return b.caIdStatus - a.caIdStatus; // Descending order
  });

  const totalPagesForCustomers = Math.ceil(
    sortedProducts?.length / usersPerPage
  );
  const indexOfLastUserCustomer = currentPage * usersPerPage;
  const indexOfFirstUserCustomer = indexOfLastUserCustomer - usersPerPage;
  const currentUsers = sortedProducts?.slice(
    indexOfFirstUserCustomer,
    indexOfLastUserCustomer
  );
  const sortedBwv = [...finalBwv].sort((a, b) => {
    return b.caIdStatus - a.caIdStatus; // Descending order
  });

  // Pagination calculations for Vendors
  const totalPagesForVendors = Math.ceil(sortedBwv?.length / usersPerPage);
  const indexOfLastUserVendor = currentPage * usersPerPage;
  const indexOfFirstUserVendor = indexOfLastUserVendor - usersPerPage;
  const currentVendors = sortedBwv?.slice(
    indexOfFirstUserVendor,
    indexOfLastUserVendor
  );
  useEffect(() => {
    setCurrentPage(1);
  }, [finalProducts, finalBwv]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
      window.scrollTo(0, 0);
    }
  };

  const handleNextPage = (totalPages) => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
      window.scrollTo(0, 0);
    }
  };
  const getGuestCount = (finalProducts) => {
    // check how many products having eventDetails.totalGuestCount more than 150 by parsing product.eventDetails
    const guestCount = finalProducts.filter((product) => {
      if (product.eventDetails) {
        const parsedEventDetails = JSON.parse(product.eventDetails);
        const filterEvents = parsedEventDetails.filter((event) => {
          return event.totalGuestCount > 150;
        });
        return filterEvents.length > 0;
      }
    });
    console.log(guestCount, "guestCount");
    return guestCount.length;
  };
  return (
    <>
      {selectedSubCategory &&
        selectedSubCategory.Name === "Upcoming Wedding/Event" && (
          <>
            {activeTab === "Customers" && (
              <div className="d-flex flex-wrap justify-content-between align-items-center gap-3">
                <div className="flex-grow-1 text-center text-md-start">
                  {/* <h6
                    className="fw-bold p-1 bg-info text-white"
                    style={{
                      width: "fit-content",
                      borderRadius: "8px",
                      margin: "0 auto",
                    }}
                  >
                    {getGuestCount(finalProducts)} Products Have 150+ Guests
                  </h6> */}
                </div>
                <div
                  className="d-flex flex-grow-1 flex-md-nowrap flex-wrap align-items-center justify-content-center justify-content-md-end"
                  style={{
                    marginTop: "-15px",
                  }}
                >
                  <b className="text-nowrap me-2">Select Month & Year:</b>
                  <Input
                    type="month"
                    name="month"
                    className="form-control-sm w-auto"
                    value={month} // Bind the value to state
                    onChange={(e) => handleMonthChange(e.target.value)}
                    placeholder="Select Month"
                  />
                </div>
              </div>
            )}

            <div
              className="col-lg-6 d-flex row mt-3 view-tabs w-100"
              style={{ marginLeft: "2px" }}
            >
              <h5
                className={`col-sm-4 ${
                  activeTab === "Vendor" ? "active" : ""
                } mx-0`}
                onClick={() => {
                  dispatch(setActiveTab("Vendor"));
                  const searchParams = new URLSearchParams(location.search);
                  searchParams.set("tab", "Vendor");
                  navigate(
                    {
                      pathname: location.pathname,
                      search: searchParams.toString(),
                    },
                    { replace: true }
                  );
                  setCurrentPage(1); // Reset to first page when switching tabs
                }}
                style={{
                  textDecoration: activeTab === "Vendor" ? "underline" : "none",
                  cursor: "pointer",
                  width: "50%",
                }}
              >
                View Vendors
              </h5>
              <h5
                className={`col-sm-4 ${
                  activeTab === "Customers" ? "active " : ""
                } mx-0`}
                onClick={() => {
                  dispatch(setActiveTab("Customers"));
                  const searchParams = new URLSearchParams(location.search);
                  searchParams.set("tab", "Customers");
                  navigate(
                    {
                      pathname: location.pathname,
                      search: searchParams.toString(),
                    },
                    { replace: true }
                  );
                  setCurrentPage(1); // Reset to first page when switching tabs
                }}
                style={{
                  textDecoration:
                    activeTab === "Customers" ? "underline" : "none",
                  cursor: "pointer",
                  width: "50%",
                }}
              >
                View Customers
              </h5>
            </div>
          </>
        )}
      <div>
        {" "}
        {activeTab == "Customers" ? (
          <LcaCards
            allProducts={currentUsers}
            scrollPopupVisible={scrollPopupVisible}
            carouselData={carouselData}
          />
        ) : activeTab == "Vendor" ? (
          currentVendors && currentVendors.length > 0 ? (
            <BwvCards
              allProducts={currentVendors}
              carouselData={carouselData}
            />
          ) : (
            <div
              className="text-center py-5 text-primary"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "50px 0px",
              }}
            >
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "10px 0px",
                  fontSize: "20px",
                }}
                className="text-center py-5"
              >
                No cards to display
              </p>
            </div>
          )
        ) : null}
      </div>

      {(activeTab === "Customers" || activeTab === "Vendor") && (
        <div className="pagination pb-3 d-flex justify-content-center">
          <Button
            className="me-2"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <span className="align-self-center">
            Page {currentPage} of{" "}
            {activeTab === "Customers"
              ? totalPagesForCustomers
              : totalPagesForVendors}
          </span>
          <Button
            className="ms-2"
            onClick={() =>
              handleNextPage(
                activeTab === "Customers"
                  ? totalPagesForCustomers
                  : totalPagesForVendors
              )
            }
            disabled={
              currentPage ===
              (activeTab === "Customers"
                ? totalPagesForCustomers
                : totalPagesForVendors)
            }
          >
            Next
          </Button>
        </div>
      )}
    </>
  );
};

export default LcaAndBwv;
