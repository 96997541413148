import React, { useEffect, useState } from "react";
import { Input, Label, ListGroup, ListGroupItem } from "reactstrap";
import { fetchAllUsers } from "../../components/Header/Data";
import { useDispatch, useSelector } from "react-redux";
import { setSearchTerm } from "../../redux/slices/PeopleInComSlice";
import { FaSearch, FaShoppingCart } from "react-icons/fa";

const SearchByUsers = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [userSelected, setUserSelected] = useState(null);
  const dispatch = useDispatch();
  const { allUsers, searchTerm } = useSelector((state) => state.peopleInCom);
  const handleInputChange = (event) => {
    const term = event.target.value.toLowerCase();
    dispatch(setSearchTerm(term));
  };

  const handleSelect = (user) => {
    const name =
      user.FirstName || user.LastName
        ? `${user.FirstName || ""} ${user.LastName || ""}`.trim()
        : user.UserName;
    dispatch(setSearchTerm(name));
    setSearchResults([]); // Clear search results after selection
    setUserSelected(user);
  };

  const handleClearInput = () => {
    dispatch(setSearchTerm(""));
    setSearchResults([]); // Clear search results
    setUserSelected(null); // Clear the selected user
  };

  // Check if no results match the current search term and it's not a selected user
  const noResultsFound = searchTerm && searchResults.length === 0;

  return (
    <div>
      <Label>Search for People & Businesses</Label>
      <div className="input-container">
        <FaSearch
          style={{
            position: "absolute",
            left: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            color: "#aaa",
          }}
        />
        <Input
          type="text"
          value={searchTerm}
          name="searchTerm"
          id="searchTerm"
          onChange={handleInputChange}
          placeholder="Search by Firstname, Lastname ..."
          className="search-input"
          autoComplete="off"
          style={{ paddingLeft: "30px" }}
          spellCheck="false"
          tabIndex={0}
        />
        {searchTerm && (
          <span className="clear-button" onClick={handleClearInput}>
            &#x2715;
          </span>
        )}
      </div>
      {/* {searchTerm && searchResults.length > 0 && (
        <ListGroup className="search-results-container">
          {searchResults.map((user, index) => (
            <ListGroupItem
              key={index}
              className="list-group-item"
              onClick={() => handleSelect(user)}
            >
              <small>
                {user.FirstName || user.LastName
                  ? `${user.FirstName || ""} ${user.LastName || ""}`
                  : user.UserName}
                <br />
              </small>
            </ListGroupItem>
          ))}
        </ListGroup>
      )} */}
      {/* {!userSelected && noResultsFound && <p>No users found</p>} */}
    </div>
  );
};

export default SearchByUsers;
