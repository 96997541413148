import React, { useRef, useEffect } from "react";
import TvProductCard from "./TvProductCard";
import { useSelector } from "react-redux";
import TvScreenItems from "./TvScreenItems";

const TvScreenProducts = ({ data }) => {
  const carouselRef = useRef(null);
  const { guruSubCat } = useSelector((state) => state.lcAds);

  // Filter by category function
  const filterByCategory = (data, category) => {
    return data.filter((product) =>
      product.subCategoryName
        ?.trim()
        .toLowerCase()
        .includes(category?.Name.trim().toLowerCase())
    );
  };

  const maxProducts = 40;

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const card = entry.target;
          // Add zoom effect only if the item is entering
          if (entry.isIntersecting) {
            card.classList.add("zoom");
            setTimeout(() => {
              card.classList.remove("zoom");
            }, 3000); // Adjust duration based on your zoom effect duration
          }
        });
      },
      {
        threshold: 0.5, // Adjust based on how much of the item should be visible to trigger the zoom
      }
    );
    // Observe all tv-card elements inside the carousel
    const items = document.querySelectorAll(".tv-card");
    items.forEach((item) => observer.observe(item));
    return () => {
      items.forEach((item) => observer.unobserve(item));
    };
  }, [data]); // Add data to dependency array to re-run effect on data change
  // Sort guruSubCat to place "Matrimonial/Dating" second
  const sortedSubCat = [...guruSubCat].sort((a, b) => {
    if (a.Name === "Matrimony") return 1; // Move "Matrimonial/Dating" down
    if (b.Name === "Matrimony") return -1; // Move "Matrimonial/Dating" down
    return 0; // Keep other categories in their original order
  });
  const matrimonialIndex = sortedSubCat.findIndex(
    (cat) => cat.Name === "Matrimony"
  );
  if (matrimonialIndex > -1) {
    const matrimonialCategory = sortedSubCat.splice(matrimonialIndex, 1)[0]; // Remove it from its current position
    sortedSubCat.splice(1, 0, matrimonialCategory); // Insert it at the second position
  }
  return (
    <div>
      <div className="text-center">
        {data && data.length > 0 ? (
          <>
            {" "}
            {sortedSubCat &&
              sortedSubCat.map((category, index) => {
                const filteredProducts = filterByCategory(data, category);
                if (filteredProducts.length === 0) {
                  return null; // Skip rendering if no products for this category
                }
                let itemsToShow = [];
                while (itemsToShow.length < maxProducts) {
                  itemsToShow = itemsToShow.concat(
                    filteredProducts.slice(0, maxProducts - itemsToShow.length)
                  );
                }
                return (
                  <React.Fragment key={index}>
                    <div className="tv-screen-products-container">
                      <div className="static-category-card">
                        <div className="category-title">{category.Name}</div>
                      </div>
                      <div className="tv-screen-carousel">
                        <div className="tv-screen-carousel-inner">
                          {itemsToShow.map((item, idx) => (
                            <div
                              key={idx} // Ensure a unique key for each item
                              className="tv-card" // Keep the same class for all cards
                              data-toggle="tooltip"
                              data-placement="top"
                              title={
                                item && item.itemName
                                  ? item.itemName
                                  : item.subCategoryName
                                  ? item.subCategoryName
                                  : item.categoryName
                                  ? item.categoryName
                                  : ""
                              }
                            >
                              <TvProductCard item={item} />
                            </div>
                          ))}
                          {/* Duplicate items to create a seamless effect */}
                          {itemsToShow.map((item, idx) => (
                            <div
                              key={`duplicate-${idx}`} // Unique key for duplicates
                              className={`tv-card`} // Keep the same class for all duplicate cards
                              data-toggle="tooltip"
                              data-placement="top"
                              title={
                                item && item.itemName
                                  ? item.itemName
                                  : item.subCategoryName
                                  ? item.subCategoryName
                                  : item.categoryName
                                  ? item.categoryName
                                  : ""
                              }
                            >
                              <TvProductCard item={item} />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    {/* Add the span after every 3 categories */}
                    {(index + 1) % 4 === 0 && (
                      <div className="flashing-text">
                        <b>
                          <span className="px-2">
                            {" "}
                            CALL (800)245-3610 & Dial Listing number
                          </span>{" "}
                          ||
                          <span className="px-2">
                            ਕਾਲ ਕਰੋ (800)245-3610 ਅਤੇ ਫਿਰ ਲਿਸਟਿੰਗ ਨੰਬਰ ਡਾਇਲ ਕਰੋ।
                          </span>
                        </b>
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
          </>
        ) : (
          <span className="m-5 fw-bold">No products found</span>
        )}
      </div>
      <hr />
      <h6 className="mt-3 fw-bold text-center fs-5">Items For Sale</h6>
      <div>
        <TvScreenItems />
      </div>
    </div>
  );
};

export default TvScreenProducts;
