import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  resetFormState,
  setCreatedBy,
  setCreatedEmail,
  setEmail,
  setEngagedCouple,
  setUpdatedBy,
} from "../../../redux/slices/RequestFormSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { updateRequest, postRequest } from "../../../components/Header/Data";
import { toast } from "react-toastify";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import CreateNeed from "./CreateNeed";
import UpcomingEvent from "./UpcomingEvent";
import DonateModal from "../../../components/Header/modals/DonateModal";
import {
  setDonationFrom,
  setDonationStatus,
} from "../../../redux/slices/DonationSlice";

const RequestNeed = ({
  categories,
  subCategories1,
  editMode,
  needId,
  selectedCat,
  setSelectedCat,
}) => {
  const dispatch = useDispatch();
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [subCategories, setSubCategories] = useState([]);
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const formData = useSelector((state) => state.requestForm);
  const location = useLocation();
  const tab = new URLSearchParams(location.search).get("tab");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalClosed, setModalClosed] = useState(false);
  const { donationStatus } = useSelector((state) => state.gurdwaraDonation);
  const [amount, setAmount] = useState(50);
  const [donate, setDonate] = useState("");
  const [openPaypal, setOpenPaypal] = useState(false);
  const [showAmountInput, setShowAmountInput] = useState(false); // State to control which modal body to show
  // console.log(formData.upc);
  useEffect(() => {
    if (islogin) {
      if (!needId) {
        dispatch(setCreatedBy(user.UserID));
        dispatch(setEmail(user.Email));
      } else {
        dispatch(setUpdatedBy(user.UserID));
      }
    }
  }, [editMode, user]);
  // console.log(formData, "formData");
  const handleModalClose = () => {
    setModalClosed(true); // Track that the modal has been closed
    setModalIsOpen(false); // Close the modal
  };
  const handleFormSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    if (isLoading) return;
    if (isAdmin && !formData?.Email) {
      toast.error("Please Enter Email ID");
      return;
    } else if (!formData?.FullName) {
      toast.error("Please Enter Full Name");
      return;
    } else if (
      (isAdmin || formData?.engagedCouple === 1) &&
      !formData?.categoryId
    ) {
      toast.error("Please Select Category");
      return;
    } else if (
      isAdmin &&
      subCategories?.length !== 0 &&
      !formData?.subCategoryId
    ) {
      toast.error("Please Select Sub Category");
      return;
    } else if (isAdmin && !formData?.needRadius) {
      toast.error("Please Select Radius");
      return;
    } else if (isAdmin && formData?.dialExtension === "") {
      toast.error("Please Enter Dial Extension");
      return;
    } else if (!formData?.Title) {
      toast.error("Please Enter Title");
      return;
    } else if (!formData?.Zipcode || formData?.Zipcode?.length < 5) {
      toast.error("Enter 5 digits Zip Code");
      return;
    } else if (!formData?.Phone) {
      toast.error("Enter Phone Number");
      return;
    } else if (formData?.Phone?.length < 10 && formData?.Phone?.length > 0) {
      toast.error("Enter valid Phone number");
      return;
    } else if (!formData?.alternativePhoneOne) {
      toast.error('"Mobile Number (Family Member)" is a required field.');
      return;
    } else if (
      formData?.alternativePhoneOne?.length < 10 &&
      formData?.alternativePhoneOne?.length > 0
    ) {
      toast.error("Enter valid Phone number");
      return;
    } else if (
      formData?.alternativePhoneTwo?.length < 10 &&
      formData?.alternativePhoneTwo?.length > 0
    ) {
      toast.error("Enter valid Phone number");
      return;
    } else if (!formData?.Terms) {
      toast.error("Accept the Terms & Conditions");
      return;
    }
    // console.log(
    //   Object.keys(editMode).length > 0 &&
    //     formData?.Gurudwara !== null &&
    //     formData?.engagedCouple !== 1 &&
    //     !modalClosed &&
    //     donationStatus !== "COMPLETED",
    //   "DonateModal"
    // );
    if (
      (Object.keys(editMode).length === 0 &&
        formData?.engagedCouple === 0 &&
        formData?.Gurudwara !== "" &&
        !modalClosed &&
        !isAdmin &&
        donationStatus !== "COMPLETED") ||
      (Object.keys(editMode).length > 0 &&
        formData?.Gurudwara !== "" &&
        formData?.engagedCouple !== 1 &&
        !modalClosed &&
        !isAdmin &&
        donationStatus !== "COMPLETED")
    ) {
      // console.log("entered");
      setModalIsOpen(true);
      dispatch(setDonationFrom("GURDWARA_NEED"));
      return;
    } else {
      setModalIsOpen(false);
    }
    const updatedFormData = { ...formData };

    if (
      updatedFormData.availableFrom &&
      typeof updatedFormData.availableFrom !== "string"
    ) {
      updatedFormData.availableFrom = JSON.stringify(
        updatedFormData.availableFrom
      );
    }
    setIsLoading(true);
    if (Object.keys(editMode).length > 0) {
      await updateRequest(editMode.Id, updatedFormData);
      toast.success("Updated Request sent to Admin for Approval");
      {
        !islogin
          ? navigate("/Localcommunityads")
          : navigate("/dashboardpage/needs-requests");
      }
      window.scroll(0, 0);
    } else {
      await postRequest(updatedFormData);
      toast.success("New Request sent to Admin for Approval");
      {
        !islogin
          ? navigate("/Localcommunityads")
          : navigate("/dashboardpage/needs-requests");
      }
      window.scroll(0, 0);
    }
    dispatch(resetFormState());
    // toast.success("Request sent to Admin for Approval");
    setIsLoading(false);
  };
  useEffect(() => {
    // console.log(donationStatus, "donationStatus");
    if (donationStatus === "COMPLETED") {
      handleFormSubmit();
      dispatch(setDonationStatus(""));
      dispatch(setDonationFrom(""));
    }
  }, [donationStatus]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      (Object.keys(editMode).length === 0 &&
        formData?.engagedCouple === 0 &&
        formData?.Gurudwara !== "") ||
      (Object.keys(editMode).length > 0 &&
        formData?.Gurudwara !== "" &&
        formData?.engagedCouple !== 1)
    ) {
      setModalIsOpen(true);
      return;
    } else {
      handleFormSubmit(e);
    }
  };
  // console.log(modalIsOpen, "modalIsOpen");
  const handlePayment = async () => {
    if (!amount) {
      toast.error("Please Enter Amount");
      return;
    }
    let formData = {
      customerId: user?.UserID,
      totalAmount: amount,
      orderStatus: "PENDING",
    };
    //const order = await postOrder(formData);
    //dispatch(setOrder(order));
    // toast.success("Order Created");
    setOpenPaypal(true);
  };

  return (
    <div>
      <div className="mt-4 text-center">
        <div
          className="wc_post-add-btn d-inline-block"
          style={{
            backgroundColor: "#DB3545",
            padding: "10px",
            borderRadius: "10px",
            color: "#fff",
            gap: "7px",
          }}
        >
          <input
            type="checkbox"
            className="custom-checkbox"
            checked={formData?.engagedCouple == 1}
            onChange={(e) => {
              dispatch(setEngagedCouple(e.target.checked ? 1 : 0));
            }}
            name="engagedCouple"
          />
          <label className="ml-2">I have Upcoming Wedding/Event</label>
        </div>
      </div>
      {(!islogin || (islogin && isAdmin)) && (
        <Col className="request-form p-4 mt-3">
          {" "}
          <Row>
            <FormGroup>
              <Label>Enter Email ID</Label>{" "}
              {islogin && isAdmin && <b className="text-danger">*</b>}
              <Input
                type="email"
                name="Email"
                placeholder="Enter Email ID"
                value={formData.Email}
                onChange={(e) => dispatch(setEmail(e.target.value))}
              />
            </FormGroup>
          </Row>
        </Col>
      )}
      {formData.engagedCouple === 0 && (
        <CreateNeed
          categories={categories}
          subCategories1={subCategories1}
          subCategories={subCategories}
          setSubCategories={setSubCategories}
          editMode={editMode}
          handleFormSubmit={handleFormSubmit}
          selectedCat={selectedCat}
          setSelectedCat={setSelectedCat}
          isLoading={isLoading}
        />
      )}
      {formData.engagedCouple === 1 && (
        <UpcomingEvent
          categories={categories}
          subCategories1={subCategories1}
          subCategories={subCategories}
          setSubCategories={setSubCategories}
          editMode={editMode}
          handleFormSubmit={handleFormSubmit}
          selectedCat={selectedCat}
          setSelectedCat={setSelectedCat}
          isLoading={isLoading}
        />
      )}
      <DonateModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        handleFormSubmit={handleFormSubmit}
        onClose={handleModalClose} // Pass close handler to the modal
      />
    </div>
  );
};

export default RequestNeed;
