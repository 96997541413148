import React, { useEffect, useState } from "react";
import ChatHeader from "./ChatHeader";
import { useDispatch, useSelector } from "react-redux";
import Conversation from "./Conversation";
import MsgInputBox from "./MsgInputBox";
import { toast } from "react-toastify";
import { fetchMyServices, sendMessage } from "../../../components/Header/Data";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useSocket } from "../../../contexts/SocketContext";
import { MessageSeen } from "../../../components/Header/Data2";
import { setSelectedConversation } from "../../../redux/slices/MsgSlice";

const ChatCommunication = ({
  isMobileView,
  onBack,
  toggleDrawer,
  fetchMsgs,
  fetchAllMsg,
  fetchData,
  isChatModal,
  isAds,
}) => {
  const dispatch = useDispatch();
  const selectedConversation = useSelector(
    (state) => state.messages.selectedConvo
  );
  const { sendNewMessage, socket } = useSocket();
  const { loadingConvo, messages, newMessage, selectedFile } = useSelector(
    (state) => state.messages
  );
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [openModal, setOpenModal] = useState(false);
  const [activeServices, setActiveServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const logUser = localStorage.getItem("USER_ROLE");
  useEffect(() => {
    if (openModal) {
      fetchActiveServices();
    }
  }, [openModal]);
  useEffect(() => {
    const handleReceivedMessage = (message) => {
      fetchMsgs();
    };
    const handleNewMessage = async (message) => {
      if (message.parentId === selectedConversation.Id) {
        if (message?.customerId === user.UserID) {
          let request = { customerId: user.UserID };
          try {
            await MessageSeen(message.parentId, request);
            setTimeout(() => {
              fetchMsgs();
            }, 1000);
          } catch (error) {
            console.error(
              "Error updating message status or fetching messages:",
              error
            );
          }
        } else {
          fetchMsgs();
        }
      }
    };

    socket.on("new-message", handleNewMessage);
    socket.on("receive-message", handleReceivedMessage);
    return () => {
      socket.off("new-message", handleNewMessage);
      socket.off("receive-message", handleReceivedMessage);
    };
  }, [socket]);
  if (loadingConvo) {
    return (
      <div className="text-center" style={{ fontSize: "smaller" }}>
        Loading...
      </div>
    );
  }

  const handleProfileShare = async () => {
    setOpenModal(false);
    const messageForm = {
      customerId:
        selectedConversation?.customerId === user?.UserID
          ? selectedConversation?.createdBy
          : selectedConversation?.customerId,
      postId: selectedConversation?.postId,
      postType: 1, // message 1 or requestQuote 2 or offer my service 3
      parentId:
        selectedConversation?.parentId === 0
          ? selectedConversation?.Id
          : selectedConversation?.parentId,
      // Description: `My Business Profile, ${
      //   selectedConversation?.createdBy === user?.UserID &&
      //   selectedConversation?.createdBy
      // }`,
      Description: `My Business Profile, ${user?.UserID}`,
      messageStatus: 8,
      createdBy: user?.UserID,
      roleId: user?.RoleId,
      ownerId: selectedConversation?.ownerId,
      userName: user?.UserName,
      Email: user?.Email,
      serviceNeed: "need",
      needName: selectedConversation?.postName,
    };
    console.log(messageForm, "messageForm");
    // console.log(selectedConversation, "messageForm");
    const response = await sendMessage(messageForm);
    if (response.Data) {
      let data = response.Data;
      if (data.parentId == 0) {
        dispatch(setSelectedConversation(data));
      } else {
        fetchMsgs();
      }
      console.log(response, "response");
      // dispatch(setMessageText(""));
      sendNewMessage(response.Data);
      toast.success("Business Profile PageLink Sent Successfully");
    }
    setTimeout(() => {
      fetchMsgs();
    }, 1000);
    setSelectedService(null);
    // fetchMsgs();
  };

  const fetchActiveServices = async () => {
    const reqData = {
      roleId: 2,
      createdBy: user.UserID,
    };
    const services = await fetchMyServices(reqData);
    const filterActive = services.filter(
      (service) => !["0", "1"].includes(service.isactive)
    );
    setActiveServices(filterActive);
  };
  return (
    <div className="chat-communication">
      <ChatHeader
        isMobileView={isMobileView}
        onBack={onBack}
        toggleDrawer={toggleDrawer}
        fetchMsgs={fetchMsgs}
        fetchData={fetchData}
        isChatModal={isChatModal}
      />
      <div>
        <div
          className={`${
            selectedConversation && selectedConversation.postType === 3
              ? "visible"
              : "invisible"
          }`}
        >
          {/* {selectedConversation.postType === 3 && ( */}
          <div
            className="text-center d-flex flex-column align-items-center"
            style={{ fontSize: "smaller" }}
          >
            <span className="text-white px-2 py-1 mb-1 rounded bg-secondary">
              Starting Offer $ {selectedConversation?.Budget}
            </span>{" "}
            <span className="rounded px-2 py-1 bg-success text-white">
              Negotiated $ {selectedConversation?.finalBudget}
            </span>
          </div>
          {/* )} */}
        </div>
        <div className="upwork_message">
          {selectedFile && (
            <div className="text-center" style={{ fontSize: "smaller" }}>
              Sending Attachment...
            </div>
          )}
          {!loadingConvo &&
            messages &&
            messages.length > 0 &&
            messages.map((msg, index) => (
              <div
                className={`${
                  messages.length === 1
                    ? "single-message"
                    : messages.length === 2 && "two-messages"
                }`}
                key={msg.Id}
              >
                <Conversation key={msg.Id} message={msg} />
              </div>
            ))}
        </div>
        <MsgInputBox
          fetchMsgs={fetchMsgs}
          fetchAllMsg={fetchAllMsg}
          isChatModal={isChatModal}
          isAds={isAds}
        />
        {logUser === "provider" && (
          <div>
            <p
              className="text-center text-primary fst-italic text-decoration-underline"
              onClick={handleProfileShare}
              // onClick={() => setOpenModal(true)}
              style={{ cursor: "pointer" }}
            >
              Send link to visit my Profile Page
            </p>
          </div>
        )}
      </div>
      <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
        <ModalBody>
          <Label>Choose a Service</Label>
          <Input
            type="select"
            name="activeServices"
            //placeholder="Enter Zip code "
            onChange={(e) => setSelectedService(e.target.value)}
            value={selectedService}
          >
            <option value="" selected>
              Select a Service
            </option>
            {activeServices.map((service) => (
              <option key={service.Id} value={service.Id}>
                {service.listName}
              </option>
            ))}
          </Input>
          <div className="text-center mt-3">
            <Button onClick={handleProfileShare}>Send Link</Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ChatCommunication;
