import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
// import SearchByLocation from "./SearchByLocation";
import Helmet from "../../components/Helmet/Helmet";
import SearchByUsers from "./SearchByUsers";
import SearchByLocation from "./SearchByLocation";
import "./PeopleInMyCommunity.css";
import MoreFilters from "./MoreFilters";
import { fetchAllUsers } from "../../components/Header/Data";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllUsers,
  setFinalUsers,
  setIsLoading,
} from "../../redux/slices/PeopleInComSlice";
import UsersContainer from "./UsersContainer";
import SearchByType from "./SearchByType";

const PeopleInMyCom = () => {
  const dispatch = useDispatch();
  const {
    allUsers,
    searchTerm,
    searchLocation,
    searchType,
    finalUsers,
    isLoading,
  } = useSelector((state) => state.peopleInCom);
  const fetchUsers = async () => {
    dispatch(setIsLoading(true));
    const users = await fetchAllUsers();
    dispatch(setAllUsers(users));
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    fetchUsers();
  }, []);
  React.useEffect(() => {
    let initialdata = allUsers;
    const apply = () => {
      if (searchTerm) {
        initialdata = allUsers.filter(
          (user) =>
            (user.FirstName &&
              user.FirstName.toLowerCase().includes(
                searchTerm.toLowerCase()
              )) ||
            (user.LastName &&
              user.LastName.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (user.UserName &&
              user.UserName.toLowerCase().includes(searchTerm.toLowerCase()))
        );
      }
      if (searchLocation) {
        initialdata = initialdata.filter(
          (user) =>
            user.Location &&
            user.Location.toLowerCase().includes(searchLocation.toLowerCase())
        );
      }
      // if (searchType) {
      //   initialdata = initialdata.filter(
      //     (user) => user.UserType === searchType
      //   );
      // }
    };
    apply();
    dispatch(setFinalUsers(initialdata));
  }, [allUsers, searchTerm, searchLocation, searchType]);
  return (
    <Helmet title="my-community">
      <div className="wc-people-community">
        <Row className="mb-2">
          <Col lg={3} md={3} xl={3} sm={12} xs={12}>
            <SearchByUsers />
          </Col>
          <Col lg={3} md={3} xl={3} sm={12} xs={12}>
            <SearchByLocation />
          </Col>
          <Col lg={3} md={3} xl={3} sm={12} xs={12}>
            <SearchByType />
          </Col>
          <Col lg={3} md={3} xl={3} sm={12} xs={12}>
            <MoreFilters />
          </Col>
        </Row>
        <div>
          <UsersContainer />
        </div>
      </div>
    </Helmet>
  );
};

export default PeopleInMyCom;
