import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  myCartItems: [],
};

const ItemsDataSlice = createSlice({
  name: "ItemsData",
  initialState,
  reducers: {
    setMyCartItems: (state, action) => {
      state.myCartItems = action.payload;
    },
  },
});

export const { setMyCartItems } = ItemsDataSlice.actions;

export default ItemsDataSlice.reducer;
