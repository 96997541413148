import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { CiMenuKebab } from "react-icons/ci";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { FadeLoader } from "react-spinners";
import UpdateUserModal from "./UpdateUserModal";
import { useSelector } from "react-redux";
import { DeleteUser } from "../../components/Header/Data2";
import { toast } from "react-toastify";
import GitHubForkRibbon from "react-github-fork-ribbon";
import UserPlaceHolder from "../../assets/images/user-profile-icon.jpg";
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Typography,
  Grid,
} from "@mui/material";
import { Edit, Delete, PeopleAltOutlined } from "@mui/icons-material";
import CustomerGottonModal from "./CustomerGottonModal";

const UserCard = ({ allUsers, fetchUsers }) => {
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [openMenuId, setOpenMenuId] = useState(null);
  const usersPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const [openUpdateUserModal, setOpenUpdateUserModal] = useState(false);
  const [userToUpdate, setUserToUpdate] = useState(null);
  const [openGottonModal, setOpenGottenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const totalPages = Math.ceil(allUsers.length / usersPerPage);

  if (!allUsers || allUsers.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "200px",
        }}
      >
        <FadeLoader color="#36d7b7" />
      </div>
    );
  }

  const toggleMenu = (adId) => {
    setOpenMenuId(openMenuId === adId ? null : adId);
  };

  const onDelete = async (deleteUser) => {
    const reqData = {
      DeletedBy: user.UserID,
    };
    await DeleteUser(deleteUser.Id, reqData);
    toast.info(`User Deleted`);
    setOpenMenuId(null);
    fetchUsers();
  };

  const onEdit = (user) => {
    setUserToUpdate(user);
    setOpenUpdateUserModal(true);
  };
  const onCustomersGotten = (user) => {
    setSelectedUser(user);
    setOpenGottenModal(true);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
    }
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = allUsers.slice(indexOfFirstUser, indexOfLastUser);
  const truncateTitle = (title) => {
    return title.length > 15 ? title.substring(0, 15) + "..." : title;
  };
  return (
    <div className="user-manage-Container p-3">
      <Grid container spacing={3} className="user-manage-grid">
        {currentUsers.map((user) => (
          <Grid item xs={12} sm={6} md={4} key={user.Id}>
            <Card
              sx={{
                maxWidth: 345,
                position: "relative",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
              }}
            >
              {user?.caIdStatus === 1 && (
                <GitHubForkRibbon color="green" position="left">
                  Verified & Trusted
                </GitHubForkRibbon>
              )}
              <CardHeader
                avatar={
                  <Avatar
                    src={
                      user.Photo !== "" && user.Photo !== null
                        ? user?.Photo
                        : UserPlaceHolder
                    }
                    alt={user.FirstName}
                  />
                }
                action={
                  <Dropdown
                    isOpen={openMenuId === user.Id}
                    toggle={() => toggleMenu(user.Id)}
                  >
                    <DropdownToggle tag="div">
                      <IconButton>
                        <CiMenuKebab size={20} />
                      </IconButton>
                    </DropdownToggle>
                    <DropdownMenu end>
                      <DropdownItem onClick={() => onEdit(user)}>
                        <Edit fontSize="small" /> Edit
                      </DropdownItem>
                      <DropdownItem onClick={() => onDelete(user)}>
                        <Delete fontSize="small" /> Delete
                      </DropdownItem>
                      <DropdownItem onClick={() => onCustomersGotten(user)}>
                        <PeopleAltOutlined fontSize="small" /> Customers Gotten
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                }
                title={truncateTitle(`${user.FirstName} ${user.LastName}`)}
                subheader={`Role: ${
                  user.Role === 1
                    ? "Admin"
                    : user.Role === 2
                    ? "User "
                    : "Unknown"
                }`}
              />
              <CardMedia
                component="img"
                height="140"
                image={
                  user.Photo !== "" && user.Photo !== null
                    ? user?.Photo
                    : UserPlaceHolder
                }
                alt={user.FirstName}
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  Email: {user.Email}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Mobile No: {user.MobileNo}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Bio: {user.BioData}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  ID: {user.Id}
                </Typography>
              </CardContent>
              {/* <CardActions disableSpacing>
                <Button
                  color="primary"
                  onClick={() => onEdit(user)}
                  className="me-2"
                >
                  Edit
                </Button>
                <Button color="danger" onClick={() => onDelete(user)}>
                  Delete
                </Button>
              </CardActions> */}
            </Card>
          </Grid>
        ))}
      </Grid>
      <UpdateUserModal
        isOpen={openUpdateUserModal}
        closeModal={() => setOpenUpdateUserModal(false)}
        userInfo={userToUpdate}
      />
      <CustomerGottonModal
        open={openGottonModal}
        close={() => setOpenGottenModal(false)}
        user={selectedUser}
      />
      <div className="pagination pb-5 d-flex justify-content-center">
        <Button
          className="me-2"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <span className="align-self-center">
          Page {currentPage} of {totalPages}
        </span>
        <Button
          className="ms-2"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default UserCard;
