// Sidebar.js
import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import "./DashboardPages/DashboardPages.css";
import { useSelector } from "react-redux";
import { Badge } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
// import { useNavigate, useLocation } from "react-router-dom";
const Sidebar = ({ onItemClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(null);
  const logUser = localStorage.getItem("USER_ROLE");
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const id = user?.Gurudwara;
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  const { pendingTemps } = useSelector((state) => state.templates);
  const sidebarItems = [
    { id: 1, title: "Dashboard", link: "/dashboardpage" },
    {
      id: 2,
      title: "My Services for Sale",
      link: "/dashboardpage/my-services",
    },
    {
      id: 3,
      title: "Flash Deals",
      link: "/dashboardpage/flash-Deals",
    },
    { id: 4, title: "Calendar", link: "/dashboardpage/calendar" },
    {
      id: 5,
      title: "My Needs/Requests",
      link: "/dashboardpage/needs-requests",
    },
    { id: 6, title: "Orders", link: "/dashboardpage/orders" },
    // { id: 7, title: "Inbox", link: "/dashboardpage/inbox/old-inbox" },
    { id: 7, title: "Inbox", link: "/dashboardpage/inbox" },
    { id: 8, title: "Settings", link: "/dashboardpage/settings" },
    { id: 9, title: "Sponsored Ads", link: "/dashboardpage/sponsored-ads" },

    {
      id: 14,
      title: "Items For Sale",
      link: "/dashboardpage/my-items",
    },
    ...(logUser === "admin"
      ? [
          {
            id: 11,
            title: "User Management",
            link: "/admin/usermanagement",
          },
          {
            id: 12,
            title: "Category Management",
            link: "/admin/categorymanagement",
          },
          {
            id: 13,
            title: "Category Questions",
            link: "/admin/questions",
          },
          {
            id: 16,
            title: "Gurdwara Management",
            link: "/dashboardpage/gurdwaramanagement",
          },
        ]
      : []),
    ...(logUser === "provider"
      ? [
          {
            id: 10,
            title: "My Favorites",
            link: "/dashboardpage/myFavorites",
          },
        ]
      : []),
    {
      id: 15,
      title: "Notify List",
      link: "/dashboardpage/notifyList",
    },
    ...(!isAdmin && id !== 0
      ? [
          {
            id: 16,
            title: "Gurdwara Management",
            link: "/dashboardpage/gurdwaramanagement",
          },
        ]
      : []),
    {
      id: 17,
      title: "Message Templates",
      link: "/dashboardpage/message-templates",
    },
   
  ];

  const handleItemClick = (item) => {
    setSelectedItem(item);
    navigate(item.link);
  };
  return (
    <div className="side-bar p-3 border-right border-1px-solid-black">
      {sidebarItems.map((item) => (
        <div
          key={item.id}
          className={`sidebar-item py-3 ps-2 ${
            (location.pathname === "/dashboardpage" &&
              item.link === "/dashboardpage") ||
            (location.pathname === item.link &&
              item.link !== "/dashboardpage") ||
            (location.pathname.startsWith(item.link + "/") &&
              item.link !== "/dashboardpage")
              ? "selected"
              : ""
          } `}
          onClick={() => handleItemClick(item)}
        >
          {item.title}
          {isAdmin &&
            item.title === "Message Templates" &&
            pendingTemps?.length > 0 && (
              <Badge badgeContent={pendingTemps?.length} color="error">
                .
              </Badge>
            )}
        </div>
      ))}
    </div>
  );
};

export default Sidebar;
