import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup, Input } from "reactstrap";
import { FaPlusCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import uploadToS3 from "../../../utils/awsS3Upload";
import { setUploadedImages } from "../../../redux/slices/ServiceInfo";

const ServiceImages = () => {
  const dispatch = useDispatch();
  const serviceInfo = useSelector((state) => state.serviceInfo);
  const [highlightedImages, setHighlightedImages] = useState(
    Array(7).fill(null)
  );

  const handleImageChange = async (index, event) => {
    const files = event.target.files;
    let folder = "service/";
    if (files.length > 0) {
      const imageFile = files[0];
      // const reader = new FileReader();
      // reader.onload = (e) => {
      const imageUrl = await uploadToS3(imageFile, folder);
      console.log(imageUrl, "imageUrl");
      const newImages = [...highlightedImages];
      newImages[index] = imageUrl; // Base64 data URI
      setHighlightedImages(newImages);
      dispatch(setUploadedImages(JSON.stringify(newImages.filter(Boolean))));
      // };
      // reader.readAsDataURL(files[0]);
    }
  };
  console.log(serviceInfo.uploadedImages, "serviceInfo.uploadedImages");
  console.log(highlightedImages, "highlightedImages");
  useEffect(() => {
    const jsonPhoto =
      serviceInfo?.uploadedImages &&
      typeof serviceInfo?.uploadedImages === "string" &&
      JSON.parse(serviceInfo?.uploadedImages);
    if (jsonPhoto.length > 0) {
      const imagesToAdd = Array(7 - jsonPhoto.length).fill(null);
      setHighlightedImages(jsonPhoto.concat(imagesToAdd));
    } else {
      setHighlightedImages(Array(7).fill(null));
    }
  }, [serviceInfo.uploadedImages]);

  const handleDragStart = (index, event) => {
    event.dataTransfer.setData("index", index.toString());
  };
  const handleDrop = (index, event) => {
    event.preventDefault();
    const dragIndex = parseInt(event.dataTransfer.getData("index"));
    if (
      dragIndex === index ||
      dragIndex < 0 ||
      dragIndex >= highlightedImages.length
    ) {
      return; // No need to update if dropped in the same place or invalid index
    }
    const newImages = [...highlightedImages];
    const [draggedImage] = newImages.splice(dragIndex, 1); // Remove dragged image
    newImages.splice(index, 0, draggedImage); // Insert dragged image at new index
    setHighlightedImages(newImages);
    dispatch(setUploadedImages(JSON.stringify(newImages.filter(Boolean))));
  };

  return (
    <>
      <Row className="mb-5">
        <h6>
          Highlighted Sample Work <span style={{ color: "red" }}>*</span>
        </h6>
        <span style={{ fontSize: "small", padding: "10px" }}>
          Add images of your listing. The first one will be the Cover image and
          will be displayed in the Browse page. You can reorder these after
          uploading.
        </span>
        {highlightedImages.map((image, index) => (
          <Col xs={4} sm={4} md={3} lg={2} xxs={6} className="image-input" key={index}>
            <div className="mb-3">
              <FormGroup style={{ padding: "10px 0px" }}>
                <Input
                  type="file"
                  id={`imageUpload${index + 1}`}
                  onChange={(e) => handleImageChange(index, e)}
                  style={{ display: "none" }}
                  name="images"
                />
              </FormGroup>
              <div
                className="image-box"
                onClick={() =>
                  document.getElementById(`imageUpload${index + 1}`).click()
                }
                onDragStart={(e) => handleDragStart(index, e)}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => handleDrop(index, e)}
                draggable
              >
                {image && (
                  <>
                    <img
                      src={image}
                      alt={`Highlighted Image ${index + 1}`}
                      className="uploaded-image image-fluid rounded"
                      style={{ width: "80px", height: "80px" }}
                    />
                  </>
                )}
                {!image && <FaPlusCircle className="upload-icon" />}
              </div>
              {index === 0 && (
                <p className="text-primary text-center mt-2">Cover Image</p>
              )}
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default ServiceImages;
