import { Box, Button, FormGroup, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Modal.css";
import { useSelector } from "react-redux";
import { makeCall, sendMsgMail, virtualTourClick } from "../Data";
import { GetZoomLink } from "../Data2";
import { toast } from "react-toastify";
import { Col, Input, Label, ModalBody, Row } from "reactstrap";
import { countryCodes } from "../../../components/CountryCodes";
import Select from "react-select";

const StartZoomVendorModal = ({ open, handleClose }) => {
  const selectedCard = useSelector((state) => state.bwvData.selectedService);
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [isZoomStarted, setIsZoomStarted] = useState(false);
  const [link, setLink] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(null);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "90%", // 90% width on mobile
      sm: "70%", // 70% width on tablet
      md: "730px", // 600px width on desktop
    },
    height: {
      xs: "auto", // auto height on mobile
      sm: "auto", // auto height on tablet
      md: openModal ? "300px" : "730px", // 400px height on desktop
    },
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "hidden", // Prevent overflow on smaller screens
  };
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }
  const now = new Date();
  const formattedDate = formatDate(now);
  const handleOpenModal = () => {
    if (islogin) {
      handleStartZoomCall();
    } else {
      setOpenModal(true);
    }
  };
  const [selectedCountry, setSelectedCountry] = useState("+1");
  const countryOptions = countryCodes.map((country) => ({
    label: `${country.name} (${country.dial_code}) `,
    value: country.dial_code,
  }));

  const handleCodeChange = (value) => {
    console.log(value, "value");
    setSelectedCountry(value);
  };
  const handleStartZoomCall = async () => {
    if (!islogin && !name) {
      toast.error("Please Enter Email");
      return;
    }
    if (!islogin && !phoneNumber) {
      toast.error("Please Enter Phone Number");
      return;
    }
    if (!islogin && !selectedCountry) {
      selectedCountry = "+1";
    }
    setOpenModal(false);
    setLoading(true);
    const serviceInfo = JSON.parse(selectedCard?.additionalPeople);
    const newPerson1 = {
      email: !islogin ? name : user?.Email,
      phoneNumber: !islogin ? phoneNumber : user?.Phone,
      countryCode: !islogin ? selectedCountry : user?.CountryCode,
    };
    let newPerson2;
    let newPerson3;
    if (selectedCard.profilePhone === selectedCard.Phone) {
      newPerson2 = {
        email: selectedCard?.Email, // Assuming anotherUser is defined
        phoneNumber: selectedCard?.profilePhone,
        countryCode: selectedCard?.countryCode,
      };
    } else {
      newPerson2 = {
        email: selectedCard?.Email, // Assuming anotherUser is defined
        phoneNumber: selectedCard?.profilePhone,
        countryCode: selectedCard?.countryCode,
      };
      newPerson3 = {
        email: selectedCard?.Email, // Assuming anotherUser is defined
        phoneNumber: selectedCard?.Phone,
        countryCode: selectedCard?.countryCode,
      };
    }
    serviceInfo.unshift(newPerson1);
    serviceInfo.push(newPerson2);
    if (newPerson3) {
      serviceInfo.push(newPerson3);
    }
    const stringifiedAdditionalPeople = JSON.stringify(serviceInfo);
    let formData = {
      email: !islogin ? name : user?.Email,
      phoneNumber: !islogin ? phoneNumber : user?.Phone,
      countryCode: !islogin ? selectedCountry : user?.CountryCode,
      phoneDetails: stringifiedAdditionalPeople,
    };
    console.log(formData, "formData");
    const response = await makeCall(formData);
    toast.success(
      "Calling the Vendor now, Please Standby as we attempt to get them on the call"
    );
    if (response === "initiating call") {
      const formData = {
        startTime: formattedDate,
        needName: selectedCard?.listName,
      };
      const linkResponse = await GetZoomLink(formData, selectedCard?.Id);
      setLink(linkResponse.join_url);
      let formData1 = {
        zoomlink: linkResponse.join_url,
        phoneDetails: stringifiedAdditionalPeople,
        customerId: user?.UserID,
        ownerId: parseInt(selectedCard?.createdBy),
        Type: "details",
        postId: selectedCard?.Id,
        postName: selectedCard?.listName,
      };
      console.log(formData1, "formData1");
      await sendMsgMail(formData1);
      setLoading(false);
      setIsZoomStarted(true); // Set state to show the iframe
      await virtualTourClick(selectedCard?.Id);
    } else {
      throw new Error("Response from makeCall was invalid");
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpenModal(false);
        handleClose();
        setName("");
        setSelectedCountry("+1");
        setPhoneNumber(null);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {!isZoomStarted ? (
          <>
            <Box>
              {!openModal ? (
                <>
                  <Typography
                    id="modal-title"
                    variant="h5"
                    fontWeight="bold"
                    textAlign="center"
                    mb={2}
                    className="my-5"
                  >
                    Start Virtual Tour
                  </Typography>
                  <Typography
                    id="modal-description"
                    variant="body1"
                    textAlign="center"
                    mb={3}
                    className="my-5"
                  >
                    The vendor will answer your zoom call request & give you a
                    15 minute meeting. They’ll showcase their offering. <br />
                    <br />
                    You can keep your camera Off, if you wish.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleOpenModal}
                    sx={{ mb: 2 }}
                    className="my-5"
                    disabled={loading}
                  >
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm text-dark"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Start Tour Now"
                    )}
                  </Button>
                  <div className="start-zoom my-5">
                    <Typography
                      variant="body2"
                      className="start-zoom-text my-3"
                    >
                      "Please wait up to 1 minute for the vendor to join the
                      meeting. Try again later if vendor is busy & doesn't join
                      within the 1 minute wait”{" "}
                    </Typography>
                  </div>
                  <Link
                    href="#"
                    onClick={handleClose}
                    className="mt-5"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "x-small",
                      textDecoration: "underline",
                      cursor: "pointer",
                      margin: "10px",
                    }}
                  >
                    Continue Browsing Site
                  </Link>
                </>
              ) : (
                <div className="">
                  <FormGroup>
                    <Label>
                      Email<b className="text-danger">*</b>
                    </Label>
                    <Input
                      type="email"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <Row className="mt-3">
                      <Col sm={3}>
                        <Label>Country Code:</Label>
                        <FormGroup>
                          <div className="d-flex align-items-center login-ctr-select">
                            <Select
                              options={countryOptions}
                              name="CountryCode"
                              placeholder="Select"
                              value={
                                // selectedCountry
                                //   ? selectedCountry
                                //   : userDetails.CountryCode
                                selectedCountry
                                  ? {
                                      label: selectedCountry,
                                      value: selectedCountry,
                                    }
                                  : null
                              }
                              onChange={(selectedOption) => {
                                handleCodeChange(selectedOption.value);
                              }}
                              className="w-100"
                              // styles={{
                              //   control: (styles) => ({
                              //     ...styles,
                              //     width: "150px",
                              //     borderColor: "black",
                              //   }),
                              //   option: (styles) => ({
                              //     ...styles,
                              //     color: "black",
                              //     fontSize: "small",
                              //     width: "170px",
                              //   }),
                              //   menu: (styles) => ({
                              //     ...styles,
                              //     width: "150px", // Adjust the width of the menu as needed
                              //   }),
                              // }}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm={9}>
                        <FormGroup>
                          <Label>
                            Phone Number <b className="text-danger">*</b>
                          </Label>
                          <Input
                            type="text"
                            value={phoneNumber}
                            onChange={(e) => {
                              const { value } = e.target;
                              const isValidPhone = /^\d{0,10}$/.test(value); // Ensure up to 10 digits
                              if (isValidPhone) {
                                setPhoneNumber(value);
                              }
                            }}
                            maxLength={10}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </FormGroup>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mb: 2 }}
                    className="mt-2"
                    disabled={loading}
                    onClick={handleStartZoomCall}
                  >
                    Start Virtual Tour Now
                  </Button>
                </div>
              )}
            </Box>
          </>
        ) : (
          <iframe
            src={link}
            width="100%"
            height="100%"
            frameBorder="0"
            allow="camera; microphone; fullscreen; autoplay"
            title="Zoom Call"
            style={{ border: 0 }} // Remove default border
          />
        )}
      </Box>
    </Modal>
  );
};

export default StartZoomVendorModal;
