import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeTab: "Customers",
  searchTerm: "",
  selectedSubCategory: null,
  selectedItem: null,
  radius: [1, 1],
  allProducts: [],
  upcomingProducts: [],
  bwv: [],
  finalBwv: [],
  finalProducts: [],
  dupProducts: [],
  visibleCategories: [],
  showAll: false,
  Lca: [],
  searchQuery: "",
  lcaLoading: false,
  bwvLoading: false,
  bizzLca: [],
  selectedGurdwara: null,
  myOffers: [],
  parentMsg: null,
  selectedAd: null,
  month: "",
  guruSubCat: [],
};

const localCommunityAdsSlice = createSlice({
  name: "lcAds",
  initialState,
  reducers: {
    resetLca(state, action) {
      state.activeTab = "Customers";
      state.searchTerm = "";
      state.selectedSubCategory = null;
      state.selectedItem = null;
      state.radius = [1, 1];
      state.searchQuery = "";
    },
    setShowAll(state, action) {
      state.showAll = action.payload;
    },
    setSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
    setActiveTab(state, action) {
      state.activeTab = action.payload;
    },
    setSearchTerm(state, action) {
      state.searchTerm = action.payload;
    },
    setSelectedSubCategory(state, action) {
      state.selectedSubCategory = action.payload;
    },
    setSelectedItem(state, action) {
      state.selectedItem = action.payload;
    },
    setRadius(state, action) {
      state.radius = action.payload;
    },
    setAllProducts(state, action) {
      state.allProducts = action.payload;
    },
    setUpcomingProducts(state, action) {
      state.upcomingProducts = action.payload;
    },
    setBwv(state, action) {
      state.bwv = action.payload;
    },
    setFinalBwv(state, action) {
      state.finalBwv = action.payload;
    },
    setFinalProducts(state, action) {
      state.finalProducts = action.payload;
    },
    setLca(state, action) {
      state.Lca = action.payload;
    },
    setDupProducts(state, action) {
      state.dupProducts = action.payload;
    },
    setVisibleCategories(state, action) {
      state.visibleCategories = action.payload;
    },
    setShowAll(state, action) {
      state.showAll = action.payload;
    },
    setSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
    setLcaLoading(state, action) {
      state.lcaLoading = action.payload;
    },
    setBwvLoading(state, action) {
      state.bwvLoading = action.payload;
    },
    setBizzLca(state, action) {
      state.bizzLca = action.payload;
    },
    setSelectedGurdwara(state, action) {
      state.selectedGurdwara = action.payload;
    },
    setMyOffers(state, action) {
      state.myOffers = action.payload;
    },
    setParentMsg(state, action) {
      state.parentMsg = action.payload;
    },
    setSelectedAd(state, action) {
      state.selectedAd = action.payload;
    },
    setMonth(state, action) {
      state.month = action.payload;
    },
    setGuruSubCat(state, action) {
      state.guruSubCat = action.payload;
    },
  },
});

export const {
  resetLca,
  setActiveTab,
  setSearchTerm,
  setSelectedSubCategory,
  setSelectedItem,
  setRadius,
  setAllProducts,
  setUpcomingProducts,
  setBwv,
  setFinalBwv,
  setFinalProducts,
  setLca,
  setDupProducts,
  setVisibleCategories,
  setShowAll,
  setSearchQuery,
  setLcaLoading,
  setBwvLoading,
  setBizzLca,
  setSelectedGurdwara,
  setMyOffers,
  setParentMsg,
  setSelectedAd,
  setMonth,
  setGuruSubCat,
} = localCommunityAdsSlice.actions;

export default localCommunityAdsSlice.reducer;
