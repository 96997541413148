import React, { useState, useEffect } from "react";
import ProductCard from "./ProductCard";
import SmallScreenProducts from "./SmallScreenProducts";
import TvScreenProducts from "./TvScreenProducts";
import { FadeLoader } from "react-spinners";
import { useLocation } from "react-router-dom";
import SingleProduct from "./SingleProduct";
import { useSelector } from "react-redux";

const ProductSection = ({ data }) => {
  const location = useLocation();
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [tvScreen, setTvScreen] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const { guruSubCat } = useSelector((state) => state.lcAds);
  const updateItemsPerPage = () => {
    const screenWidth = window.innerWidth;
    switch (true) {
      case screenWidth >= 1200:
        setTvScreen(true);
        break;
      case screenWidth >= 768:
        setItemsPerPage(8 * 4);
        setTvScreen(false);
        break;
      case screenWidth >= 576:
        setItemsPerPage(6 * 4);
        setTvScreen(false);
        break;
      default:
        setTvScreen(false);
        break;
    }
    setIsInitialLoad(false);
  };
  useEffect(() => {
    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);
    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, []);
  if (isInitialLoad) {
    return (
      <FadeLoader
        color={"#36D7B7"}
        loading={true}
        className="top-100 start-100 translate-middle"
      />
    );
  }
  return (
    <div>
      <>
        {tvScreen ? (
          // <>
          //   {location && location.state?.gurudwara == "El Sobrante Gurdwara" ? (
          //     <SingleProduct data={data} } />
          //   ) : (
          <TvScreenProducts data={data}  />
        ) : (
          //   )}
          // </>
          <SmallScreenProducts
            data={data}
            itemsPerPage={itemsPerPage}
            tvScreen={tvScreen}
          />
        )}
      </>
    </div>
  );
};

export default ProductSection;
