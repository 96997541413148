import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Name: "",
  allItemSubCat: [],
  allItemGurdwara: [],
  Description: "",
  Quantity: 1,
  Price: null,
  Image: "",
  deliveryOption: "",
  createdEmail: "",
  itemStatus: 0,
  Address: "",
  subCategoryId: null,
  itemSubCatName: "",
  itemGurdwara: "",
  gurudwaraId: null,
  categoryId: 0,
  Phone: "",
  dialExt: null,
};

const IteamFormSlice = createSlice({
  name: "ifsForm",
  initialState,
  reducers: {
    resetItem: (state) => {
      state.Name = "";
      state.Description = "";
      state.Quantity = 1;
      state.Price = null;
      state.Image = "";
      state.deliveryOption = "";
      state.createdEmail = "";
      state.itemStatus = 0;
      state.Address = "";
      state.subCategoryId = null;
      state.itemSubCatName = "";
      state.itemGurdwara = "";
      state.gurudwaraId = null;
      state.categoryId = 0;
      state.Phone = "";
      state.dialExt = null;
    },
    setName: (state, action) => {
      state.Name = action.payload;
    },
    setAllItemSubCat: (state, action) => {
      state.allItemSubCat = action.payload;
    },

    setAllItemGurdwara: (state, action) => {
      state.allItemGurdwara = action.payload;
    },
    setDescription: (state, action) => {
      state.Description = action.payload;
    },
    setQuantity: (state, action) => {
      state.Quantity = action.payload;
    },
    setPrice: (state, action) => {
      state.Price = action.payload;
    },
    setImage: (state, action) => {
      state.Image = action.payload;
    },
    setDeliveryOption: (state, action) => {
      state.deliveryOption = action.payload;
    },
    setCreatedEmail: (state, action) => {
      state.createdEmail = action.payload;
    },

    setItemStatus: (state, action) => {
      state.itemStatus = action.payload;
    },

    setAddress: (state, action) => {
      state.Address = action.payload;
    },
    setItemSubCatId: (state, action) => {
      state.subCategoryId = action.payload;
    },
    setItemSubCatName: (state, action) => {
      state.itemSubCatName = action.payload;
    },
    setItemGurdwara: (state, action) => {
      state.itemGurdwara = action.payload;
    },
    setGurudwaraId: (state, action) => {
      state.gurudwaraId = action.payload;
    },
    setCategoryId: (state, action) => {
      state.categoryId = action.payload;
    },
    setPhone: (state, action) => {
      state.Phone = action.payload;
    },
    setDialExt: (state, action) => {
      state.dialExt = action.payload;
    },
   
  },
});

export const {
  resetItem,
  setName,
  setAllItemSubCat,
  setAllItemGurdwara,
  setDescription,
  setQuantity,
  setPrice,
  setImage,
  setDeliveryOption,
  setCreatedEmail,
  setItemStatus,
  setAddress,
  setItemSubCatId,
  setItemSubCatName,
  setItemGurdwara,
  setGurudwaraId,
  setCategoryId,
  setPhone,
  setDialExt,
} = IteamFormSlice.actions;
export default IteamFormSlice.reducer;
