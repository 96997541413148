import React, { useEffect, useState } from "react";
import Sidebar from "../../pages/SideBar";
import {
  Row,
  Col,
  Input,
  FormGroup,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { fetchAllUsers } from "../../components/Header/Data";
import UserCard from "./UserCard";
import "./user.css";
import { FaSearch } from "react-icons/fa";
import { FadeLoader } from "react-spinners";

const User = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);

  // Function to fetch users
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const users = await fetchAllUsers();
      console.log(users); // Log users to verify the structure of the data
      setAllUsers(users);
      setFilteredUsers(users); // Initially, show all users
    } catch (error) {
      console.error("Error fetching users:", error);
    }
    setLoading(false);
  };

  // Filter users based on search query
  const handleSearch = (query) => {
    setSearchQuery(query);
    console.log(query, "search query");
    // Filter users based on Email, FirstName, LastName, and UserName
    const filtered = allUsers.filter((user) => {
      const fullName = `${user.FirstName || ""} ${
        user.LastName || ""
      }`.toLowerCase(); // Safe check for missing firstName or lastName
      const lowerQuery = query.toLowerCase(); // Case insensitive search

      return (
        (user.Email && user.Email.toLowerCase().includes(lowerQuery)) || // Email (check if email exists)
        (user.FirstName && user.FirstName.toLowerCase().includes(lowerQuery)) || // FirstName
        (user.LastName && user.LastName.toLowerCase().includes(lowerQuery)) || // LastName
        (user.UserName && user.UserName.toLowerCase().includes(lowerQuery)) || // UserName
        fullName.includes(lowerQuery) // Combined FirstName + LastName
      );
    });

    setFilteredUsers(filtered);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <section className="bg-white">
      <div className="container">
        <Row>
          <Col sm={3}>
            <Sidebar />
          </Col>
          <Col sm={9} className="p-0">
            <div className="search-bar my-3 d-flex justify-content-end me-sm-2 me-md-4">
              <InputGroup className="w-sm-100 w-md-100 w-lg-50 w-xl-50">
                <Input
                  type="search"
                  value={searchQuery}
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder="Search by Email, Name, and Username"
                />
                <InputGroupText>
                  <FaSearch />
                </InputGroupText>
              </InputGroup>
            </div>
            {loading ? (
              <FadeLoader // FadeLoader component
                css={{ margin: "0 auto" }}
                color={"#36D7B7"}
                loading={loading}
                className="position-absolute translate-middle"
                style={{ left: "60%", top: "60%" }}
              />
            ) : (
              <>
                {filteredUsers.length === 0 ? (
                  <div
                    className="no-users-found inter-font"
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "20px",
                      height: "500px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    No users found
                  </div>
                ) : (
                  <UserCard allUsers={filteredUsers} fetchUsers={fetchUsers} />
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default User;
