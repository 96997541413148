//Conversations
import React, { useEffect, useRef, useState } from "react";
import "./messages.css";
import { useDispatch, useSelector } from "react-redux";
import { FcVideoCall } from "react-icons/fc";
import moment from "moment-timezone";
import { FaCheck, FaCheckDouble } from "react-icons/fa";
import paypalLogo from "../../../assets/images/paypal-logo.png";
import { useSocket } from "../../../contexts/SocketContext";
import { MessageSeen } from "../../../components/Header/Data2";
import {
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
} from "reactstrap";
import Payment from "../settingsComponents/Payment";
import { setChildId, setOrder } from "../../../redux/slices/MsgSlice";

function Conversation({ message }) {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const userId = user?.UserID;
  const { selectedConvo } = useSelector((state) => state.messages);
  const { socket } = useSocket();
  const dispatch = useDispatch();
  function convertTo12HourFormat(time24) {
    if (!time24) {
      return "";
    }
    const [hour24, minute] = time24.split(":").map(Number);
    if (isNaN(hour24) || isNaN(minute)) {
      return "";
    }
    const period = hour24 >= 12 ? "PM" : "AM";
    const hour12 = (hour24 % 12 || 12).toString().padStart(2, "0"); // Added padStart
    return `${hour12}:${minute.toString().padStart(2, "0")} ${period}`;
  }

  function formatDate(inputDate) {
    let date = new Date(inputDate);
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    let year = date.getFullYear();
    let month = monthNames[date.getMonth()];
    let day = date.getDate();
    let formattedDay = day < 10 ? "0" + day : day;
    let formattedDate = `${month} ${formattedDay}, ${year}`;
    return formattedDate;
  }
  function renderAttachment() {
    const photoUrl = message.Photo;
    if (!photoUrl) return null;

    const fileExtension = photoUrl?.split(".").pop().toLowerCase();
    if (["jpg", "jpeg", "png", "gif", "webp"].includes(fileExtension)) {
      return (
        <img
          src={photoUrl}
          alt="attachment"
          className="attachment-image"
          style={{ width: "200px", height: "auto" }} // Adjust size as needed
        />
      );
    } else if (["mp4", "webm", "ogg"].includes(fileExtension)) {
      return (
        <video
          controls
          className="attachment-video"
          style={{ width: "300px", height: "auto" }} // Adjust size as needed
        >
          <source src={photoUrl} type={`video/${fileExtension}`} />
        </video>
      );
    } else if (fileExtension === "pdf") {
      return (
        <div>
          <iframe
            src={photoUrl}
            width="100%"
            height="500" // Adjust height as needed
            frameBorder="0"
            scrolling="no"
          />
        </div>
      );
    }
    return null;
  }
  function convertToUserTimezone(utcTime) {
    let timezone = "America/Los_Angeles"; // Default to system timezone
    if (islogin && user?.TimeZone) {
      timezone = user?.TimeZone.split(",")[0];
    }
    const localTime = moment
      .utc(utcTime)
      .tz(timezone)
      .format("YYYY-MM-DD HH:mm:ss");
    return localTime;
  }
  function getMessageStatus() {
    if (message.createdBy == userId) {
      if (message.seenCustomer == 1) {
        return <FaCheckDouble style={{ color: "red" }} title="Read" />;
      }
      return <FaCheckDouble style={{ color: "gray" }} title="Delivered" />;
    }
    return null;
  }
  const userTimeZone = moment.tz.guess();
  // React.useEffect(() => {
  //   const handleNewMessage = async (message) => {
  //     if (message.Id == selectedConvo.Id) {
  //       let request = {};
  //       if (message?.createdBy === user.UserID) {
  //         request = {
  //           createdBy: user.UserID,
  //         };
  //       } else if (message?.customerId === user.UserID) {
  //         request = {
  //           customerId: user.UserID,
  //         };
  //       }
  //       await MessageSeen(message.Id, request);
  //     }
  //   };
  //   socket.on("new-message", handleNewMessage);
  //   return () => {
  //     socket.off("new-message", handleNewMessage);
  //   };
  // }, [socket]);
  const [paymentDetails, setPaymentDetails] = useState({
    description: "",
    amount: "",
    status: "",
  });
  let paymentDescription;
  let PaymentStatus;
  let totalPrice;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const eventListenerAdded = useRef(false);
  const handleChatPayment = async () => {
    // console.log(selectedConvo?.orderId, "selectedConvo?.orderId");
    // dispatch(setOrder(selectedConvo?.orderId));
  };
  const closeModal = () => {
    setModalIsOpen(false);
    // setAmount(null);
    // setOpenPaypal(false);
    // dispatch(setDescription(""));
  };
  const [pendingState, setPendingState] = useState("");
  const handlePendingPayment = (event) => {
    const messageId = event.target.id.replace("pendingPayment-", "");
    const clickedMessage = message.Id === parseInt(messageId);
    if (clickedMessage) {
      const paymentDetails = message.Message.replace(
        "payment for chat with",
        ""
      )
        .trim()
        .split(",")
        .map((item) => item.trim());
      setPaymentDetails({
        description: paymentDetails[0],
        amount: paymentDetails[1],
        status: paymentDetails[2],
      });
      setModalIsOpen(true);
      dispatch(setOrder(message?.orderId));
      dispatch(setChildId(message?.Id));
    }
  };

  useEffect(() => {
    // Add the event listener only once per message and user
    const pendingPayments = document.querySelectorAll(
      '[id^="pendingPayment-"]'
    );

    if (message?.createdBy === user?.UserID && !eventListenerAdded.current) {
      setPendingState(pendingPayments);
      pendingPayments.forEach((element) => {
        element.addEventListener("click", handlePendingPayment);
      });
    }

    // Clean up listeners when the component unmounts or re-renders
    return () => {
      pendingPayments.forEach((element) => {
        element.removeEventListener("click", handlePendingPayment);
      });
    };
  }, [modalIsOpen]); // Dependencies: message or user changes trigger the effect

  useEffect(() => {
    if (message?.orderStatus === "COMPLETED") {
      setModalIsOpen(false);
    }
  }, [message?.orderStatus]);

  return (
    <div
      className={`chat-message ${
        message.createdBy === userId ? "own" : "other"
      } `}
    >
      <div className="message-content">
        <div className="avatar d-flex align-items-center mb-1">
          <img
            src={
              message?.customerId !== user?.UserID
                ? message?.providerProfilePhoto &&
                  message?.providerProfilePhoto.trim() !== ""
                  ? message.providerProfilePhoto
                  : "https://cdn.pixabay.com/photo/2018/11/13/21/43/avatar-3814049_1280.png"
                : message?.customerProfilePhoto &&
                  message?.customerProfilePhoto.trim() !== ""
                ? message.customerProfilePhoto
                : "https://cdn.pixabay.com/photo/2018/11/13/21/43/avatar-3814049_1280.png"
            }
            alt="Avatar"
            className="rounded-circle border border-1 border-secondary bg-light avatar shadow p-1 d-inline-block"
            width={30}
            height={30}
          />
          <div className="sender mt-1" style={{ fontSize: "14px" }}>
            {message?.senderName}
          </div>
        </div>

        {message?.postType != 4 && (
          <p
            className="my-2 p-0 text-dark"
            style={{ fontSize: "medium" }}
            dangerouslySetInnerHTML={{
              __html: (() => {
                const messageText =
                  message.Message ||
                  message.messageComment ||
                  message.giftDescription ||
                  message.Description ||
                  "";

                // Determine the name based on the condition selectedcon?.createdby === user.userid
                const name =
                  message?.createdBy === user?.UserID
                    ? message?.customerName
                    : message?.senderName;

                // Check if the message contains "Click to join zoom meeting"
                if (messageText.includes("Click to join zoom meeting")) {
                  // Insert the condition after the "Click to join zoom meeting"
                  const zoomMessage = messageText.replace(
                    "Click to join zoom meeting",
                    `Click to join zoom meeting with ${message?.postName}`
                  );

                  // Render the updated message with the zoom link
                  return zoomMessage;
                }

                if (messageText.startsWith("My Business Profile")) {
                  // Split the remaining part based on commas
                  const detailsArray = messageText
                    .split(",")
                    .map((item) => item.trim());

                  // Extract values from the detailsArray
                  const description = detailsArray[0];
                  const userId = detailsArray[1];
                  // Generate  HTML with dynamic values
                  const htmlContent = `Check my Profile page<br/><a href="/#/userDetails/${userId}" target="_blank" style="text-decoration: underline !important;"> View Profile</a>`;
                  // const htmlContent = `Check My BusinessProfile<br/><a href="/#/vendorDetails/${userId}" target="_blank" style="text-decoration: underline !important;"> View Profile</a>`;

                  // Now, htmlContent contains the HTML structure with dynamic values inserted

                  // Render the updated message with the zoom link
                  return htmlContent;
                }
                // Example messageText: "payment for chat with Payment, 400, COMPLETED"
                if (messageText.startsWith("payment for chat with")) {
                  // Remove the "payment for chat with" part
                  const paymentDetails = messageText
                    .replace("payment for chat with", "")
                    .trim();

                  // Split the remaining part based on commas
                  const detailsArray = paymentDetails
                    .split(",")
                    .map((item) => item.trim());

                  // Extract values from the detailsArray
                  paymentDescription = detailsArray[0]; // Payment Type
                  totalPrice = detailsArray[1]; // 400
                  PaymentStatus = detailsArray[2]; // COMPLETED

                  // Generate the HTML with dynamic values
                  const htmlContent = `<div style="display:flex;align-items:center;height:80px"><img src="${paypalLogo}" style="height:60px;width:60px"/><div style="margin-left:10px"><div style="font-weight:bold;font-size:23px">$ ${
                    message?.totalAmount
                  }</div><div>${
                    message?.orderMessage?.length > 30
                      ? `${message?.orderMessage.slice(0, 30)}...`
                      : message?.orderMessage
                  }</div><div>Payment Status: <span style="font-weight:bold; cursor:${
                    message?.createdBy === user?.UserID &&
                    message?.orderStatus !== "COMPLETED"
                      ? "pointer"
                      : "auto"
                  };color:${
                    message?.createdBy === user?.UserID
                      ? message?.orderStatus === "COMPLETED"
                        ? "#198754"
                        : "blue"
                      : message?.orderStatus === "COMPLETED"
                      ? "#198754"
                      : "black"
                  };text-decoration:${
                    message?.createdBy === user?.UserID &&
                    message?.orderStatus !== "COMPLETED"
                      ? "underline"
                      : "none"
                  }">${
                    message?.orderStatus === "PENDING"
                      ? `<span id="pendingPayment-${message.Id}">PENDING</span>`
                      : message?.orderStatus
                  }</span></div></div></div>`;
                  // if (pendingState) {
                  //   console.log(pendingState, "pendingState");
                  //   const handlePendingPayment = (event) => {
                  //     const messageId = event.target.id.replace(
                  //       "pendingPayment-",
                  //       ""
                  //     );
                  //     const clickedMessage = message.Id === parseInt(messageId);
                  //     console.log(clickedMessage?.Message, "clickedMessage");
                  //     if (clickedMessage) {
                  //       const paymentDetails = message.Message.replace(
                  //         "payment for chat with",
                  //         ""
                  //       )
                  //         .trim()
                  //         .split(",")
                  //         .map((item) => item.trim());
                  //       setPaymentDetails({
                  //         description: paymentDetails[0],
                  //         amount: paymentDetails[1],
                  //         status: paymentDetails[2],
                  //       });
                  //       setModalIsOpen(true);
                  //       console.log(
                  //         message?.orderId,
                  //         message?.Id,
                  //         "orderID,messageID"
                  //       );
                  //       dispatch(setOrder(message?.orderId));
                  //       dispatch(setChildId(message?.Id));
                  //     }
                  //   };
                  //   console.log("Pending Payments Found:", pendingState.length);
                  //   {
                  //     if (message?.createdBy === user?.UserID) {
                  //       pendingState.forEach((element) => {
                  //         if (message?.createdBy === user?.UserID) {
                  //           console.log("Adding listener to:", element.id);
                  //           element.addEventListener(
                  //             "click",
                  //             handlePendingPayment
                  //           );
                  //         }
                  //       });
                  //     }
                  //   }
                  // }
                  // Now, htmlContent contains the HTML structure with dynamic values inserted
                  return htmlContent;
                }

                // Default case: Just render the message normally with the name.
                return messageText;
              })(),
            }}
          />
        )}
        {message?.postType == 4 && (
          <div>
            <p
              className="my-2 p-0 text-dark attachment-title"
              style={{ fontSize: "medium" }}
              dangerouslySetInnerHTML={{
                __html:
                  // (
                  //   message.Message ||
                  //   message.messageComment ||
                  //   message.giftDescription ||
                  //   ""
                  // ).length > 20
                  //   ? (
                  //       message.Message ||
                  //       message.messageComment ||
                  //       message.giftDescription ||
                  //       ""
                  //     ).substring(0, 20) + "..."
                  //   :
                  message.Message ||
                  message.messageComment ||
                  message.giftDescription ||
                  "",
              }}
            />
            {renderAttachment()}
          </div>
        )}

        <div
          className={`time text-dark mt-1 ${
            message.createdBy === userId ? "me-1" : "ms-1"
          }`}
          style={{ textAlign: message.createdBy === userId ? "right" : "left" }}
        >
          <span className="me-2">
            {moment(message?.createdAt)
              .tz(userTimeZone)
              .format("MMMM DD, YYYY hh:mm A")}
          </span>
          <span className="ms-2 message-status">{getMessageStatus()}</span>
        </div>
      </div>
      <Modal isOpen={modalIsOpen} toggle={closeModal}>
        <ModalBody>
          {/* <div>
              <Label>Title</Label>
              <Input
                type="text"
                name="title"
                placeholder="Title"
                value={title}
                onChange={(e) => dispatch(setTitle(e.target.value))}
              />
            </div> */}
          <div>
            <Label>
              Description <b className="text-danger">*</b>
            </Label>
            <Input
              type="textarea"
              name="descrption"
              placeholder="Please Enter Descrption"
              value={paymentDetails.description}
              // onChange={(e) => dispatch(setDescription(e.target.value))}
              disabled
            />
          </div>
          <div>
            <Label>Amount</Label>
            <InputGroup>
              <InputGroupText className="fw-bold">$</InputGroupText>
              <Input
                type="number"
                name="amount"
                placeholder="amount"
                value={paymentDetails.amount}
                // onChange={handleAmountChange}
                disabled
              />
            </InputGroup>
            <div>
              <Payment
                totalPrice={Number(paymentDetails.amount)}
                state={{ chatPay: true }}
                chatDetails={true}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Conversation;
