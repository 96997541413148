import React, { useState, useEffect } from "react";
import MessageList from "./MessagesList";
import ChatWindow from "./ChatWindow";
import { useDispatch, useSelector } from "react-redux";
import { getAllMessages } from "../../../components/Header/Data";
import {
  setConversations,
  setFinalConvos,
  setLoadingNotif,
  setLodingConvo,
  setMessages,
  setMyConnections,
  setMyJobOffers,
  setNewMessage,
  setOrder,
  setOrderStatus,
  setSelectedConversation,
  setTemps,
} from "../../../redux/slices/MsgSlice";
import { FadeLoader } from "react-spinners";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GetAllTemplates,
  GetConnections,
  GetItemOrderById,
  GetJobOffers,
} from "../../../components/Header/Data3";
import { getConvoById, MessageSeen } from "../../../components/Header/Data2";
import { useSocket } from "../../../contexts/SocketContext";

const InboxPage2 = () => {
  const { sendNewMessage, socket } = useSocket();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [isMobileView, setIsMobileView] = useState(false);
  const [showChatWindow, setShowChatWindow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const conversations = useSelector((state) => state.messages.convos);
  const { loadingNotif, myConnections, newMessage, messages } = useSelector(
    (state) => state.messages
  );
  const selectedConversation = useSelector(
    (state) => state.messages.selectedConvo
  );
  const logUser = localStorage.getItem("USER_ROLE");
  const fetchData = async () => {
    setIsLoading(true);
    dispatch(setLoadingNotif(true));
    const connectionResponse = await GetConnections(user.UserID);
    const jobResponse = await GetJobOffers(user.UserID);
    dispatch(setMyConnections(connectionResponse));
    dispatch(setMyJobOffers(jobResponse));
    dispatch(setLoadingNotif(false));
    fetchAllMsg();
    setIsLoading(false);
  };
  let count = 1;
  const fetchAllMsg = async () => {
    if (user.RoleId === 1) {
      const msgForm = {
        roleId: 1,
      };
      const messagesData = await getAllMessages(msgForm);
      const filterParent = messagesData.filter((msg) => msg.parentId === 0);
      dispatch(setConversations(filterParent));
      dispatch(setFinalConvos(filterParent));
      setIsLoading(false);
      window.scroll(0, 0);
      // if (filterParent.length > 0) {
      //   dispatch(setSelectedConversation(filterParent[0]));
      // }
    } else {
      const msgForm = {
        customerId: user.UserID,
        createdBy: user.UserID,
      };
      const messagesData = await getAllMessages(msgForm);
      const filterParent = messagesData.filter((msg) => msg.parentId === 0);
      const myMessages = filterParent.filter(
        (msg) =>
          msg.messageStatus !== 0 &&
          msg.messageStatus !== 1 &&
          msg.messageStatus !== 2
      );
      dispatch(setConversations(myMessages));
      window.scroll(0, 0);
      // if (filterParent.length > 0) {
      //   dispatch(setSelectedConversation(myMessages[0]));
      // }
      console.log(filterParent, "conversations");
    }
  };
  const fetchMsgs = async () => {
    dispatch(setLodingConvo(true));
    const messagesData = await getConvoById(selectedConversation?.Id);
    dispatch(setOrder(null));
    dispatch(setOrderStatus(""));
    dispatch(setMessages(messagesData));
    dispatch(setLodingConvo(false));
  };
  const fetchTemplates = async () => {
    const response = await GetAllTemplates();
    const active = response.filter((temp) => temp.template_status == 1);
    dispatch(setTemps(active));
    console.log((count = count + 1), "api call for templates");
  };

  useEffect(() => {
    fetchTemplates();
    fetchData();
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1024);
      if (window.innerWidth > 767) {
        setShowChatWindow(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const read = async (request, message) => {
    await MessageSeen(message.parentId, request);
  };
  useEffect(() => {
    const handleReceivedMessage = (message) => {
      console.log(message, "receive-message");
      fetchAllMsg();
      if (selectedConversation?.Id == message.parentId) {
        if (message?.createdBy === user.UserID) {
          let request = {
            createdBy: user.UserID,
          };
          read(request, message);
        } else if (message?.customerId === user.UserID) {
          let request = {
            customerId: user.UserID,
          };
          read(request, message);
        }
      }
      // setTimeout(() => {
      //   fetchMsgs();
      // }, 1000);
    };

    socket.on("receive-message", handleReceivedMessage);
    return () => {
      socket.off("receive-message", handleReceivedMessage);
    };
  }, [socket]);

  const handleSelectConversation = async (conversationId, msg) => {
    if (msg?.createdBy === user.UserID || msg?.customerId === user.UserID) {
      console.log("mymessage");
      const messagesData = await getConvoById(conversationId);
      let latest;
      let request = {};
      if (messagesData) {
        latest = messagesData[0];
      }
      if (latest && latest?.createdBy === user.UserID) {
        request = {
          createdBy: user.UserID,
        };
        console.log("mymessage-createdBy me");
      } else if (latest && latest?.customerId === user.UserID) {
        request = {
          customerId: user.UserID,
        };
        console.log("mymessage-recivedBy me");
      }
      await MessageSeen(conversationId, request);
    }
    dispatch(setMessages(null));
    const conversation = conversations.find(
      (conv) => conv.Id === conversationId
    );
    dispatch(setSelectedConversation(conversation));
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("chat", conversationId);
    navigate(
      {
        pathname: location.pathname,
        search: searchParams.toString(),
      },
      { replace: true }
    ); // Use replace to avoid adding to the history stack
    if (isMobileView) {
      setShowChatWindow(true);
    }
    fetchAllMsg();
    // onclick seen api called
  };

  const handleBackToList = () => {
    setShowChatWindow(false);
  };

  return (
    <div
      className="message-container inter-font"
      style={{
        height: logUser === "provider" ? "95vh" : "90vh",
      }}
    >
      {isLoading ? (
        <FadeLoader // FadeLoader component
          css={{ margin: "0 auto" }}
          color={"#36D7B7"}
          loading={isLoading}
          className="position-absolute top-50 start-50 translate-middle"
          //style={{ left: "0%", top: "40%" }}
        />
      ) : (
        <>
          {!isMobileView || !showChatWindow ? (
            <MessageList onSelectConversation={handleSelectConversation} />
          ) : null}
          {selectedConversation && (!isMobileView || showChatWindow) && (
            <ChatWindow
              conversation={selectedConversation}
              onBack={handleBackToList}
              isMobileView={isMobileView}
              fetchData={fetchData}
              fetchAllMsg={fetchAllMsg}
              fetchMsgs={fetchMsgs}
            />
          )}
        </>
      )}
    </div>
  );
};

export default InboxPage2;
