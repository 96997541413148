import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
const AppointmentStatus = ({ msg }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;

  return (
    <div className="d-flex flex-column justify-content-center align-items-center text-wrap">
      {/* {(msg.messageStatus === 1 || msg.messageStatus === 0) && (
        <>
          {user.RoleId === 1 ? (
            <>
              <Button
                color="success"
                className="btn-sm me-2 mb-2 mb-md-0"
                // onClick={(event) => handleApproveAdmin(event, msg)}
              >
                Approve
              </Button>
              <Button
                color="danger"
                className="btn-sm me-2 mb-2 mb-md-0"
                // onClick={(event) => handlerejectAdmin(event, msg)}
              >
                Reject
              </Button>
            </>
          ) : (
            <Button
              color="outline-success"
              className="btn-sm me-2 mb-2 mb-md-0"
            >
              Appointment Requested
            </Button>
          )}
        </>
      )} */}
      {msg.messageStatus === 2 && (
        <div className="status-bubble">
          <p className="text-danger">
            <small>Admin Rejected the Appointment</small>
          </p>
        </div>
      )}
      {msg.messageStatus === 3 && (
        <>
          {msg.ownerId === user.UserID ? (
            <>
              <Button
                color="success"
                className="btn-sm me-2 mb-2 mb-md-0"
                // onClick={(event) => handleAccept(event, msg)}
              >
                Accept
              </Button>
              <Button
                color="danger"
                className="btn-sm me-2 mb-2 mb-md-0"
                // onClick={(event) => handlereject(event, msg)}
              >
                Reject
              </Button>
              <Button
                color="warning"
                className="btn-sm me-2 mb-2 mb-md-0"
                // onClick={() => setNegotiate(true)}
              >
                Negotiate
              </Button>
            </>
          ) : (
            <div className="status-bubble">
              <p className="text-warning">
                <small>Waiting For Vendor Response</small>
              </p>
            </div>
          )}
        </>
      )}
      {(msg.messageStatus === 4 || msg.messageStatus === 9) && (
        <>
          <div className="status-bubble">
            <p className="text-success">
              <small>Appointment fixed</small>
            </p>
          </div>
        </>
      )}
      {(msg.messageStatus === 5 || msg.messageStatus === 10) && (
        <div className="status-bubble">
          <p className="text-danger">
            <small>
              {msg.createdBy === user.UserID
                ? "You cancelled the appointment"
                : "Other party cancelled the appointment"}
            </small>
          </p>
        </div>
      )}
      {user.RoleId !== 1 && msg.messageStatus === 6 && (
        <>
          {msg.ownerId !== user.UserID ? (
            <>
              <div className="status-bubble">
                <p className="text-warning">
                  <small>You Negotiated</small>
                </p>
              </div>
            </>
          ) : (
            <>
              <Button
                color="success"
                className="btn-sm me-2 mb-2 mb-md-0"
                // onClick={(event) => handleAccept(event, msg)}
              >
                Accept
              </Button>
              <Button
                color="danger"
                className="btn-sm me-2 mb-2 mb-md-0"
                // onClick={(event) => handlereject(event, msg)}
              >
                Reject
              </Button>
              <Button
                color="warning"
                className="btn-sm me-2 mb-2 mb-md-0"
                // onClick={() => setNegotiate(true)}
              >
                View Negotiation
              </Button>
            </>
          )}
        </>
      )}
      {user.RoleId !== 1 && msg.messageStatus === 7 && (
        <>
          {msg.ownerId === user.UserID ? (
            <div className="status-bubble">
              <p className="text-warning">
                <small>You Negotiated</small>
              </p>
            </div>
          ) : (
            <>
              <Button
                color="success"
                className="btn-sm me-2 mb-2 mb-md-0"
                // onClick={(event) => handleAccept(event, msg)}
              >
                Accept
              </Button>
              <Button
                color="danger"
                className="btn-sm me-2 mb-2 mb-md-0"
                // onClick={(event) => handlereject(event, msg)}
              >
                Reject
              </Button>
              <div className="status-bubble">
                <p className="text-warning">
                  <small>You Negotiated</small>
                </p>
              </div>
            </>
          )}
        </>
      )}
      {user.RoleId === 1 &&
        (msg.messageStatus === 6 || msg.messageStatus === 7) && (
          <div className="status-bubble">
            <p className="text-warning">
              <small>Disscussing</small>
            </p>
          </div>
        )}
    </div>
  );
};

export default AppointmentStatus;
