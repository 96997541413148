import React, { useEffect, useState } from "react";
import {
  FaArrowLeft,
  FaLightbulb,
  FaPlusCircle,
  FaRegLightbulb,
} from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../SideBar";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  resetItem,
  setAddress,
  setAllItemGurdwara,
  setAllItemSubCat,
  setDeliveryOption,
  setDescription,
  setItemGurdwara,
  setImage,
  setName,
  setPrice,
  setQuantity,
  setItemSubCatId,
  setItemSubCatName,
  setGurudwaraId,
  setDialExt,
  setPhone,
  setCategoryId,
} from "../../../redux/slices/IteamFormSlice";
import { toast } from "react-toastify";
import axiosClient from "../../../axios-client";
import uploadToS3 from "../../../utils/awsS3Upload";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@mui/material";
import "./CreateItem.css";
import {
  fetchCategories,
  fetchSubCategories,
  listGurudwaras,
} from "../../../components/Header/Data";

const CreateItem = () => {
  const [imageFile, setImageFile] = useState(null); // holds the selected file
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sending, setSending] = useState(false);
  const {
    Name,
    allItemSubCat,
    allItemGurdwara,
    Price,
    Quantity,
    Image,
    Description,
    Address,
    deliveryOption,
    itemSubCatName,
    itemGurdwara,
    Phone,
    dialExt,
  } = useSelector((state) => state.IteamForm);
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  const formData = useSelector((state) => state.IteamForm);
  const [deleveryType, setDeliveryType] = useState([]);
  let editMode = location.state;
  const handleIncrease = () => {
    if (Quantity >= 0) {
      dispatch(setQuantity(Quantity + 1));
    }
  };
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    // Allow only numbers and limit to 10 characters
    if (/^\d{0,10}$/.test(value)) {
      dispatch(setPhone(value));
    }
  };
  const fetchData = async () => {
    const category = await fetchCategories();
    const subCategories = await fetchSubCategories();
    const gurudwaras = await listGurudwaras();
    const filterCategoryByName = category.filter(
      (cat) => cat.Name === "Local Community Ads"
    );
    const filterSubCat = subCategories.filter(
      (subCat) => subCat.CategoryId === filterCategoryByName[0].Id
    );
    dispatch(setAllItemSubCat(filterSubCat));
    dispatch(setAllItemGurdwara(gurudwaras));
  };
  const handleDecrease = () => {
    if (Quantity > 1) {
      dispatch(setQuantity(Quantity - 1));
    }
  };
  const handleCategory = (e) => {
    dispatch(setItemSubCatName(e.target.value));
    const selectedCategory = allItemSubCat.filter(
      (cat) => cat.Name === e.target.value
    );
    dispatch(setItemSubCatId(selectedCategory[0].id));
  };
  const handleGurdwara = (e) => {
    dispatch(setItemGurdwara(e.target.value));
    const selectedGurdwara = allItemGurdwara.filter(
      (cat) => cat.Title === e.target.value
    );
    dispatch(setGurudwaraId(selectedGurdwara[0].Id));
  };
  useEffect(() => {
    if (editMode) {
      dispatch(setName(editMode.name));
      dispatch(setDescription(editMode.description));
      dispatch(setQuantity(editMode.quantity));
      dispatch(setPrice(editMode.price));
      dispatch(setImage(editMode.image));
      dispatch(setAddress(editMode.address));
      dispatch(setDeliveryOption(editMode.delivery_option));
      dispatch(setDialExt(editMode.dial_ext));
      dispatch(setPhone(editMode.phone));
      dispatch(setGurudwaraId(editMode.gurudwara_id));
      dispatch(setItemGurdwara(editMode.gurudwaraName));
      dispatch(setItemSubCatName(editMode.subCategoryName));
      dispatch(setItemSubCatId(editMode.sub_category_id));
    }
  }, [editMode]);

  React.useEffect(() => {
    if (deliveryOption) {
      const arrDelivery = JSON.parse(deliveryOption);
      setDeliveryType(arrDelivery);
    }
  }, [deliveryOption]);
  React.useEffect(() => {
    fetchData();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    //if deliveryOption not selected throw error
    if (deleveryType.length === 0) {
      toast.error("Please select at least one delivery option");
      return;
    }
    setSending(true);
    let imageUrl = "";
    if (editMode) {
      const updateFormData = {
        ...formData,
        updateBy: user ? user.UserID : null,
        itemStatus: 0,
      };
      try {
        const response = await axiosClient.put(
          `item/UpdateItem/${editMode.Id}`,
          updateFormData
        );
        console.log(response, "response");
        if (response.data.statusCode === 200) {
          toast.success(response.data.message);
          setSending(false);
        } else {
          toast.error(response.data.message);
          setSending(false);
        }
      } catch (error) {
        setSending(false);
        console.log(error, "error.response");
        toast.error(
          error.response.data.message + " " + error.response.data.statusCode
        );
      }
    } else {
      if (imageFile) {
        try {
          const folder = "item-for-sale/";
          imageUrl = await uploadToS3(imageFile, folder); // Upload to S3 and get URL
        } catch (error) {
          console.error("Error uploading image to S3:", error);
          toast.error("Image upload failed. Please try again.");
        }
      }
      const submitFormData = {
        ...formData,
        createdBy: user ? user.UserID : null, // assuming user.id contains the logged-in user's identifier
        Image: imageUrl,
      };
      try {
        const response = await axiosClient.post(
          "item/CreateItem",
          submitFormData
        );
        console.log(response, "response");
        if (response.data.statusCode === 200) {
          toast.success(response.data.message);
          setSending(false);
        } else {
          toast.error(response.data.message);
          setSending(false);
        }
      } catch (error) {
        setSending(false);
        console.log(error, "error.response");
        toast.error(
          error.response.data.message + " " + error.response.data.statusCode
        );
      }
    }
    dispatch(resetItem());
    setImageFile("");
    dispatch(setImage(""));
    navigate("/dashboardpage/my-items");
  };
  const handleImageChange = () => {
    const files = document.getElementById("imageUpload").files;
    if (files.length > 0) {
      const file = files[0];
      setImageFile(file); // Store the file for later S3 upload
      const reader = new FileReader();
      reader.onload = () => {
        dispatch(setImage(reader.result));
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePrice = (e) => {
    let value = e.target.value.replace(/[^0-9.]/g, "");
    const decimalCount = (value.match(/\./g) || []).length;
    if (decimalCount > 1) {
      value = value.substring(0, value.lastIndexOf("."));
    }
    if (value.length <= 6) {
      const parts = value.split(".");
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        value = parts.join(".");
      }
      dispatch(setPrice(value));
    }
  };
  // const [selectedTypes, setSelectedTypes] = useState([]);

  const handleChange = (e) => {
    const { value, checked } = e.target;
    const currentDeliveryOptions = JSON.parse(deliveryOption || "[]");

    if (checked) {
      currentDeliveryOptions.push(Number(value));
    } else {
      const index = currentDeliveryOptions.indexOf(Number(value));
      if (index > -1) {
        currentDeliveryOptions.splice(index, 1);
      }
    }
    dispatch(setDeliveryOption(JSON.stringify(currentDeliveryOptions)));
  };

  return (
    <section className="bg-white">
      <div className="container">
        <Row>
          <Col sm={3}>
            <Sidebar />
          </Col>
          <Col sm={9}>
            <div className="p-3">
              <span
                onClick={() => {
                  navigate(-1);
                  dispatch(resetItem());
                }}
                style={{ cursor: "pointer" }}
              >
                <FaArrowLeft />
                <p
                  style={{
                    marginLeft: "10px",
                    marginTop: "10px",
                    display: "inline",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Back
                </p>
              </span>
            </div>
            <div className="form-container p-4 mx-1 mt-3 mt-lg-4">
              <h6>Item Information</h6>
              <Form onSubmit={(e) => handleSubmit(e)}>
                <div>
                  <FormGroup>
                    <Label for="imageUpload" className="m-0">
                      Item Image<span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="file"
                      id="imageUpload"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                      name="images"
                    />
                  </FormGroup>
                  <div
                    className={`image-box ${
                      Image ? "image-box-uploaded" : "image-box-initial"
                    }`}
                    onClick={() =>
                      document.getElementById("imageUpload").click()
                    }
                  >
                    {Image ? (
                      <div className="image-preview">
                        <img
                          src={Image}
                          alt="Uploaded Image"
                          className="uploaded-image"
                          onMouseOver={(e) =>
                            (e.target.style.transform = "scale(1.05)")
                          }
                          onMouseOut={(e) =>
                            (e.target.style.transform = "scale(1)")
                          }
                        />
                      </div>
                    ) : (
                      <>
                        <FaPlusCircle className="upload-icon" />
                      </>
                    )}
                  </div>
                </div>
                <FormGroup>
                  <Label>
                    Item Name <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    placeholder="Enter Item Name"
                    required
                    name="name"
                    value={Name}
                    onChange={(e) => {
                      dispatch(setName(e.target.value));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Item Description <span className="text-danger">*</span>
                  </Label>
                  {/* text could be add styles like bold font ,color etc*/}
                  <Input
                    type="textarea"
                    rows="3"
                    placeholder="Enter Item Description"
                    required
                    name="description"
                    value={Description}
                    onChange={(e) => {
                      dispatch(setDescription(e.target.value));
                    }}
                  />
                </FormGroup>
                <Row>
                  <Col className="col-md-6">
                    <FormGroup>
                      <Label>
                        Select Sub Category
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="select"
                        name="category"
                        id="category"
                        required
                        style={{ borderRadius: "10px" }}
                        value={itemSubCatName}
                        onChange={(e) => handleCategory(e)}
                      >
                        <option value="">Select a category</option>
                        {allItemSubCat.map((category) => (
                          <option key={category.Id} value={category.Name}>
                            {category.Name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="col-md-6">
                    <FormGroup>
                      <Label>
                        Select Gurdwara<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="select"
                        required
                        name="gurdwara"
                        id="gurdwara"
                        style={{ borderRadius: "10px" }}
                        value={itemGurdwara}
                        onChange={(e) => handleGurdwara(e)}
                      >
                        <option value="">Select a gurdwara</option>
                        {allItemGurdwara.map((gurdwara) => (
                          <option key={gurdwara.Id} value={gurdwara.Title}>
                            {gurdwara.Title}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-6">
                    <FormGroup>
                      <Label>
                        Phone
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        name="Phone"
                        id="Phone"
                        required
                        placeholder="Enter Phone Number"
                        style={{ borderRadius: "10px" }}
                        value={Phone}
                        onChange={handlePhoneChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="col-md-6">
                    {isAdmin && (
                      <FormGroup>
                        <Label>
                          Dial Extension<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          required
                          name="dialExt"
                          id="dialExt"
                          placeholder="Enter dial extension"
                          style={{ borderRadius: "10px" }}
                          value={dialExt}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Check if the value contains only 4 digits
                            const isValid = /^\d{0,4}$/.test(value); // Allow only 4 digits
                            if (isValid) {
                              dispatch(setDialExt(value));
                            } else {
                              return;
                            }
                          }}
                        />
                      </FormGroup>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-6">
                    <FormGroup>
                      <Label>
                        Item Quantity <span className="text-danger">*</span>
                      </Label>
                      <div className="d-flex justify-content-center align-items-center">
                        <Button className="btn-light" onClick={handleDecrease}>
                          -
                        </Button>
                        <Input
                          type="text"
                          value={Quantity}
                          readOnly
                          required
                          className="text-center mx-2"
                          style={{ width: "60%" }}
                        />
                        <Button className="btn-light" onClick={handleIncrease}>
                          +
                        </Button>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col className="col-md-6">
                    <FormGroup>
                      <Label>
                        Price <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        name="price"
                        placeholder="$00.00"
                        required
                        value={Price && `$${Price}`}
                        onChange={(e) => handlePrice(e)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-6">
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Delivery Options Offered{" "}
                        <span className="text-danger">*</span>
                        <Tooltip
                          title="Utilize Uber Send Package service"
                          placement="right"
                        >
                          <span>
                            <FaLightbulb
                              color="yellow"
                              className="light-bulb"
                            />
                          </span>
                        </Tooltip>
                      </FormLabel>

                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={1}
                              checked={deleveryType.includes(1)}
                              onChange={handleChange}
                            />
                          }
                          label="Standard 3 Days Delivery"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={2}
                              checked={deleveryType.includes(2)}
                              onChange={handleChange}
                            />
                          }
                          label="Same Day Delivery"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={3}
                              checked={deleveryType.includes(3)}
                              onChange={handleChange}
                            />
                          }
                          label="Pick Up In Person"
                        />
                      </FormGroup>
                      {/* <div>
                        <strong>Selected Types: </strong>
                        {deleveryType.join(", ")}
                      </div> */}
                    </FormControl>
                  </Col>
                  <Col className="col-md-6">
                    {deliveryOption.includes(3) && (
                      <FormGroup>
                        <Label>
                          Pick Up Address <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          value={Address}
                          required={deliveryOption.includes(3)}
                          className="text-center mx-2"
                          onChange={(e) => dispatch(setAddress(e.target.value))}
                        />
                      </FormGroup>
                    )}
                  </Col>
                </Row>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    padding: "10px 2px 10px 10px",
                  }}
                >
                  <FormGroup>
                    <Button
                      type="submit"
                      className="btn btn-danger mb-2"
                      // onClick={(e) => handleSubmit(e)}
                      disabled={sending}
                    >
                      {sending ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </FormGroup>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default CreateItem;
