import React from "react";
import ItemCard from "../../../pages/PeopleInMyCommunity/ItemCards";
import axiosClient from "../../../axios-client";
import { toast } from "react-toastify";
import "./itemsSale.css";
import { FadeLoader } from "react-spinners";

const ItemsForSale = () => {
  const [profileItems, setProfileItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const getAllIfs = async () => {
    setLoading(true);
    try {
      const response = await axiosClient.get(`item/getAllItem`);
      if (response.data.statusCode === 200) {
        let items = response.data.itemList.filter(
          (item) => item.item_status === 1
        );
        setProfileItems(items);
        console.log(items, "profileItems");
      } else {
        toast.error(`${response.data.message} ${response.data.statusCode}`);
      }
    } catch (error) {
      console.error("Error fetching items:", error);
      toast.error("Failed to fetch items.");
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getAllIfs();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "150px",
        }}
      >
        <FadeLoader color="#36d7b7" />
      </div>
    );
  }

  return (
    <div className="items-for-sale-container bg-white">
      <div className="container">
        <div className="row">
          {!loading && profileItems && profileItems.length > 0 ? (
            <>
              <h5 className="">Items For Sale</h5>
              <div className="d-flex flex-wrap justify-content-center p-2">
                <ItemCard
                  profileItems={profileItems}
                  setProfileItems={setProfileItems}
                  getAllIfs={getAllIfs}
                />
              </div>
            </>
          ) : (
            <div className="text-center">
              <h5 className="p-5">No Items Available</h5>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemsForSale;
