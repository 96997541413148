import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: {},
};

const socketSlice = createSlice({
  name: "socket",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    userOnline: (state, action) => {
      state.users[action.payload] = true;
    },
    userOffline: (state, action) => {
      delete state.users[action.payload];
    },
  },
});

export const { setUsers, userOnline, userOffline } = socketSlice.actions;

export default socketSlice.reducer;
