import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  itemsInAds: [],
  loadingItems: false,
};
const latestStates = createSlice({
  name: "latestAds",
  initialState,
  reducers: {
    resetLatestAds: (state) => {},
    setItemsInAds: (state, action) => {
      state.itemsInAds = action.payload;
    },
    setLoadingItems: (state, action) => {
      state.loadingItems = action.payload;
    },
  },
});

export const { resetLatestAds, setItemsInAds, setLoadingItems } =
  latestStates.actions;
export default latestStates.reducer;
