import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import { setSearchTerm } from "../redux/slices/BwvData";

const PlaceSearch = ({ Location }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [placeSelected, setPlaceSelected] = useState(false);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const listRef = useRef(null);
  const { finalBwv, originalBwv, searchTerm } = useSelector(
    (state) => state.bwvData
  );
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(searchTerm, "searchTerm");
    console.log(suggestions, "suggestions");
  }, [searchTerm]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (listRef.current && !listRef.current.contains(event.target)) {
      setSuggestions([]);
    }
  };

  const ShowPlaces = () => {
    if (window.google && window.google.maps) {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService();
      if (searchTerm) {
        autocompleteService.getPlacePredictions(
          { input: searchTerm },
          (predictions, status) => {
            if (status === "OK") {
              setSuggestions(predictions);
            } else {
              console.error("Error fetching suggestions:", status);
            }
          }
        );
      } else {
        setSuggestions([]);
      }
    }
  };

  const handleSelect = (place) => {
    setPlaceSelected(true);
    dispatch(setSearchTerm(place.description));
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      if (activeSuggestion < suggestions.length - 1) {
        setActiveSuggestion(activeSuggestion + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (activeSuggestion > 0) {
        setActiveSuggestion(activeSuggestion - 1);
      }
    } else if (e.key === "Enter") {
      handleSelect(suggestions[activeSuggestion]);
      setSuggestions([]);
    }
  };

  return (
    <div>
      <Input
        type="text"
        placeholder="Search Locations..."
        value={searchTerm}
        onChange={(e) => {
          dispatch(setSearchTerm(e.target.value));
          setPlaceSelected(false);
          ShowPlaces();
        }}
        onKeyDown={handleKeyDown}
      />
      {suggestions.length > 0 && !placeSelected && (
        <ul ref={listRef} className="list-group">
          {suggestions.map((place, index) => (
            <li
              key={place.place_id}
              className="list-group-item"
              onMouseEnter={(e) => (
                (e.target.style.backgroundColor = "#5435C2"),
                (e.target.style.color = "white"),
                (e.target.style.borderRadius = "10px")
              )}
              onMouseLeave={(e) => (
                (e.target.style.backgroundColor = "initial"),
                (e.target.style.color = "black")
              )}
              onClick={() => {
                handleSelect(place);
                setSuggestions([]);
              }}
              style={
                activeSuggestion === index
                  ? {
                      backgroundColor: "#5435C2",
                      color: "white",
                      borderRadius: "10px",
                    }
                  : {}
              }
            >
              {place.description}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PlaceSearch;
