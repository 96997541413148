// GoogleCalendar.js
import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "./Calender.css";
import moment from "moment";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { BlockCalander, fetchCalanderEvents } from "../Header/Data";
import { Col, Row } from "reactstrap";
import { setSelectedServiceDate } from "../../redux/slices/BwvData";

const GoogleCalendar = ({ openQuoteModal, selectedHour, setSelectedHour }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const { selectedServiceDate } = useSelector((state) => state.bwvData);
  const userinfo = useSelector((state) => state.user.userInfo);
  const selectedCard = useSelector((state) => state.bwvData.selectedService);
  const user = islogin ? JSON.parse(userinfo) : null;
  const availableDays = selectedCard?.availableDays;
  const [blockedDates, setBlockedDates] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const dispatch = useDispatch();
  const handleDateChange = (date) => {
    dispatch(setSelectedServiceDate(date));
  };
  const handleHourChange = (slotTime) => {
    const hour = slotTime.getHours();
    const minute = slotTime.getMinutes();
    setSelectedHour(`${hour}:${minute}`);
  };
  const fetchBlockedData = async () => {
    const dates = await BlockCalander(selectedCard?.createdBy);
    setBlockedDates(dates);
  };
  useEffect(() => {
    if (selectedCard) {
      fetchBlockedData();
    }
  }, [selectedCard]);
  const handleQuote = () => {
    if (!selectedHour) {
      toast.warning("Please select a time slot");
      return;
    }

    const validAvailableDays = availableDays.filter((available) => {
      return available.days.length > 0 && available.from && available.to;
    });

    const selectedDateObj = moment(selectedServiceDate);
    const selectedDayOfWeek = selectedDateObj.format("dddd");
    const selectedHourParts = selectedHour.split(":");
    const selectedHourStart = parseInt(selectedHourParts[0]);
    const selectedMinuteStart = parseInt(selectedHourParts[1]);

    const isHourAvailable = validAvailableDays.some((available) => {
      const days = available.days;
      const startTime = available.from.split(":");
      const endTime = available.to.split(":");
      const startHour = parseInt(startTime[0]);
      const startMinute = parseInt(startTime[1]);
      const endHour = parseInt(endTime[0]);
      const endMinute = parseInt(endTime[1]);

      if (days.includes(selectedDayOfWeek)) {
        if (
          selectedHourStart > startHour ||
          (selectedHourStart === startHour &&
            selectedMinuteStart >= startMinute)
        ) {
          if (
            selectedHourStart < endHour ||
            (selectedHourStart === endHour && selectedMinuteStart <= endMinute)
          ) {
            return true;
          }
        }
      }

      return false;
    });

    if (!isHourAvailable) {
      console.log(isHourAvailable, "isHourAvailable");
      toast.warning("Provider Not Available");
    } else {
      openQuoteModal();
    }
  };
  const fetchEvents = async () => {
    const calendarId = user?.Email;
    const events = await fetchCalanderEvents(calendarId);
    console.log(events, "calendarEvents");
    setCalendarEvents(events);
  };
  useEffect(() => {
    fetchEvents();
  }, []);

  const currentHour = new Date().getHours();
  useEffect(() => {
    if (!moment(new Date()).isSame(selectedServiceDate, "day")) {
      setSelectedHour(null); // Reset selected hour when the date changes
    }
  }, [selectedServiceDate]);
  // const Hour = date.getHours()
  const generateHourSlots = () => {
    const slots = [];
    const now = new Date();
    const selectDate = new Date(selectedServiceDate);
    const isToday = moment(now).isSame(selectDate, "day");
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 2; j++) {
        let formattedHour;
        let period;
        const hour = i;
        const minute = j === 0 ? "00" : "30"; // Create a slot for each half-hour (00 or 30)

        // Determine the correct hour and period (AM/PM)
        if (hour < 12) {
          formattedHour = hour === 0 ? "12" : `${hour}`;
          period = "AM";
        } else {
          formattedHour = hour === 12 ? "12" : `${hour - 12}`;
          period = "PM";
        }

        // Create the slot time in 30-minute intervals
        const slotTime = new Date(selectDate);
        slotTime.setHours(hour, j * 30, 0, 0); // Set hour and minute (00 or 30)

        let startendblocked = "";
        const isSelected =
          selectedHour !== null &&
          slotTime.getTime() ===
            new Date(selectDate).setHours(
              selectedHour.split(":")[0],
              selectedHour.split(":")[1],
              0,
              0
            );

        const isAvailable = availableDays?.some((available) => {
          const days = available?.days;
          const startTime = parseInt(available?.from.split(":")[0]);
          const endTime = parseInt(available?.to.split(":")[0]);
          const currentDay = moment(selectDate).format("dddd");

          // Check if the current day and time slot are within available time range
          if (
            days.includes(currentDay) &&
            hour >= startTime &&
            hour <= endTime
          ) {
            startendblocked = ""; // Reset to empty if available for the current item
            return true; // Slot is available
          } else {
            startendblocked = "blocked";
          }
          return false; // Slot is not available
        });

        const isBlockedByCalendarEvents =
          calendarEvents &&
          calendarEvents.some((event) => {
            const eventStartHour = moment.utc(event.start.dateTime).hour();
            const eventEndHour = moment.utc(event.end.dateTime).hour();
            return (
              slotTime.getHours() >= eventStartHour &&
              slotTime.getHours() < eventEndHour
            );
          });

        const isBlockedByBlockedDates = blockedDates.some((blockedDate) => {
          const startDate = new Date(
            `${blockedDate.reserve_date} ${blockedDate.start_time}`
          );
          const endDate = new Date(
            `${blockedDate.reserve_date} ${blockedDate.end_time}`
          );
          return slotTime >= startDate && slotTime <= endDate;
        });

        const isBlocked = isBlockedByBlockedDates || isBlockedByCalendarEvents;
        const isCompleted = isToday && slotTime < now;

        // Ensure the slot is available and not blocked
        if (isAvailable && !isBlocked && !isCompleted) {
          slots.push(
            <div
              key={`${i}-${j}`} // Use both hour and minute to ensure unique key for each slot
              className={`${startendblocked} hour-slot ${
                isSelected ? "selected" : ""
              } ${
                isAvailable && !isCompleted && !isBlocked ? "available" : ""
              } ${
                slotTime.getHours() === currentHour &&
                slotTime.getMinutes() === 0
                  ? "focus"
                  : ""
              }`}
              onClick={() =>
                isAvailable &&
                !isCompleted &&
                !isBlocked &&
                handleHourChange(slotTime)
              }
            >
              {formattedHour.padStart(2, "0")}:{minute} {period}
            </div>
          );
        }
      }
    }

    if (slots.length === 0) {
      return (
        <div style={{ textAlign: "center", marginTop: "30%" }}>
          No slots available for vendors on this date.
        </div>
      );
    }

    return slots;
  };

  let initialTime = new Date();
  initialTime.setHours(15, 0, 0);
  const tileClassName = ({ date, view }) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set current date to midnight for comparison
    if (view === "month" && date.getTime() === selectedServiceDate?.getTime()) {
      return "selected-date";
    }
    return date < currentDate ? "completed-date" : null;
  };
  var date = new Date();
  date.setDate(date.getDate() - 1);

  return (
    <div className="google-calendar">
      <div className="calendar-container my-2">
        <div className="date-picker">
          <Calendar
            onChange={handleDateChange}
            value={selectedServiceDate}
            tileClassName={tileClassName}
            minDate={date}
          />
        </div>
        <div className="time-slot">
          <div className="hour-slots">{generateHourSlots()}</div>
        </div>
      </div>
      <Row className="d-flex justify-content-between align-items-center">
        <Col sm={12} lg={6} className="d-flex justify-content-center">
          <button className="btn btn-primary" onClick={() => handleQuote()}>
            Request In Person Appointment
          </button>
        </Col>

        <Col
          sm={12}
          lg={6}
          className="d-flex my-3 justify-content-center"
          style={{ gap: "5px", fontSize: "10px" }}
        >
          <div
            style={{
              padding: "5px",
              backgroundColor: "#666",
              borderRadius: "50%",
              height: "10px",
              width: "10px",
            }}
          ></div>{" "}
          Avaliable
          <div
            style={{
              padding: "5px",
              backgroundColor: "#2C6FFF",
              borderRadius: "50%",
              height: "10px",
              width: "10px",
            }}
          ></div>
          Selected Hour
        </Col>
      </Row>
    </div>
  );
};

export default GoogleCalendar;
