import React from "react";
import { Row, Col } from "reactstrap";
import { Card, CardBody, CardImg } from "reactstrap";
import { useNavigate } from "react-router-dom";
import ReactStars from "react-rating-stars-component";

const FreeGifts = ({ freeGiftVendors }) => {
  const navigate = useNavigate();
  const cardStyle = {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    margin: "0",
    padding: "0",
  };

  const handleCardClick = (selectedCard) => {
    let Id = selectedCard.Id;
    navigate(`/vendordetails/${Id}`, { state: true });
  };
  return (
    <div className="NewListings">
      <h3>Free Gifts/ Sample Services</h3>
      <hr />
      <Row>
        {freeGiftVendors.length > 0 ? (
          <Row className="flex-nowrap overflow-auto pb-2">
            {freeGiftVendors
              .slice()
              .sort((a, b) => b.freeGift - a.freeGift) // Sort in descending order
              .map((provider) => (
                <Col
                  key={provider.Id}
                  xs="12"
                  sm="6"
                  md="4"
                  lg="3"
                  className="mb-4"
                >
                  <Card
                    style={cardStyle}
                    onClick={() => handleCardClick(provider)}
                  >
                    <CardImg
                      top
                      src={
                        provider.Photo &&
                        JSON.parse(provider.Photo).length > 0 &&
                        JSON.parse(provider.Photo)[0] !== ""
                          ? JSON.parse(provider.Photo)[0]
                          : "https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                      }
                      alt={provider.listName}
                      style={{
                        objectFit: "cover",
                        height: "200px",
                        width: "100%",
                      }}
                    />
                    <CardBody style={{ gap: 10 }}>
                      <p style={{ fontSize: "small" }}>
                        {provider.categoryName}
                      </p>
                      <h5 style={{ color: "black" }}>{provider.listName}</h5>
                      <Row>
                        <Col sm={12} className="d-flex justify-content-center">
                          <ReactStars
                            count={5}
                            size={20}
                            value={Math.round(provider.Rating)}
                            activeColor="#ffd700"
                            edit={false}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <h6 style={{ color: "blue" }}>
                          {provider.giftAmount
                            ? `Gift Amount: $${provider?.giftAmount}`
                            : `Free Service: ${provider?.giftTitle}`}
                        </h6>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              ))}
          </Row>
        ) : (
          <Col xs="12">
            <p className="text-center p-5">No Gifts Avaliable</p>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default FreeGifts;
