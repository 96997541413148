import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  Button,
  Form,
  FormGroup,
} from "reactstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { listGurudwaras, updateProfile } from "../../components/Header/Data";
import Select from "react-select";
import { countryCodes } from "../../components/CountryCodes";

const UpdateUserModal = ({ isOpen, closeModal, userInfo }) => {
  const [newDetails, setNewDetails] = useState({
    FirstName: userInfo?.FirstName || "",
    LastName: userInfo?.LastName || "",
    UserName: userInfo?.UserName || "",
    Email: userInfo?.Email || "",
    Phone: userInfo?.MobileNo || "",
    Gurudwara: userInfo?.Gurudwara || "",
    twilioNumber: userInfo?.twilioNumber || "",
    secondaryPhone: userInfo?.secondaryPhone || "",
  });
  const [gurdwaras, setGurdwaras] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("+1");
  const fetchData = async () => {
    const gurudwaras = await listGurudwaras();
    setGurdwaras(gurudwaras);
  };
  const countryOptions = countryCodes.map((country) => ({
    label: `${country.name} (${country.dial_code}) `,
    value: country.dial_code,
  }));
  const handleCodeChange = (value) => {
    console.log(value, "value");
    setSelectedCountry(value);
  };
  console.log(userInfo, "userInfo");
  useEffect(() => {
    fetchData();
  }, []);
  // Update newDetails when userInfo changes
  useEffect(() => {
    setNewDetails({
      FirstName: userInfo?.FirstName || "",
      LastName: userInfo?.LastName || "",
      UserName: userInfo?.UserName || "",
      Email: userInfo?.Email || "",
      Phone: userInfo?.MobileNo || "",
      Gurudwara: userInfo?.Gurudwara || "",
      twilioNumber: userInfo?.twilioNumber || "",
      secondaryPhone: userInfo?.secondaryPhone || "",
    });
  }, [userInfo]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "Phone") {
      const formattedValue = /^\d{0,10}$/.test(value) ? value : "";
      setNewDetails({
        ...newDetails,
        Phone: formattedValue,
      });
    } else if (name === "twilioNumber") {
      const formattedValue = value.slice(0, 10); // Slice value to max 10 digit
      setNewDetails({
        ...newDetails,
        twilioNumber: formattedValue,
      });
    } else {
      setNewDetails({
        ...newDetails,
        [name]: value,
      });
    }
  };

  const handleSubmit = async () => {
    if (!newDetails?.FirstName) {
      toast.error("Please Enter First Name");
      return;
    }
    if (!newDetails?.LastName) {
      toast.error("Please Enter Last Name");
      return;
    }
    if (!newDetails?.Email) {
      toast.error("Please Enter User Name");
      return;
    }
    if (!newDetails?.Phone) {
      toast.error("Please Enter Mobile Number");
      return;
    }
    await updateProfile(newDetails, userInfo.Id);
    closeModal();
    toast.success(`${newDetails.UserName} Profile Updated`);
  };

  return (
    <Modal isOpen={isOpen} toggle={closeModal} className="user-update-modal">
      <ModalBody>
        <button
          type="button"
          className="btn-close position-absolute top-0 end-0 m-2"
          onClick={closeModal}
          aria-label="Close"
        ></button>
        <div className="modal-body">
          <div>
            <Form>
              <Row className="mt-3">
                <Col>
                  <Label className="">
                    First Name <b className="text-danger">*</b>
                  </Label>
                  <Input
                    type="text"
                    name="FirstName"
                    placeholder="First Name"
                    className="form-control"
                    value={newDetails.FirstName}
                    onChange={handleInputChange}
                  />
                </Col>
                <Col>
                  <Label className="">
                    Last Name <b className="text-danger">*</b>
                  </Label>
                  <Input
                    type="text"
                    name="LastName"
                    placeholder="Last Name"
                    className="form-control"
                    value={newDetails.LastName}
                    onChange={handleInputChange}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg={4}>
                  <Label className="">User Name</Label>
                  <Input
                    type="text"
                    name="UserName"
                    placeholder="User Name"
                    className="form-control"
                    value={newDetails.UserName}
                    onChange={handleInputChange}
                  />
                </Col>
                <Col lg={8}>
                  <Label className="">
                    Email <b className="text-danger">*</b>
                  </Label>
                  <Input
                    type="email"
                    name="Email"
                    placeholder="Enter Email"
                    className="form-control"
                    value={newDetails.Email}
                    onChange={handleInputChange}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Label className="">
                    Mobile <b className="text-danger">*</b>
                  </Label>
                  <Input
                    type="text"
                    name="Phone"
                    placeholder="Mobile"
                    className="form-control"
                    value={newDetails.Phone}
                    onChange={handleInputChange}
                    maxLength={10}
                  />
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Secondary Mobile</Label>
                    <Input
                      type="tel"
                      name="secondaryPhone"
                      value={newDetails?.secondaryPhone}
                      onChange={handleInputChange}
                      // disabled
                      maxLength={10}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label className="">Select Gurdwara</Label>
                  <Input
                    type="select"
                    name="Gurudwara"
                    placeholder="Mobile"
                    className="form-control"
                    value={newDetails.Gurudwara}
                    onChange={handleInputChange}
                  >
                    <option value="0">Select a Gurdwara</option>
                    {gurdwaras.map((gurudwara) => (
                      <option key={gurudwara.Id} value={gurudwara.Id}>
                        {gurudwara.Title}
                      </option>
                    ))}
                  </Input>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={4}>
                  <Label>Country Code:</Label>
                  <FormGroup>
                    <div className="d-flex align-items-center login-ctr-select">
                      <Select
                        options={countryOptions}
                        name="CountryCode"
                        placeholder="Select"
                        value={
                          // selectedCountry
                          //   ? selectedCountry
                          //   : userDetails.CountryCode
                          selectedCountry
                            ? {
                                label: selectedCountry,
                                value: selectedCountry,
                              }
                            : null
                        }
                        onChange={(selectedOption) => {
                          handleCodeChange(selectedOption.value);
                        }}
                        className="w-100"
                        // styles={{
                        //   control: (styles) => ({
                        //     ...styles,
                        //     width: "150px",
                        //     borderColor: "black",
                        //   }),
                        //   option: (styles) => ({
                        //     ...styles,
                        //     color: "black",
                        //     fontSize: "small",
                        //     width: "170px",
                        //   }),
                        //   menu: (styles) => ({
                        //     ...styles,
                        //     width: "150px", // Adjust the width of the menu as needed
                        //   }),
                        // }}
                        isDisabled={true}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col sm={8}>
                  <FormGroup>
                    <Label>Twilio Number</Label>
                    <Input
                      type="text"
                      name="twilioNumber"
                      value={newDetails?.twilioNumber}
                      onChange={handleInputChange}
                      maxLength={10}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <Button color="primary" onClick={handleSubmit}>
              Update
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UpdateUserModal;
