import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import {
  CreateNewTemplate,
  updateTemplate,
} from "../../../components/Header/Data3";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { fetchTemplates } from "../../../redux/slices/TemplateSlice";

const CreateTemplate = ({ open, handleClose, fullScreen, editTemp }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [sending, setSending] = useState(false);
  const [formValues, setFormValues] = useState({
    Title: "",
    Description: "",
    createdBy: user.UserID,
    templateStatus: 0,
    updatedBy: user.UserID,
  });

  const [errors, setErrors] = useState({
    Title: "",
    Description: "",
  });

  // Handle form input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };
  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    if (!formValues.Title.trim()) {
      valid = false;
      newErrors.Title = "Template name is required.";
    }
    if (!formValues.Description.trim()) {
      valid = false;
      newErrors.Description = "Description is required.";
    }
    setErrors(newErrors);
    return valid;
  };
  useEffect(() => {
    if (editTemp) {
      setFormValues((prev) => ({
        ...prev,
        Title: editTemp.Title,
        Description: editTemp.Description,
      }));
    }
  }, [editTemp]);
  console.log(formValues, "formValues");
  // Handle form submission
  const handleSubmit = async () => {
    if (!validateForm()) return;
    setSending(true);
    if (editTemp) {
      const request = {
        Title: formValues.Title,
        Description: formValues.Description,
        updatedBy: user.UserID,
        templateStatus: 0,
      };
      await updateTemplate(editTemp.Id, request);
      toast.success("Template updated successfully");
      setSending(false);
    } else {
      await CreateNewTemplate(formValues);
      toast.success("Template created successfully");
      setSending(false);
    }
    handleClose();
    setFormValues((prev) => ({
      ...prev,
      Title: "",
      Description: "",
    }));

    fetchTemplates();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="create-template-dialog-title"
    >
      <DialogTitle id="create-template-dialog-title">
        Create New Template
      </DialogTitle>
      <DialogContent>
        <form>
          {/* Template Name Field */}
          <TextField
            fullWidth
            margin="normal"
            label="Template Name"
            name="Title"
            value={formValues.Title}
            onChange={handleChange}
            error={!!errors.Title}
            helperText={errors.Title}
          />

          {/* Description Field */}
          <TextField
            fullWidth
            margin="normal"
            label="Description"
            name="Description"
            value={formValues.Description}
            onChange={handleChange}
            multiline
            rows={4}
            error={!!errors.Description}
            helperText={errors.Description}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={sending}
        >
          {sending ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Submit"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTemplate;
