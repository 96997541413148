import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Label, ListGroup, ListGroupItem } from "reactstrap";
import { setSearchLocation } from "../../redux/slices/PeopleInComSlice";
import { MdLocationPin } from "react-icons/md";

const SearchByLocation = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const { allUsers, searchLocation, finalUsers } = useSelector(
    (state) => state.peopleInCom
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await fetch(
          "https://countriesnow.space/api/v0.1/countries/population/cities"
        );
        const data = await response.json();
        const allCities = data.data.map((city) => city.city);
        setCities(allCities);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };

    fetchCities();
  }, []);

  useEffect(() => {
    const results = cities.filter((city) =>
      city.toLowerCase().includes(searchLocation.toLowerCase())
    );
    setSearchResults(results);
  }, [searchLocation, cities]);

  const handleInputChange = (event) => {
    const term = event.target.value;
    dispatch(setSearchLocation(term));
  };
  const handleClearInput = () => {
    dispatch(setSearchLocation(""));
    setSearchResults([]); // Clear search results
    setSelectedCity(null); // Clear the selected user
  };

  const handleCitySelect = (city) => {
    dispatch(setSearchLocation(city));
    setSearchResults([]);
    setSelectedCity(city);
  };
  const noResultsFound = searchLocation && searchResults.length === 0;
  return (
    <div>
      <Label>Search By Location</Label>
      <div className="input-container">
        <MdLocationPin
          style={{
            position: "absolute",
            left: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            color: "#aaa",
          }}
        />
        <Input
          type="text"
          className="search-input"
          value={searchLocation}
          onChange={handleInputChange}
          placeholder="Search cities..."
          autoComplete="off"
          list="cities"
          name="city"
          id="city"
          style={{
            paddingLeft: "30px",
          }}
        />
        {/* Show clear button only if there is text in the input field */}
        {searchLocation && (
          <span className="clear-button" onClick={handleClearInput}>
            &#x2715; {/* Unicode for the X symbol */}
          </span>
        )}
      </div>
      {searchLocation && searchResults.length > 0 && (
        <ListGroup className="search-results-container">
          {searchResults.map((city, index) => (
            <ListGroupItem
              key={index}
              onClick={() => handleCitySelect(city)}
              className="list-group-item"
            >
              {city}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}

      {/* {!selectedCity && noResultsFound && <p>No Cities Founds</p>} */}
    </div>
  );
};

export default SearchByLocation;
