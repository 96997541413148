import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allUsers: [],
  finalUsers: [],
  searchTerm: "",
  searchLocation: "",
  searchType: "",
  userMaps: true,
  isLoading: false,
  connectionModal: false,
  openJobModal: false,
};

const peopleInCom = createSlice({
  name: "peopleInCom",
  initialState,
  reducers: {
    setAllUsers: (state, action) => {
      state.allUsers = action.payload;
    },
    setFinalUsers: (state, action) => {
      state.finalUsers = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    setSearchLocation: (state, action) => {
      state.searchLocation = action.payload;
    },
    setSearchType: (state, action) => {
      state.searchType = action.payload;
    },
    setUserMaps: (state, action) => {
      state.userMaps = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setConnectionModal: (state, action) => {
      state.connectionModal = action.payload;
    },
    setOpenJobModal: (state, action) => {
      state.openJobModal = action.payload;
    },
  },
});

export const {
  setAllUsers,
  setSearchTerm,
  setSearchLocation,
  setSearchType,
  setFinalUsers,
  setUserMaps,
  setIsLoading,
  setConnectionModal,
  setOpenJobModal,
} = peopleInCom.actions;
export default peopleInCom.reducer;
