import React, { useEffect, useState } from "react";
import { fetchUserDetails } from "../Header/Data";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import { Col, Row } from "reactstrap";

const TvProductCard = ({ item }) => {
  const baseURL = window.location.origin;
  const qrValue = `${baseURL}/#/requestDetails/${item.Id}`;
  const item_desc = item.Description;
  const title = item.Title;
  const maxLength = 75; // Set max length for the description to 50 characters
  const truncatedItemDesc =
    item_desc.length > maxLength
      ? `${item_desc.slice(0, maxLength)}...`
      : item_desc;

  if (!item) {
    return <p>Loading...</p>;
  }
  return (
    <>
      <div className="tv-product-card card">
        <div className="card-container-guru">
          <h5 className="head m-0">
            {title.length > 23 ? `${title.substring(0, 22)}...` : title}
          </h5>
          <div className="product-image-container">
            <img
              className="product-image"
              src={(() => {
                try {
                  const photos = JSON.parse(item?.Photo);
                  return Array.isArray(photos) && photos.length > 0
                    ? photos[0]
                    : "https://st3.depositphotos.com/6672868/13801/v/600/depositphotos_138013506-stock-illustration-user-profile-group.jpg";
                } catch (error) {
                  console.error("Error parsing item.Photo:", error);
                  return "https://st3.depositphotos.com/6672868/13801/v/600/depositphotos_138013506-stock-illustration-user-profile-group.jpg";
                }
              })()}
              alt=""
            />
          </div>
          <Row className="guru-ext">
            <Col lg={7} xl={7} className="pe-0 ps-1 m-0 text-center">
              <a href={`tel:8002453610`} className="text-danger ">
                Listing #{item?.dialExtension}
              </a>
              <p className="dec">{truncatedItemDesc}</p>
            </Col>
            <Col lg={5} xl={5} className="mb-5 p-0 text-center">
              <QRCodeSVG
                className="qr-code"
                value={qrValue}
                size={60} // size of the QR code
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"} // error correction level (L, M, Q, H)
                includeMargin={true} // adds margin around QR code
              />
            </Col>
          </Row>
        </div>
      </div>
      <p className="p-0 m-0">(800)245-3610</p>
    </>
  );
};

export default TvProductCard;
