import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../RecentAds.css"; // Import or create a CSS file for styling
import { Row, Input, Col, Button } from "reactstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import DownloadAds from "../DownloadAds";
import {
  FaAngleDoubleDown,
  FaAngleDoubleUp,
  FaCaretDown,
  FaChevronDown,
  FaSlidersH,
} from "react-icons/fa";
import {
  fetchGurdwaras,
  fetchAllUsers,
  fetchAllProducts,
  fetchItemsBySubCatID,
} from "../../Data";
import { FadeLoader } from "react-spinners";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { toast } from "react-toastify";
import {
  setSelectedSubCategory,
  setSelectedItem,
  setFinalProducts,
  setFinalBwv,
  setActiveTab,
} from "../../../../redux/slices/LcaSlice";
import { RiArrowDownDoubleFill } from "react-icons/ri";
import PostAdModal from "../../modals/PostAdModal";
import { Fade, Menu, MenuItem, Modal } from "@mui/material";
import InviteCreateModal from "../../modals/InviteCreateModal";
import LcaAndBwv from "./LcaAndBwv2";
import LcaAndBwvShuffle from "./LcaAndBwvShuffle";
const RecentAds = ({
  handleClear,
  productSubCat,
  isLoading,
  scrollPopupVisible,
  setScrollPopupVisible,
}) => {
  const dispatch = useDispatch();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const {
    selectedSubCategory,
    finalProducts,
    Lca,
    activeTab,
    finalBwv,
    selectedItem,
    searchQuery,
  } = useSelector((state) => state.lcAds);
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState("Newer to Older"); // Add state for sorting
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showGurudwaraList, setShowGurudwaraList] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const [gurdwaras, setGurdwaras] = useState([]);
  const [engagedCouple, setEngagedCouple] = useState([]);
  const [dropdownAds, setDropdownAds] = useState(false);
  const toggle = () => setDropdownAds((prevState) => !prevState);
  const [showPopup, setShowPopup] = useState(false);
  const [openPostAd, setOpenPostAd] = useState(false); // [openPostAd, setOpenPostAd]
  const popupRef = useRef(); // Ref for the popup
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  const [openDropdown, setOpenDropdown] = useState(null);
  const togglePostAd = () => setOpenPostAd((prevState) => !prevState);
  const scrollPopupRef = useRef();
  const [items, setItems] = useState([]);
  const handleSortBy = (option) => {
    setSortBy(option);
    let sortedLca = [...finalProducts]; // Make a copy of the original array
    let sortedBwv = [...finalBwv];

    switch (option) {
      case "Newer to Older":
        sortedLca.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        sortedBwv.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        break;
      case "Older to Newer":
        sortedLca.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        sortedBwv.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        break;
      default:
        break;
    }
    dispatch(setFinalProducts(sortedLca));
    dispatch(setFinalBwv(sortedBwv));
  };
  const [inviteCreated, setInviteCreated] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setInviteCreated(true);
    }, 5000);
  }, []);
  const toggleInviteCreated = () => setInviteCreated(!inviteCreated);
  const [currentItem, setCurrentItem] = useState(null);
  const handleSubCategoryClick = (category, event) => {
    setScrollPopupVisible(true);
    setAnchorEl(event.currentTarget);
    // if (openDropdown === category.id) {
    //   setOpenDropdown(null); // Close dropdown if it's already open
    // } else {
    //   setOpenDropdown(category.id); // Open dropdown for the clicked subcategory
    // }
    if (Lca) {
      if (selectedSubCategory && selectedSubCategory.id === category.id) {
        dispatch(setSelectedSubCategory(null));
        dispatch(setActiveTab("Customers"));
      } else {
        dispatch(setSelectedSubCategory(category));
      }
      dispatch(setSelectedItem(null));
      // setItems([]);
    }
  };
  const handleItemClick = () => {
    window.scrollTo(0, 370);
    setScrollPopupVisible(true);
  };

  const fetchData = async () => {
    const gurudwaras = await fetchGurdwaras();
    const fetchUsers = await fetchAllUsers();
    const filterUsers = fetchUsers.filter((user) => user.EngagedCoupled === 1);
    setEngagedCouple(filterUsers);
    setGurdwaras(gurudwaras);
  };
  useEffect(() => {
    fetchData();
  }, [islogin]);

  const fetchItems = async () => {
    const items = await fetchItemsBySubCatID(selectedSubCategory?.id);
    console.log(items, "fetchItems");
    setItems(items);
  };

  useEffect(() => {
    if (selectedSubCategory) {
      fetchItems();
    }
  }, [selectedSubCategory]);
  const handleItemNameClick = (item, event) => {
    setScrollPopupVisible(true);
    event.stopPropagation();
    setCurrentItem(item);
    dispatch(setSelectedItem(item));
    handleClose();
    window.scrollTo(0, 400);
  };
  const tgl_filter = () => {
    var myele = document.getElementById("lca-sidebar");
    myele.classList.remove("tgl-close");
    myele.classList.add("tgl-show");
  };

  const handleGurudwaraSelect = (gurudwara) => {
    // Encode the gurudwara name without removing spaces
    const formattedGurudwara = encodeURIComponent(gurudwara);

    // Navigate to the dashboard with the gurudwara query parameter
    navigate(`/dashboard/?gurdwara=${formattedGurudwara}`);

    // Close the gurudwara list
    setShowGurudwaraList(false);
  };

  const customOrderMap = {
    "Job Market": 1,
    "Real Estate": 2,
    "Child Care Services": 3,
    "Upcoming Wedding/Event": 4,
    "Local Business Ads": 5,
    "Other Local Ads": 6,
    "Matrimonial/Dating": 7,
  };
  const sortedProductSubCat = [...productSubCat].sort((a, b) => {
    return (
      (customOrderMap[a.Name] || Infinity) -
      (customOrderMap[b.Name] || Infinity)
    );
  });
  const displayNameMap = {
    "Child Care Services": "Child Care",
    "Upcoming Wedding/Event": "Weddings",
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };
    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        scrollPopupRef.current &&
        !scrollPopupRef.current.contains(event.target)
      ) {
        setScrollPopupVisible(false);
        //window.scroll(0, 0);
      }
    };
    if (scrollPopupVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [scrollPopupVisible]);
  const handlePeopleClick = () => {
    if (!islogin) {
      toast.info("Please log in to access the People section!");
    } else {
      navigate("/people-in-mycommunity");
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);
  const screenWidth = window.innerWidth;
  const iPadView = screenWidth > 1300;
  const Popup = ({ closePopup }) => (
    <div className="text-center wc_show_cat_popup d-lg-block d-xl-none d-block">
      <div
        className=""
        ref={popupRef}
        style={{
          // position: "absolute",
          // top: iPadView ? "15%" : "7%",
          // left: iPadView ? "20%" : "50%",
          // transform: "translate(-50%, -50%)",
          padding: "10px",
          backgroundColor: "rgb(246 225 107)",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          zIndex: 5,
          width: 200,
          margin: "-74px auto 10px",
        }}
      >
        <div
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            textWrap: "nowrap",
            position: "relative",
            marginBottom: "15px",
          }}
        >
          Select an AD type to view
        </div>
        <div
          style={{
            marginTop: "-17px",
            textAlign: "center",
            marginBottom: "-9px",
          }}
          onClick={closePopup}
        >
          <RiArrowDownDoubleFill size={30} />
        </div>
      </div>
    </div>
  );
  const ScrollPopup = ({ closePopup }) => (
    <div
      ref={scrollPopupRef}
      style={{
        position: "fixed",
        bottom: "10%",
        left: "50%",
        transform: "translateX(-50%)",
        padding: "10px 20px",
        backgroundColor: "rgb(246 225 107)",
        animation: "flashingRed 2s infinite",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        zIndex: 9,
        display: scrollPopupVisible ? "block" : "none",
      }}
      onClick={() => {
        closePopup();
        //window.scroll(0, 0);
      }}
    >
      <div style={{ fontSize: "16px", fontWeight: "bold", textWrap: "nowrap" }}>
        Scroll &nbsp;
        <span className="text-primary">
          UP <FaAngleDoubleUp />
        </span>
        &nbsp; / &nbsp;
        <span className="text-primary">
          DOWN <FaAngleDoubleDown />{" "}
        </span>
        to see new ads
      </div>
    </div>
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMenu, setCurrentMenu] = useState(false);
  const handleClose = () => {
    setAnchorEl(null);
    setCurrentMenu(null);
  };
  const open22 = Boolean(anchorEl);
  return (
    <div className="recent-ads-container">
      {scrollPopupVisible && (
        <ScrollPopup closePopup={() => setScrollPopupVisible(false)} />
      )}
      <div>
        {!isLoading && showPopup && (
          <Popup closePopup={() => setShowPopup(false)} />
        )}
        {!isLoading && (
          <div
            style={{
              display: "flex",
              gap: "4.8px",
              width: "100%",
              textWrap: "nowrap",
              overflowX: "scroll",
              overflowY: "hidden",
              scrollBehavior: "smooth",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              animation: "flashingRed 2s infinite",
              borderRadius: "4px",
            }}
            className="inter-font d-xl-none"
          >
            {sortedProductSubCat.map((item) => (
              <div key={item?.id}>
                <Button
                  className="subCatList"
                  style={{
                    padding: "5px",
                    backgroundColor:
                      selectedSubCategory && selectedSubCategory.id === item.id
                        ? "rgb(186 181 181)"
                        : "rgb(245,245,245)",
                    marginBottom: 0,
                    color: "black",
                    border: "1px solid gray",
                    borderRadius: "5px",
                    fontWeight: "bold",
                    fontSize: "10px",
                    cursor: "pointer",
                  }}
                  aria-controls={open22 ? "fade-menu" : undefined}
                  size="sm"
                  onClick={(event) => handleSubCategoryClick(item, event)}
                >
                  <span className="drop-button-title">
                    {displayNameMap[item?.Name] || item?.Name} <FaChevronDown />
                  </span>
                </Button>
              </div>
            ))}
          </div>
        )}
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          open={open22}
          onClose={handleClose}
          TransitionComponent={Fade}
          disableScrollLock={true}
        >
          {items &&
            items.map((item) => (
              <MenuItem
                key={item.id}
                onClick={(event) => handleItemNameClick(item, event)}
              >
                {item?.subCatItemName}
              </MenuItem>
            ))}
        </Menu>
      </div>
      <Row className="rounded d-lg-block d-xl-none d-md-block position-relative">
        <div>
          <span
            style={{
              backgroundColor: "#FCEFEF",
              borderRadius: "10px",
              padding: "8px 20px",
              cursor: "pointer",
              display: "inline-block",
              position: "absolute",
              top: "22px",
            }}
            onClick={tgl_filter}
            className="mb-filter-btn"
            id="mb-filter-btn"
          >
            <FaSlidersH /> Filters
          </span>
        </div>
      </Row>
      <Row
        style={{
          display: "flex",
          alignItems: "center",
        }}
        className="p-3 text-nowrap"
        id="filters"
      >
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            // gap: 8,
            flexBasis: "33%",
          }}
          id="finalProducts"
        >
          <span className="wd_filter-countaction inter-font d-none d-md-none d-lg-none d-xl-block">
            {(activeTab === "Customers" &&
              finalProducts &&
              finalProducts.length) ||
              (activeTab === "Vendor" && finalBwv && finalBwv.length) ||
              "0"}{" "}
            results
          </span>
          <div
            onClick={handleClear}
            className="wd_filter-countaction results-container"
          >
            <Link className="wd_filter-countaction clear-link inter-font  py-1">
              <div className="wd_filter-countaction mdFilter inter-font d-block d-md-block d-lg-block d-xl-none text-small text-center text-dark">
                {(activeTab === "Customers" &&
                  finalProducts &&
                  finalProducts.length) ||
                  (activeTab === "Vendor" && finalBwv && finalBwv.length) ||
                  "0"}{" "}
                results
              </div>
              <p className="lca-clear-results">Clear Results</p>
            </Link>
          </div>
          <div className="lca-latest-ads-dropdown">
            <Dropdown isOpen={dropdownAds} toggle={toggle}>
              <DropdownToggle
                className="wd_filter-countaction lca-latest-dropdown inter-font "
                onClick={() => setShowGurudwaraList(!showGurudwaraList)}
              >
                Latest Ads &nbsp; &nbsp;
                <FaCaretDown />
              </DropdownToggle>
              <DropdownMenu className="wd_filter-countaction wd_filter-countaction-hover lca-latest-dropdown-menu inter-font">
                <DropdownItem
                  onMouseEnter={(e) => (
                    (e.target.style.backgroundColor = "#5435C2"),
                    (e.target.style.color = "white")
                  )}
                  onMouseLeave={(e) => (
                    (e.target.style.backgroundColor = "initial"),
                    (e.target.style.color = "black")
                  )}
                  onClick={() => navigate("/dashboard")}
                  style={{
                    borderRadius: "0px 25px 0px 25px",
                    borderBottom: "1px solid rgb(224, 224, 224)",
                    fontSize: "14px",
                    padding: "8px 10px",
                  }}
                >
                  View all Gurdwaras
                </DropdownItem>
                {gurdwaras.map((gurdwara) => (
                  <DropdownItem
                    key={gurdwara.id}
                    onClick={() => handleGurudwaraSelect(gurdwara.name)}
                    onMouseEnter={(e) => (
                      (e.target.style.backgroundColor = "#5435C2"),
                      (e.target.style.color = "white")
                    )}
                    onMouseLeave={(e) => (
                      (e.target.style.backgroundColor = "initial"),
                      (e.target.style.color = "black")
                    )}
                    style={{
                      borderRadius: "0px 25px 0px 25px",
                      borderBottom: "1px solid rgb(224, 224, 224)",
                      fontSize: "14px",
                      padding: "8px 10px",
                    }}
                  >
                    {gurdwara.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
          {isAdmin && (
            <div className="lca-download-ads d-none d-md-none d-lg-none d-xl-block">
              <DownloadAds />
            </div>
          )}
        </Col>
        <Col className=" p-0 m-0" style={{ flexBasis: "33%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: 8,
              // marginRight: "30px",
            }}
          >
            <div className="d-none d-md-block d-lg-block">
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle
                  // caret
                  style={{
                    backgroundColor: "#fff",
                    color: "#000",
                    // border: "1px solid #007BFF",
                  }}
                  className="wd_filter-btn"
                >
                  {sortBy.charAt(0).toUpperCase() + sortBy.slice(1)}
                  <FaCaretDown />
                </DropdownToggle>
                <DropdownMenu style={{ color: "#fff" }}>
                  <DropdownItem onClick={() => handleSortBy("Newer to Older")}>
                    Newer to Older
                  </DropdownItem>
                  <DropdownItem onClick={() => handleSortBy("Older to Newer")}>
                    Older to Newer
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </Col>
      </Row>
      {isAdmin && (
        <div className="lca-download-ads-mobile d-block d-md-block d-lg-block d-xl-none">
          <DownloadAds />
        </div>
      )}
      <div
        className="mb-4"
        style={{
          backgroundColor: "#f5f5f5",
          padding: "10px",
          borderRadius: "7px",
        }}
      >
        {" "}
        <Row className="m-0 p-0 d-flex align-items-center justify-content-between">
          <Col
            sm={8}
            style={{
              textAlign: "left",
              padding: "7px",
              color: "#000",
              fontWeight: "500",
              fontSize: "18px",
            }}
          >
            This site is FREE to use with purpose to serve the Sikh Community.
            {/* List your own Ads on the site by calling us (800) 245-3610. It's
            Free. */}
          </Col>
          <Col sm={4}>
            <button
              type="button"
              className="btn btn-danger m-0 px-5"
              style={{
                height: "100%",
                width: "100%",
                padding: "1rem", // Increase padding
                borderRadius: "20px", // Add border radius for rounded corners
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Add shadow for depth
              }}
              onClick={() => setOpenPostAd(true)}
              // onClick={() => navigate("/needs-requests/request")}ss
            >
              Post Your Ad
            </button>
          </Col>
          <PostAdModal open={openPostAd} toggle={togglePostAd} />
        </Row>
      </div>
      {searchQuery && searchQuery != "" ? (
        <LcaAndBwvShuffle scrollPopupVisible={scrollPopupVisible} />
      ) : (
        <LcaAndBwv scrollPopupVisible={scrollPopupVisible} />
      )}
      <InviteCreateModal
        open={inviteCreated}
        toggle={toggleInviteCreated}
        setOpenPostAd={setOpenPostAd}
      />
    </div>
  );
};

export default RecentAds;
