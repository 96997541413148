import React, { useEffect } from "react";
import { Modal, Box, Typography, Button, Grid } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { setEngagedCouple } from "../../../redux/slices/RequestFormSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const PostAdModal = ({ open, toggle }) => {
  const formData = useSelector((state) => state.requestForm);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const islogin = useSelector((state) => state.user.isLogin);
  const adTypes = [
    {
      id: 1,
      title: "You have a community Need",
      color: "#ff4d4d",
      link: "/needs-requests/request",
      tab: "normal-need",
    },
    {
      id: 2,
      title: "You have an upcoming Event or Wedding",
      color: "#4d79ff",
      link: "/needs-requests/request",
      engagedCouple: true,
      tab: "upcoming-event",
    },
    {
      id: 3,
      title: "You have a service to sell",
      color: "#4db84d",
      link: "/create-service",
      tab: "service-sell",
      service: true,
    },
    {
      id: 4,
      title: "You have an Item to Sell",
      color: "#ffba08",
      link: "/create-item",
      tab: "item-sell",
      item: true,
    },
  ];

  const handleSelect = (ad) => {
    if (ad.engagedCouple) {
      dispatch(setEngagedCouple(1));
    } else {
      dispatch(setEngagedCouple(0));
    }
    if (!islogin && ad.service) {
      toast.error("Please login to create a service");
      return;
    }
    navigate(`${ad.link}`);
    toggle();
  };

  return (
    <Modal open={open} onClose={toggle}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h4" textAlign="center" mb={3}>
          Post Your Ad
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          {adTypes.map((ad, index) => (
            <Grid item xs={12} sm={6} md={3} key={ad.id}>
              <Box
                sx={{
                  border: `2px solid ${ad.color}`,
                  borderRadius: "100px 0px",
                  overflow: "hidden",
                  bgcolor: "white",
                  height: "100%",
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.5)", // Custom shadow
                }}
              >
                <Box
                  sx={{
                    color: `${ad.color}`,
                    p: 2,
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ fontSize: "15px", paddingTop: "28px" }}
                  >
                    {ad.title}
                  </Typography>
                </Box>
                <Box textAlign="center" sx={{ pb: 3 }}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: ad.color,
                      color: "white",
                      borderRadius: "8px",
                      padding: "5px 15px",
                      "&:hover": {
                        backgroundColor: `${ad.color}CC`,
                      },
                    }}
                    onClick={() => handleSelect(ad)} // Add onClick handler
                  >
                    Select
                  </Button>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Modal>
  );
};

export default PostAdModal;
