import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Link as ScrollLink } from "react-scroll";
import "./Bwv.css";
import HireModal from "../modals/HireModal";
import ReviewModal from "../modals/Review";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import InviteModal from "../modals/InviteModal";
import ReportBussinessModal from "../modals/ReportBussinessModal";
import moment from "moment";
import { FaSync } from "react-icons/fa";
import { MdVerified } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import { ImSpinner2 } from "react-icons/im";
import { MdVideoChat } from "react-icons/md";
import { FaSquarePhone } from "react-icons/fa6";
import {
  Button,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import {
  endorsementStatus,
  makeCall,
  sendMsgMail,
  serviceEndorsement,
  twilioToken,
  virtualTourClick,
} from "../Data";
import { GetZoomLink } from "../Data2";
import StartZoomVendorModal from "../modals/StartZoomVendorModal";

import {
  setEndorStatus,
  setIsInvite,
  setIsModalOpen,
  setIsReportingModalOpen,
  setIsReviewModalOpen,
  setLinkModal,
  setLoadingEndorse,
  setQuoteModal,
  setQuotePop,
} from "../../../redux/slices/BwvData";
import VendorServiceDt from "./VendorServiceDt";
import VendoeProfileDt from "./VendoeProfileDt";

const BwvcardDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const selectedCard = useSelector((state) => state.bwvData.selectedService);
  const {
    activeLink,
    isReviewModalOpen,
    isReportingModalOpen,
    isInvite,
    endorStatus,
    loadingEndorse,
    linkModal,
    isModalOpen,
    selectedServiceDate,
    quoteModal,
    selectedPackageRate,
    quotePop,
  } = useSelector((state) => state.bwvData);
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const currentHour = new Date().getHours().toString().padStart(2, "0");
  const [selectedHour, setSelectedHour] = useState(`${currentHour}:00`);
  const availableDays = selectedCard?.availableDays;
  const logUser = localStorage.getItem("USER_ROLE");
  const fetchStatus = async () => {
    if (islogin) {
      let serviceId = selectedCard?.Id;
      let formData = {
        userId: user?.UserID,
      };
      const resp = await endorsementStatus(serviceId, formData);
      dispatch(setEndorStatus(resp[0]));
    }
    dispatch(setLoadingEndorse(false));
  };
  useEffect(() => {
    if (selectedCard) {
      fetchStatus();
    }
  }, [selectedCard]);
  useEffect(() => {
    dispatch(setQuotePop(location.state));
    if (location.state) {
      dispatch(setQuoteModal(true));
    }
  }, [location.state]);

  const closeModal = () => {
    dispatch(setIsModalOpen(false));
  };
  const OpenInvite = () => {
    if (!islogin) {
      toast.info("Please Login");
      navigate("/login");
    } else {
      dispatch(setIsInvite(true));
    }
  };
  const openModal = () => {
    dispatch(setIsModalOpen(true));
  };
  const closeReviewModal = () => {
    dispatch(setIsReviewModalOpen(false));
  };
  const closeInviteModal = () => {
    dispatch(setIsInvite(false));
  };
  const handleReportBusiness = () => {
    dispatch(setIsReportingModalOpen(!isReportingModalOpen));
  };
  if (!selectedCard) {
    return <div>Card not found!</div>;
  }
  const packages = selectedCard?.Package
    ? JSON.parse(selectedCard.Package)
    : {};

  const closeLinkModal = () => {
    dispatch(setLinkModal(false));
  };

  const openHireModal = () => {
    console.log(selectedHour, "selectedHour");
    console.log(availableDays, "selectedHour");
    if (!selectedHour) {
      toast.warning("Please select a time slot");
      return;
    }
    const validAvailableDays = availableDays.filter((available) => {
      return available.days.length > 0 && available.from && available.to;
    });
    const selectedDateObj = moment(selectedServiceDate);
    const selectedDayOfWeek = selectedDateObj.format("dddd");
    const selectedHourParts = selectedHour.split(":");
    const selectedHourStart = parseInt(selectedHourParts[0]);
    const selectedMinuteStart = parseInt(selectedHourParts[1]);
    const isHourAvailable = validAvailableDays.some((available) => {
      const days = available.days;
      const startTime = available.from.split(":");
      const endTime = available.to.split(":");
      const startHour = parseInt(startTime[0]);
      const startMinute = parseInt(startTime[1]);
      const endHour = parseInt(endTime[0]);
      const endMinute = parseInt(endTime[1]);

      if (days.includes(selectedDayOfWeek)) {
        if (
          selectedHourStart > startHour ||
          (selectedHourStart === startHour &&
            selectedMinuteStart >= startMinute)
        ) {
          if (
            selectedHourStart < endHour ||
            (selectedHourStart === endHour && selectedMinuteStart <= endMinute)
          ) {
            return true;
          }
        }
      }

      return false;
    });
    if (!islogin) {
      toast.info("Please Login");
      navigate("/login");
    } else if (!isHourAvailable) {
      toast.warning("Please Select Provider Available Date In Schedule");
    } else {
      dispatch(setIsModalOpen(true));
    }
  };

  if (!selectedCard) {
    return <div>Card not found!</div>;
  }
  const prices = Object.values(packages).map(
    (packageDetails) => packageDetails.price
  );
  const minimumPrice = prices.length > 0 ? Math.min(...prices) : 0;
  return (
    <Row style={{ display: "flex", margin: "0px" }}>
      <Col sm={3} xs={12} className="card-details-profile">
        <VendoeProfileDt
          openHireModal={openHireModal}
          OpenInvite={OpenInvite}
        />
      </Col>
      <Col sm={9} xs={12} className="card-details-details">
        <VendorServiceDt
          selectedHour={selectedHour}
          setSelectedHour={setSelectedHour}
          fetchStatus={fetchStatus}
          openHireModal={openHireModal}
        />
      </Col>
      <div className="d-flex flex-column flex-md-row justify-content-md-end text-center my-2">
        <button
          className="btn btn-danger mx-auto mx-md-0"
          onClick={handleReportBusiness}
        >
          Report Business
        </button>
        <ReportBussinessModal
          isReportingModalOpen={isReportingModalOpen}
          handleReportBusiness={handleReportBusiness}
        />
      </div>
      <ReviewModal isOpen={isReviewModalOpen} closeModal={closeReviewModal} />
      <HireModal
        isOpen={isModalOpen}
        openModal={openModal}
        selectedHour={selectedHour}
        setSelectedHour={setSelectedHour}
        closeModal={closeModal}
      />
      <InviteModal isOpen={isInvite} closeModal={closeInviteModal} />
      {selectedCard?.customerToVenue === 1 &&
        (!islogin || logUser === "customer") && (
          <StartZoomVendorModal open={linkModal} handleClose={closeLinkModal} />
        )}
    </Row>
  );
};

export default BwvcardDetails;
