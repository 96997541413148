import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";
import { toast } from "react-toastify";
import CustomFields from "./CustomFields";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setCustomerToVenue,
  setDealTerms,
  setDialExtension,
  setPdfFile,
  setPhone,
  setVenueToCustomer,
} from "../../../redux/slices/ServiceInfo";
import AdditionalDetails from "./AdditionalDetails";
import Switch from "@mui/material/Switch";
import uploadToS3 from "../../../utils/awsS3Upload";
const Details = ({ setMandate }) => {
  const dispatch = useDispatch();
  const serviceInfo = useSelector((state) => state.serviceInfo);
  const [filePreview, setFilePreview] = useState(null);
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin"; // Check if user is admin

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const maxFileSizeMB = 5;
    const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024; // Convert MB to bytes
    let folder = "servicePdf/";
    if (!file) {
      toast.error("No file selected.");
      return;
    }
    if (file.type !== "application/pdf") {
      toast.error("Please select a PDF file.");
      return;
    }
    if (file.size > maxFileSizeBytes) {
      toast.error(`File size should be less than ${maxFileSizeMB} MB.`);
      return;
    }
    try {
      const pdfUrl = await uploadToS3(file, folder); // Upload to AWS S3
      dispatch(setPdfFile(pdfUrl)); // Store the result in the Redux state
    } catch (error) {
      toast.error("Failed to upload the PDF. Please try again.");
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setFilePreview(reader.result); // Base64 or blob preview for UI
    };
    reader.readAsDataURL(file);
  };
  console.log(serviceInfo.pdfFile, "pdfFile");
  useEffect(() => {
    if (serviceInfo.pdfFile) {
      setFilePreview(serviceInfo.pdfFile);
    }
  }, [serviceInfo.pdfFile]);
  const handleDialExtensionChange = (e) => {
    const dialExtension = e.target.value;
    if (!/^\d{0,4}$/.test(dialExtension)) {
      return;
    }
    dispatch(setDialExtension(dialExtension));
    setMandate(!!dialExtension);
  };

  const handlePhoneChange = (e) => {
    const phone = e.target.value;
    if (!/^\d{0,10}$/.test(phone)) {
      return;
    }
    dispatch(setPhone(phone));
    setMandate(!!phone);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setMandate(!!serviceInfo.dialExtension);
  }, [serviceInfo.dialExtension]);
  return (
    <>
      <CustomFields setMandate={setMandate} />
      <Row className="form-container p-5 m-3 ">
        <h6>Deal Terms</h6>
        <span style={{ padding: "10px" }}>Details of your Deal Terms</span>
        <FormGroup>
          <Input
            type="textarea"
            name="dealTerms"
            value={serviceInfo.dealTerms || ""}
            onChange={(e) => dispatch(setDealTerms(e.target.value))}
          />
        </FormGroup>
        <FormGroup>
          <Row>
            <Col>
              <Label>Phone Number</Label>&nbsp;
              {user && user.Role === "provider" && (
                <span style={{ color: "red" }}>*</span>
              )}
              <Input
                type="number"
                name="Phone"
                placeholder="Type phone number customers can reach you at."
                value={serviceInfo.Phone || ""}
                onChange={(e) => handlePhoneChange(e)}
              />
            </Col>
            {isAdmin && (
              <Col>
                <Label>Extension: </Label>
                <span style={{ color: "red" }}>*</span>
                <Input
                  type="number"
                  name="Extension"
                  placeholder="Add dial extension"
                  value={serviceInfo.dialExtension || ""}
                  onChange={(e) => handleDialExtensionChange(e)}
                  disabled={user?.RoleId === 1 ? false : true}
                />
              </Col>
            )}
          </Row>
        </FormGroup>
        {/* {isAdmin && ( */}
        <Row>
          <Col>
            <AdditionalDetails />
          </Col>
          {isAdmin && (
            <Row className="mt-3">
              <Label style={{ fontSize: "18px" }}>Enable Virtual tour:</Label>
              <Col>
                <div>
                  <span className="mx-2">Customer to Venue</span>
                  <Switch
                    name="customerToVenue"
                    onChange={(e) => {
                      const newChecked = e.target.checked; // Get the new checked state
                      dispatch(setCustomerToVenue(newChecked)); // Update Redux state
                      console.log(newChecked, "customerToVenue");
                    }}
                    checked={serviceInfo?.customerToVenue}
                  />
                  {/* <Input
                    type="checkbox"
                    name="customerToVenue"
                    // value={serviceInfo?.customerToVenue || ""}
                    checked={serviceInfo?.customerToVenue}
                    onChange={(e) => {
                      const newChecked = e.target.checked; // Get the new checked state
                      dispatch(setCustomerToVenue(newChecked)); // Update Redux state
                      console.log(newChecked, "customerToVenue");
                    }}
                  /> */}
                  {/* <span className="mx-2">Customer to Venue</span> */}
                </div>
              </Col>
              <Col>
                <div>
                  <span className="mx-2">Venue to Customer</span>
                  <Switch
                    name="venueToCustomer"
                    onChange={(e) => {
                      const newChecked = e.target.checked; // Get the new checked state
                      dispatch(setVenueToCustomer(newChecked)); // Update Redux state
                      console.log(newChecked, "venueToCustomer");
                    }}
                    checked={serviceInfo?.venueToCustomer}
                  />
                  {/* <Input
                    type="checkbox"
                    name="venueToCustomer"
                    // value={serviceInfo?.venueToCustomer || ""}
                    checked={serviceInfo?.venueToCustomer}
                    onChange={(e) => {
                      const newChecked = e.target.checked; // Get the new checked state
                      dispatch(setVenueToCustomer(newChecked)); // Update Redux state
                      console.log(newChecked, "venueToCustomer");
                    }}
                  /> */}
                  {/* <span className="mx-2">Venue to Customer</span> */}
                </div>
              </Col>
            </Row>
          )}
        </Row>
        {/* )} */}
      </Row>
      <Row className="form-container p-5 m-3">
        <h6>Upload PDF file</h6>
        <span>Files</span>
        <span style={{ fontSize: "small", padding: "5px" }}>
          Add PDF files of your listing (optional). File size limit: 5mb
        </span>
        <FormGroup>
          <Input type="file" name="pdfFile" onChange={handleFileChange} />
        </FormGroup>
        {filePreview && (
          <div>
            <p>File Name: {serviceInfo.pdfFile.name}</p>
            <embed src={filePreview} width="250" height="220" />
          </div>
        )}
      </Row>
    </>
  );
};

export default Details;
